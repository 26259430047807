import * as Sentry from '@sentry/nextjs'
import { authExchange } from '@urql/exchange-auth'
import { cacheExchange } from '@urql/exchange-graphcache'
import { relayPagination } from '@urql/exchange-graphcache/extras'
import firebase from 'firebase'
import {
  CreateCommentInput,
  CreateCommentMutation,
  CreateProjectMutation,
  CreateReactionMutation,
  GetCurrentPersonDocument,
  GetCurrentPersonQuery,
  GetProjectsDocument,
  GetProjectsQuery,
  MutationDeleteFileArgs,
  MutationDeleteProjectArgs,
  MutationDeleteReactionArgs,
  RegularCommentFragment,
  RegularCommentFragmentDoc,
} from 'generated/graphql'
// import { createClient as createWSClient } from 'graphql-ws'
import { SubscriptionClient } from 'subscriptions-transport-ws'
import {
  createClient,
  dedupExchange,
  errorExchange,
  fetchExchange,
  gql,
  makeOperation,
  subscriptionExchange,
} from 'urql'

let clientSingleton = null

function invalideResource(cache, fieldName: string) {
  const allFields = cache.inspectFields('Query')
  const fields = allFields.filter((field) => field.fieldName === fieldName)
  fields.forEach((field) => {
    cache.invalidate('Query', fieldName, field.arguments || {})
  })
}

export default function createClientWrapper() {
  if (!clientSingleton) {
    const wsClientSingleton = new SubscriptionClient(
      process.env.NEXT_PUBLIC_WSS,
      {
        lazy: true,
        reconnect: true,
        connectionParams: async () => {
          const token = await firebase.auth().currentUser?.getIdToken()
          return {
            authorization: token ? `Bearer ${token}` : '',
          }
        },
      }
    )
    // wsClientSingleton = createWSClient({
    //   // url: 'ws://localhost:8080/graphql',
    //   url: 'ws://localhost:8080/graphql',
    //   connectionParams: {
    //     Headers: {
    //       Authorization: 'Bearer xxxxxxxxxxxxxxxxxxxxxxxxxxxxxx',
    //     },
    //   },
    // })

    clientSingleton = createClient({
      url: process.env.NEXT_PUBLIC_GRAPHQL,
      exchanges: [
        dedupExchange,
        cacheExchange({
          resolvers: {
            Query: {
              getFilesInOrganisation: relayPagination(),
            },
          },
          keys: {
            Thumbnail: () => null,
            Location: () => null,
            GeometryPoint: () => null,
          },
          optimistic: {
            createComment: (variables, cache, info) => {
              const query: GetCurrentPersonQuery = cache.readQuery({
                query: GetCurrentPersonDocument,
              })

              const input = variables.input as CreateCommentInput

              const currentPerson: any = query.getCurrentPerson.nodes[0]
              currentPerson.__typename = 'Person'

              const c = {
                __typename: 'Comment',
                id: 'TEMP_ID_' + Date.now(),
                createdAt: Date.now(),
                updatedAt: Date.now(),
                reactions: {
                  __typename: 'ReactionsConnection',
                  nodes: [],
                },
                person: currentPerson,
                ...input.comment,
              }

              const fileId = input.comment.fileId
              const fragment = gql`
                fragment File_ on File {
                  __typename
                  id
                  comments {
                    __typename
                    nodes {
                      __typename
                      id
                      fileId
                      content
                    }
                  }
                }
              `

              const f = cache.readFragment(fragment, {
                __typename: 'File',
                id: fileId,
              })

              const newComments = {
                __typename: 'File',
                id: fileId,
                comments: {
                  __typename: 'CommentsConnection',
                  nodes: [
                    ...f.comments.nodes.map((node) => ({
                      __typename: 'Comment',
                      id: node.id,
                      createdAt: node.createdAt,
                      updatedAt: node.updatedAt,
                      reactions: node.reactions,
                      person: node.person,
                      fileId: node.fileId,
                      content: node.content,
                    })),
                    c,
                  ],
                },
              }

              cache.writeFragment(fragment, newComments)

              return {
                __typename: 'CreateCommentMutation',
                comment: c,
              }
            },
          },
          updates: {
            Subscription: {
              fileUpdated(_result, args, cache, _info) {
                // NOTE: don't know how to handle new comments otherwise
                invalideResource(cache, 'comments')
              },
              newNotification(_result, args, cache, _info) {
                invalideResource(cache, 'notifications')
                invalideResource(cache, 'getFilesInOrganisation')
              },
            },
            Mutation: {
              createNotification(_result, args, cache, _info) {
                invalideResource(cache, 'notifications')
              },
              deleteNotification(_result, args, cache, _info) {
                invalideResource(cache, 'notifications')
              },
              createReaction(result, args, cache, _info) {
                const mutation = (result as CreateReactionMutation)
                  .createReaction

                const comment = cache.readFragment(RegularCommentFragmentDoc, {
                  __typename: 'Comment',
                  id: mutation.reaction.commentId,
                }) as RegularCommentFragment

                comment.reactions.nodes.push(mutation.reaction)
                cache.writeFragment(RegularCommentFragmentDoc, comment)
              },
              deleteReaction(result, args, cache, _info) {
                cache.invalidate({
                  __typename: 'Reaction',
                  id: (args as MutationDeleteReactionArgs).input.id,
                })
              },
              createComment(result, args, cache, _info) {
                console.log('res', result)
                // const query = GetFileDocument
                const mutation = (result as CreateCommentMutation).createComment
                const fileId = mutation.comment.fileId

                const fragment = gql`
                  fragment File_ on File {
                    __typename
                    id
                    comments {
                      __typename
                      nodes {
                        __typename
                        id
                        fileId
                        content
                      }
                    }
                  }
                `

                const f = cache.readFragment(fragment, {
                  __typename: 'File',
                  id: fileId,
                })

                const newComments = {
                  __typename: 'File',
                  id: fileId,
                  comments: {
                    __typename: 'CommentsConnection',
                    nodes: [
                      ...f.comments.nodes
                        .filter((node) => !node.id.includes('TEMP_ID_'))
                        // TODO: not nice! i guess i need to make id locally and then match by id?
                        // would that fix flickering example?
                        .map((node) => ({
                          __typename: 'Comment',
                          id: node.id,
                          createdAt: node.createdAt,
                          updatedAt: node.updatedAt,
                          reactions: node.reactions,
                          person: node.person,
                          fileId: node.fileId,
                          content: node.content,
                        })),
                      mutation.comment,
                    ],
                  },
                }

                cache.writeFragment(fragment, newComments)
              },
              deleteComment(result, args, cache, _info) {
                // invalideResource(cache, 'comments')
                // cache.invalidate(result.deleteComment.comment.id)
              },
              createFile(result, args, cache, _info) {
                console.log(result)
                console.log(args)
                console.log(_info)
                invalideResource(cache, 'getFilesInOrganisation')
                // invalideResource(cache, 'projects')
                // const query = GetFilesInOrganisationDocument
                // const mutation = (result as CreateFileMutation).createFile
                // const fileId = mutation.file
                // cache.updateQuery({ query, variables: { fileId } }, (data) => {
                //   const d = data as GetFileQuery
                //   d.file.comments.nodes.push(mutation.comment)
                //   return data
                // })
              },
              deleteFile(_result, args, cache, _info) {
                cache.invalidate({
                  __typename: 'File',
                  id: (args as MutationDeleteFileArgs).input.id,
                })
              },
              addOrganisation(_result, args, cache, _info) {
                const allFields = cache.inspectFields('Query')
                const organisationsField = allFields.find(
                  (field) => field.fieldName === 'organisations'
                )
                cache.invalidate(
                  'Query',
                  'organisations',
                  organisationsField.arguments
                )
              },
              deleteOrganisation(_result, args, cache, _info) {
                const allFields = cache.inspectFields('Query')
                const organisationsField = allFields.find(
                  (field) => field.fieldName === 'organisations'
                )
                cache.invalidate(
                  'Query',
                  'organisations',
                  organisationsField.arguments
                )
              },
              createOrganisationInvitation(_result, args, cache, _info) {
                invalideResource(cache, 'organisationInvitations')
              },
              deleteOrganisationInvitation(_result, args, cache, _info) {
                invalideResource(cache, 'organisationInvitations')
              },

              // Projects
              createProject(result, args, cache, _info) {
                const query = GetProjectsDocument
                const mutation = (result as CreateProjectMutation).createProject
                const organisationId = mutation.project.organisationId
                cache.updateQuery(
                  { query, variables: { organisationId } },
                  (data) => {
                    const d = data as GetProjectsQuery
                    d.projects.nodes.push(mutation.project)
                    return data
                  }
                )
              },
              deleteProject(result, args, cache, _info) {
                cache.invalidate({
                  __typename: 'Project',
                  id: (args as MutationDeleteProjectArgs).input.id,
                })
              },

              addFirebaseAccount(_result, args, cache, _info) {
                cache.invalidate('Query', 'organisations')
              },
              createCollection(_result, args, cache, _info) {
                const allFields = cache.inspectFields('Query')
                const collectionsField = allFields.find(
                  (field) => field.fieldName === 'collections'
                )
                cache.invalidate(
                  'Query',
                  'collections',
                  collectionsField.arguments
                )
              },
              createFileCollection(_result, args, cache, _info) {
                // TODO: invalidate single file
                invalideResource(cache, 'getFilesInOrganisation')
              },
              deleteFileCollectionByCollectionIdAndFileId(
                _result,
                args,
                cache,
                _info
              ) {
                // TODO: invalidate single file
                invalideResource(cache, 'getFilesInOrganisation')
              },

              createPersonProject(_result, args, cache, _info) {
                console.log('helo')
                invalideResource(cache, 'projects')
              },
              deletePersonProjectByPersonIdAndProjectId(
                _result,
                args,
                cache,
                _info
              ) {
                invalideResource(cache, 'projects')
              },
            },
          },
        }),
        authExchange({
          async getAuth({ authState }) {
            console.log('getting auth')
            if (!authState) {
              let token = await firebase.auth().currentUser?.getIdToken()
              console.log('token', token)
              if (token) {
                return { token }
              }

              return null
            }

            // force refresh token
            let token = await firebase.auth().currentUser?.getIdToken(true)
            console.log('token', token)
            if (token) {
              return { token }
            }

            return null
          },

          didAuthError({ error }) {
            console.log('urql caught error', error)
            const isExpired = error.graphQLErrors.some(
              (e) => e.extensions?.code === 'FORBIDDEN'
            )
            console.log('is expired', isExpired)
            return isExpired
          },

          addAuthToOperation({ authState, operation }) {
            // @ts-ignore
            if (!authState || !authState.token) {
              return operation
            }

            const fetchOptions =
              typeof operation.context.fetchOptions === 'function'
                ? operation.context.fetchOptions()
                : operation.context.fetchOptions || {}

            return makeOperation(operation.kind, operation, {
              ...operation.context,
              fetchOptions: {
                ...fetchOptions,
                headers: {
                  ...fetchOptions.headers,
                  // @ts-ignore
                  Authorization: `Bearer ${authState.token}`,
                },
              },
            })
          },
        }),
        errorExchange({
          onError(error) {
            console.error('GraphQL Error:', error)
            Sentry.captureException(error)
          },
        }),
        fetchExchange,
        subscriptionExchange({
          forwardSubscription: (operation) =>
            // @ts-ignore
            wsClientSingleton.request(operation),
        }),
        // subscriptionExchange({
        //   forwardSubscription: (operation) => ({
        //     subscribe: (sink) => ({
        //       // @ts-ignore
        //       unsubscribe: wsClientSingleton.subscribe(operation, sink),
        //     }),
        //   }),
        // }),
      ],
    })
  }

  return clientSingleton
}
