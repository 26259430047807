import gql from 'graphql-tag'
import * as Urql from 'urql'
export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** A location in a connection that can be used for resuming pagination. */
  Cursor: any
  /**
   * A point in time as described by the [ISO
   * 8601](https://en.wikipedia.org/wiki/ISO_8601) standard. May or may not include a timezone.
   */
  Datetime: any
  /** The `GeoJSON` scalar type represents GeoJSON values as specified by[RFC 7946](https://tools.ietf.org/html/rfc7946). */
  GeoJSON: any
  /** A universally unique identifier as defined by [RFC 4122](https://tools.ietf.org/html/rfc4122). */
  UUID: any
}

/** All input for the `acceptOrganisationInvitation` mutation. */
export type AcceptOrganisationInvitationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  invitationCode?: Maybe<Scalars['String']>
}

/** The output of our `acceptOrganisationInvitation` mutation. */
export type AcceptOrganisationInvitationPayload = {
  __typename?: 'AcceptOrganisationInvitationPayload'
  boolean?: Maybe<Scalars['Boolean']>
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>
}

/** All input for the `addFirebaseAccount` mutation. */
export type AddFirebaseAccountInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  photoUrl?: Maybe<Scalars['String']>
}

/** The output of our `addFirebaseAccount` mutation. */
export type AddFirebaseAccountPayload = {
  __typename?: 'AddFirebaseAccountPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>
  person?: Maybe<Person>
  /** An edge for our `Person`. May be used by Relay 1. */
  personEdge?: Maybe<PeopleEdge>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>
}

/** The output of our `addFirebaseAccount` mutation. */
export type AddFirebaseAccountPayloadPersonEdgeArgs = {
  orderBy?: Maybe<Array<PeopleOrderBy>>
}

/** All input for the `addOrganisation` mutation. */
export type AddOrganisationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
}

/** The output of our `addOrganisation` mutation. */
export type AddOrganisationPayload = {
  __typename?: 'AddOrganisationPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>
  organisation?: Maybe<Organisation>
  /** An edge for our `Organisation`. May be used by Relay 1. */
  organisationEdge?: Maybe<OrganisationsEdge>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>
}

/** The output of our `addOrganisation` mutation. */
export type AddOrganisationPayloadOrganisationEdgeArgs = {
  orderBy?: Maybe<Array<OrganisationsOrderBy>>
}

export type Collection = Node & {
  __typename?: 'Collection'
  createdAt: Scalars['Datetime']
  description?: Maybe<Scalars['String']>
  /** Reads and enables pagination through a set of `FileCollection`. */
  fileCollections: FileCollectionsConnection
  /** Reads and enables pagination through a set of `File`. */
  filesByFileCollectionCollectionIdAndFileId: CollectionFilesByFileCollectionCollectionIdAndFileIdManyToManyConnection
  id: Scalars['UUID']
  name?: Maybe<Scalars['String']>
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']
  /** Reads a single `Organisation` that is related to this `Collection`. */
  organisation?: Maybe<Organisation>
  organisationId: Scalars['UUID']
  /** Reads a single `Person` that is related to this `Collection`. */
  person?: Maybe<Person>
  personId?: Maybe<Scalars['UUID']>
  updatedAt: Scalars['Datetime']
}

export type CollectionFileCollectionsArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<FileCollectionCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<FileCollectionsOrderBy>>
}

export type CollectionFilesByFileCollectionCollectionIdAndFileIdArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<FileCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<FilesOrderBy>>
}

/**
 * A condition to be used against `Collection` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type CollectionCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>
  /** Checks for equality with the object’s `description` field. */
  description?: Maybe<Scalars['String']>
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>
  /** Checks for equality with the object’s `organisationId` field. */
  organisationId?: Maybe<Scalars['UUID']>
  /** Checks for equality with the object’s `personId` field. */
  personId?: Maybe<Scalars['UUID']>
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>
}

/** A connection to a list of `File` values, with data from `FileCollection`. */
export type CollectionFilesByFileCollectionCollectionIdAndFileIdManyToManyConnection =
  {
    __typename?: 'CollectionFilesByFileCollectionCollectionIdAndFileIdManyToManyConnection'
    /** A list of edges which contains the `File`, info from the `FileCollection`, and the cursor to aid in pagination. */
    edges: Array<CollectionFilesByFileCollectionCollectionIdAndFileIdManyToManyEdge>
    /** A list of `File` objects. */
    nodes: Array<Maybe<File>>
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** The count of *all* `File` you could get from the connection. */
    totalCount: Scalars['Int']
  }

/** A `File` edge in the connection, with data from `FileCollection`. */
export type CollectionFilesByFileCollectionCollectionIdAndFileIdManyToManyEdge =
  {
    __typename?: 'CollectionFilesByFileCollectionCollectionIdAndFileIdManyToManyEdge'
    createdAt: Scalars['Datetime']
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>
    id: Scalars['UUID']
    /** The `File` at the end of the edge. */
    node?: Maybe<File>
    updatedAt: Scalars['Datetime']
  }

/** An input for mutations affecting `Collection` */
export type CollectionInput = {
  createdAt?: Maybe<Scalars['Datetime']>
  description?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['UUID']>
  name?: Maybe<Scalars['String']>
  organisationId: Scalars['UUID']
  personId?: Maybe<Scalars['UUID']>
  updatedAt?: Maybe<Scalars['Datetime']>
}

/** Represents an update to a `Collection`. Fields that are set will be updated. */
export type CollectionPatch = {
  createdAt?: Maybe<Scalars['Datetime']>
  description?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['UUID']>
  name?: Maybe<Scalars['String']>
  organisationId?: Maybe<Scalars['UUID']>
  personId?: Maybe<Scalars['UUID']>
  updatedAt?: Maybe<Scalars['Datetime']>
}

/** A connection to a list of `Collection` values. */
export type CollectionsConnection = {
  __typename?: 'CollectionsConnection'
  /** A list of edges which contains the `Collection` and cursor to aid in pagination. */
  edges: Array<CollectionsEdge>
  /** A list of `Collection` objects. */
  nodes: Array<Maybe<Collection>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `Collection` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `Collection` edge in the connection. */
export type CollectionsEdge = {
  __typename?: 'CollectionsEdge'
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>
  /** The `Collection` at the end of the edge. */
  node?: Maybe<Collection>
}

/** Methods to use when ordering `Collection`. */
export enum CollectionsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  OrganisationIdAsc = 'ORGANISATION_ID_ASC',
  OrganisationIdDesc = 'ORGANISATION_ID_DESC',
  PersonIdAsc = 'PERSON_ID_ASC',
  PersonIdDesc = 'PERSON_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
}

export type Comment = Node & {
  __typename?: 'Comment'
  content?: Maybe<Scalars['String']>
  createdAt: Scalars['Datetime']
  /** Reads a single `File` that is related to this `Comment`. */
  file?: Maybe<File>
  fileId?: Maybe<Scalars['UUID']>
  /** Reads and enables pagination through a set of `File`. */
  filesByNotificationCommentIdAndFileId: CommentFilesByNotificationCommentIdAndFileIdManyToManyConnection
  /** Reads and enables pagination through a set of `File`. */
  filesByReactionCommentIdAndFileId: CommentFilesByReactionCommentIdAndFileIdManyToManyConnection
  id: Scalars['UUID']
  /** Reads and enables pagination through a set of `Mention`. */
  mentions: MentionsConnection
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']
  /** Reads and enables pagination through a set of `Notification`. */
  notifications: NotificationsConnection
  /** Reads and enables pagination through a set of `Organisation`. */
  organisationsByMentionCommentIdAndOrganisationId: CommentOrganisationsByMentionCommentIdAndOrganisationIdManyToManyConnection
  /** Reads and enables pagination through a set of `Organisation`. */
  organisationsByNotificationCommentIdAndOrganisationId: CommentOrganisationsByNotificationCommentIdAndOrganisationIdManyToManyConnection
  /** Reads and enables pagination through a set of `Person`. */
  peopleByMentionCommentIdAndMentionedId: CommentPeopleByMentionCommentIdAndMentionedIdManyToManyConnection
  /** Reads and enables pagination through a set of `Person`. */
  peopleByMentionCommentIdAndPersonId: CommentPeopleByMentionCommentIdAndPersonIdManyToManyConnection
  /** Reads and enables pagination through a set of `Person`. */
  peopleByNotificationCommentIdAndPersonId: CommentPeopleByNotificationCommentIdAndPersonIdManyToManyConnection
  /** Reads and enables pagination through a set of `Person`. */
  peopleByNotificationCommentIdAndReceiverId: CommentPeopleByNotificationCommentIdAndReceiverIdManyToManyConnection
  /** Reads and enables pagination through a set of `Person`. */
  peopleByReactionCommentIdAndPersonId: CommentPeopleByReactionCommentIdAndPersonIdManyToManyConnection
  /** Reads a single `Person` that is related to this `Comment`. */
  person?: Maybe<Person>
  personId?: Maybe<Scalars['UUID']>
  /** Reads and enables pagination through a set of `Reaction`. */
  reactions: ReactionsConnection
  updatedAt: Scalars['Datetime']
}

export type CommentFilesByNotificationCommentIdAndFileIdArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<FileCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<FilesOrderBy>>
}

export type CommentFilesByReactionCommentIdAndFileIdArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<FileCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<FilesOrderBy>>
}

export type CommentMentionsArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<MentionCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<MentionsOrderBy>>
}

export type CommentNotificationsArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<NotificationCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<NotificationsOrderBy>>
}

export type CommentOrganisationsByMentionCommentIdAndOrganisationIdArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<OrganisationCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<OrganisationsOrderBy>>
}

export type CommentOrganisationsByNotificationCommentIdAndOrganisationIdArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<OrganisationCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<OrganisationsOrderBy>>
}

export type CommentPeopleByMentionCommentIdAndMentionedIdArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<PersonCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<PeopleOrderBy>>
}

export type CommentPeopleByMentionCommentIdAndPersonIdArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<PersonCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<PeopleOrderBy>>
}

export type CommentPeopleByNotificationCommentIdAndPersonIdArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<PersonCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<PeopleOrderBy>>
}

export type CommentPeopleByNotificationCommentIdAndReceiverIdArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<PersonCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<PeopleOrderBy>>
}

export type CommentPeopleByReactionCommentIdAndPersonIdArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<PersonCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<PeopleOrderBy>>
}

export type CommentReactionsArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<ReactionCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<ReactionsOrderBy>>
}

/** A condition to be used against `Comment` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type CommentCondition = {
  /** Checks for equality with the object’s `content` field. */
  content?: Maybe<Scalars['String']>
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>
  /** Checks for equality with the object’s `fileId` field. */
  fileId?: Maybe<Scalars['UUID']>
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>
  /** Checks for equality with the object’s `personId` field. */
  personId?: Maybe<Scalars['UUID']>
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>
}

/** A connection to a list of `File` values, with data from `Notification`. */
export type CommentFilesByNotificationCommentIdAndFileIdManyToManyConnection = {
  __typename?: 'CommentFilesByNotificationCommentIdAndFileIdManyToManyConnection'
  /** A list of edges which contains the `File`, info from the `Notification`, and the cursor to aid in pagination. */
  edges: Array<CommentFilesByNotificationCommentIdAndFileIdManyToManyEdge>
  /** A list of `File` objects. */
  nodes: Array<Maybe<File>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `File` edge in the connection, with data from `Notification`. */
export type CommentFilesByNotificationCommentIdAndFileIdManyToManyEdge = {
  __typename?: 'CommentFilesByNotificationCommentIdAndFileIdManyToManyEdge'
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>
  /** The `File` at the end of the edge. */
  node?: Maybe<File>
  /** Reads and enables pagination through a set of `Notification`. */
  notifications: NotificationsConnection
}

/** A `File` edge in the connection, with data from `Notification`. */
export type CommentFilesByNotificationCommentIdAndFileIdManyToManyEdgeNotificationsArgs =
  {
    after?: Maybe<Scalars['Cursor']>
    before?: Maybe<Scalars['Cursor']>
    condition?: Maybe<NotificationCondition>
    first?: Maybe<Scalars['Int']>
    last?: Maybe<Scalars['Int']>
    offset?: Maybe<Scalars['Int']>
    orderBy?: Maybe<Array<NotificationsOrderBy>>
  }

/** A connection to a list of `File` values, with data from `Reaction`. */
export type CommentFilesByReactionCommentIdAndFileIdManyToManyConnection = {
  __typename?: 'CommentFilesByReactionCommentIdAndFileIdManyToManyConnection'
  /** A list of edges which contains the `File`, info from the `Reaction`, and the cursor to aid in pagination. */
  edges: Array<CommentFilesByReactionCommentIdAndFileIdManyToManyEdge>
  /** A list of `File` objects. */
  nodes: Array<Maybe<File>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `File` edge in the connection, with data from `Reaction`. */
export type CommentFilesByReactionCommentIdAndFileIdManyToManyEdge = {
  __typename?: 'CommentFilesByReactionCommentIdAndFileIdManyToManyEdge'
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>
  /** The `File` at the end of the edge. */
  node?: Maybe<File>
  /** Reads and enables pagination through a set of `Reaction`. */
  reactions: ReactionsConnection
}

/** A `File` edge in the connection, with data from `Reaction`. */
export type CommentFilesByReactionCommentIdAndFileIdManyToManyEdgeReactionsArgs =
  {
    after?: Maybe<Scalars['Cursor']>
    before?: Maybe<Scalars['Cursor']>
    condition?: Maybe<ReactionCondition>
    first?: Maybe<Scalars['Int']>
    last?: Maybe<Scalars['Int']>
    offset?: Maybe<Scalars['Int']>
    orderBy?: Maybe<Array<ReactionsOrderBy>>
  }

/** An input for mutations affecting `Comment` */
export type CommentInput = {
  content?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['Datetime']>
  fileId?: Maybe<Scalars['UUID']>
  id?: Maybe<Scalars['UUID']>
  personId?: Maybe<Scalars['UUID']>
  updatedAt?: Maybe<Scalars['Datetime']>
}

/** A connection to a list of `Organisation` values, with data from `Mention`. */
export type CommentOrganisationsByMentionCommentIdAndOrganisationIdManyToManyConnection =
  {
    __typename?: 'CommentOrganisationsByMentionCommentIdAndOrganisationIdManyToManyConnection'
    /** A list of edges which contains the `Organisation`, info from the `Mention`, and the cursor to aid in pagination. */
    edges: Array<CommentOrganisationsByMentionCommentIdAndOrganisationIdManyToManyEdge>
    /** A list of `Organisation` objects. */
    nodes: Array<Maybe<Organisation>>
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** The count of *all* `Organisation` you could get from the connection. */
    totalCount: Scalars['Int']
  }

/** A `Organisation` edge in the connection, with data from `Mention`. */
export type CommentOrganisationsByMentionCommentIdAndOrganisationIdManyToManyEdge =
  {
    __typename?: 'CommentOrganisationsByMentionCommentIdAndOrganisationIdManyToManyEdge'
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>
    /** Reads and enables pagination through a set of `Mention`. */
    mentions: MentionsConnection
    /** The `Organisation` at the end of the edge. */
    node?: Maybe<Organisation>
  }

/** A `Organisation` edge in the connection, with data from `Mention`. */
export type CommentOrganisationsByMentionCommentIdAndOrganisationIdManyToManyEdgeMentionsArgs =
  {
    after?: Maybe<Scalars['Cursor']>
    before?: Maybe<Scalars['Cursor']>
    condition?: Maybe<MentionCondition>
    first?: Maybe<Scalars['Int']>
    last?: Maybe<Scalars['Int']>
    offset?: Maybe<Scalars['Int']>
    orderBy?: Maybe<Array<MentionsOrderBy>>
  }

/** A connection to a list of `Organisation` values, with data from `Notification`. */
export type CommentOrganisationsByNotificationCommentIdAndOrganisationIdManyToManyConnection =
  {
    __typename?: 'CommentOrganisationsByNotificationCommentIdAndOrganisationIdManyToManyConnection'
    /** A list of edges which contains the `Organisation`, info from the `Notification`, and the cursor to aid in pagination. */
    edges: Array<CommentOrganisationsByNotificationCommentIdAndOrganisationIdManyToManyEdge>
    /** A list of `Organisation` objects. */
    nodes: Array<Maybe<Organisation>>
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** The count of *all* `Organisation` you could get from the connection. */
    totalCount: Scalars['Int']
  }

/** A `Organisation` edge in the connection, with data from `Notification`. */
export type CommentOrganisationsByNotificationCommentIdAndOrganisationIdManyToManyEdge =
  {
    __typename?: 'CommentOrganisationsByNotificationCommentIdAndOrganisationIdManyToManyEdge'
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>
    /** The `Organisation` at the end of the edge. */
    node?: Maybe<Organisation>
    /** Reads and enables pagination through a set of `Notification`. */
    notifications: NotificationsConnection
  }

/** A `Organisation` edge in the connection, with data from `Notification`. */
export type CommentOrganisationsByNotificationCommentIdAndOrganisationIdManyToManyEdgeNotificationsArgs =
  {
    after?: Maybe<Scalars['Cursor']>
    before?: Maybe<Scalars['Cursor']>
    condition?: Maybe<NotificationCondition>
    first?: Maybe<Scalars['Int']>
    last?: Maybe<Scalars['Int']>
    offset?: Maybe<Scalars['Int']>
    orderBy?: Maybe<Array<NotificationsOrderBy>>
  }

/** Represents an update to a `Comment`. Fields that are set will be updated. */
export type CommentPatch = {
  content?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['Datetime']>
  fileId?: Maybe<Scalars['UUID']>
  id?: Maybe<Scalars['UUID']>
  personId?: Maybe<Scalars['UUID']>
  updatedAt?: Maybe<Scalars['Datetime']>
}

/** A connection to a list of `Person` values, with data from `Mention`. */
export type CommentPeopleByMentionCommentIdAndMentionedIdManyToManyConnection =
  {
    __typename?: 'CommentPeopleByMentionCommentIdAndMentionedIdManyToManyConnection'
    /** A list of edges which contains the `Person`, info from the `Mention`, and the cursor to aid in pagination. */
    edges: Array<CommentPeopleByMentionCommentIdAndMentionedIdManyToManyEdge>
    /** A list of `Person` objects. */
    nodes: Array<Maybe<Person>>
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** The count of *all* `Person` you could get from the connection. */
    totalCount: Scalars['Int']
  }

/** A `Person` edge in the connection, with data from `Mention`. */
export type CommentPeopleByMentionCommentIdAndMentionedIdManyToManyEdge = {
  __typename?: 'CommentPeopleByMentionCommentIdAndMentionedIdManyToManyEdge'
  createdAt: Scalars['Datetime']
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>
  id: Scalars['UUID']
  /** The `Person` at the end of the edge. */
  node?: Maybe<Person>
  organisationId: Scalars['UUID']
  personId?: Maybe<Scalars['UUID']>
  updatedAt: Scalars['Datetime']
}

/** A connection to a list of `Person` values, with data from `Mention`. */
export type CommentPeopleByMentionCommentIdAndPersonIdManyToManyConnection = {
  __typename?: 'CommentPeopleByMentionCommentIdAndPersonIdManyToManyConnection'
  /** A list of edges which contains the `Person`, info from the `Mention`, and the cursor to aid in pagination. */
  edges: Array<CommentPeopleByMentionCommentIdAndPersonIdManyToManyEdge>
  /** A list of `Person` objects. */
  nodes: Array<Maybe<Person>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `Person` edge in the connection, with data from `Mention`. */
export type CommentPeopleByMentionCommentIdAndPersonIdManyToManyEdge = {
  __typename?: 'CommentPeopleByMentionCommentIdAndPersonIdManyToManyEdge'
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>
  /** Reads and enables pagination through a set of `Mention`. */
  mentions: MentionsConnection
  /** The `Person` at the end of the edge. */
  node?: Maybe<Person>
}

/** A `Person` edge in the connection, with data from `Mention`. */
export type CommentPeopleByMentionCommentIdAndPersonIdManyToManyEdgeMentionsArgs =
  {
    after?: Maybe<Scalars['Cursor']>
    before?: Maybe<Scalars['Cursor']>
    condition?: Maybe<MentionCondition>
    first?: Maybe<Scalars['Int']>
    last?: Maybe<Scalars['Int']>
    offset?: Maybe<Scalars['Int']>
    orderBy?: Maybe<Array<MentionsOrderBy>>
  }

/** A connection to a list of `Person` values, with data from `Notification`. */
export type CommentPeopleByNotificationCommentIdAndPersonIdManyToManyConnection =
  {
    __typename?: 'CommentPeopleByNotificationCommentIdAndPersonIdManyToManyConnection'
    /** A list of edges which contains the `Person`, info from the `Notification`, and the cursor to aid in pagination. */
    edges: Array<CommentPeopleByNotificationCommentIdAndPersonIdManyToManyEdge>
    /** A list of `Person` objects. */
    nodes: Array<Maybe<Person>>
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** The count of *all* `Person` you could get from the connection. */
    totalCount: Scalars['Int']
  }

/** A `Person` edge in the connection, with data from `Notification`. */
export type CommentPeopleByNotificationCommentIdAndPersonIdManyToManyEdge = {
  __typename?: 'CommentPeopleByNotificationCommentIdAndPersonIdManyToManyEdge'
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>
  /** The `Person` at the end of the edge. */
  node?: Maybe<Person>
  /** Reads and enables pagination through a set of `Notification`. */
  notifications: NotificationsConnection
}

/** A `Person` edge in the connection, with data from `Notification`. */
export type CommentPeopleByNotificationCommentIdAndPersonIdManyToManyEdgeNotificationsArgs =
  {
    after?: Maybe<Scalars['Cursor']>
    before?: Maybe<Scalars['Cursor']>
    condition?: Maybe<NotificationCondition>
    first?: Maybe<Scalars['Int']>
    last?: Maybe<Scalars['Int']>
    offset?: Maybe<Scalars['Int']>
    orderBy?: Maybe<Array<NotificationsOrderBy>>
  }

/** A connection to a list of `Person` values, with data from `Notification`. */
export type CommentPeopleByNotificationCommentIdAndReceiverIdManyToManyConnection =
  {
    __typename?: 'CommentPeopleByNotificationCommentIdAndReceiverIdManyToManyConnection'
    /** A list of edges which contains the `Person`, info from the `Notification`, and the cursor to aid in pagination. */
    edges: Array<CommentPeopleByNotificationCommentIdAndReceiverIdManyToManyEdge>
    /** A list of `Person` objects. */
    nodes: Array<Maybe<Person>>
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** The count of *all* `Person` you could get from the connection. */
    totalCount: Scalars['Int']
  }

/** A `Person` edge in the connection, with data from `Notification`. */
export type CommentPeopleByNotificationCommentIdAndReceiverIdManyToManyEdge = {
  __typename?: 'CommentPeopleByNotificationCommentIdAndReceiverIdManyToManyEdge'
  createdAt: Scalars['Datetime']
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>
  fileId?: Maybe<Scalars['UUID']>
  id: Scalars['UUID']
  /** The `Person` at the end of the edge. */
  node?: Maybe<Person>
  organisationId: Scalars['UUID']
  personId?: Maybe<Scalars['UUID']>
  seen?: Maybe<Scalars['Boolean']>
  updatedAt: Scalars['Datetime']
}

/** A connection to a list of `Person` values, with data from `Reaction`. */
export type CommentPeopleByReactionCommentIdAndPersonIdManyToManyConnection = {
  __typename?: 'CommentPeopleByReactionCommentIdAndPersonIdManyToManyConnection'
  /** A list of edges which contains the `Person`, info from the `Reaction`, and the cursor to aid in pagination. */
  edges: Array<CommentPeopleByReactionCommentIdAndPersonIdManyToManyEdge>
  /** A list of `Person` objects. */
  nodes: Array<Maybe<Person>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `Person` edge in the connection, with data from `Reaction`. */
export type CommentPeopleByReactionCommentIdAndPersonIdManyToManyEdge = {
  __typename?: 'CommentPeopleByReactionCommentIdAndPersonIdManyToManyEdge'
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>
  /** The `Person` at the end of the edge. */
  node?: Maybe<Person>
  /** Reads and enables pagination through a set of `Reaction`. */
  reactions: ReactionsConnection
}

/** A `Person` edge in the connection, with data from `Reaction`. */
export type CommentPeopleByReactionCommentIdAndPersonIdManyToManyEdgeReactionsArgs =
  {
    after?: Maybe<Scalars['Cursor']>
    before?: Maybe<Scalars['Cursor']>
    condition?: Maybe<ReactionCondition>
    first?: Maybe<Scalars['Int']>
    last?: Maybe<Scalars['Int']>
    offset?: Maybe<Scalars['Int']>
    orderBy?: Maybe<Array<ReactionsOrderBy>>
  }

/** A connection to a list of `Comment` values. */
export type CommentsConnection = {
  __typename?: 'CommentsConnection'
  /** A list of edges which contains the `Comment` and cursor to aid in pagination. */
  edges: Array<CommentsEdge>
  /** A list of `Comment` objects. */
  nodes: Array<Maybe<Comment>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `Comment` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `Comment` edge in the connection. */
export type CommentsEdge = {
  __typename?: 'CommentsEdge'
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>
  /** The `Comment` at the end of the edge. */
  node?: Maybe<Comment>
}

/** Methods to use when ordering `Comment`. */
export enum CommentsOrderBy {
  ContentAsc = 'CONTENT_ASC',
  ContentDesc = 'CONTENT_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  FileIdAsc = 'FILE_ID_ASC',
  FileIdDesc = 'FILE_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PersonIdAsc = 'PERSON_ID_ASC',
  PersonIdDesc = 'PERSON_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
}

/** All input for the create `Collection` mutation. */
export type CreateCollectionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** The `Collection` to be created by this mutation. */
  collection: CollectionInput
}

/** The output of our create `Collection` mutation. */
export type CreateCollectionPayload = {
  __typename?: 'CreateCollectionPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** The `Collection` that was created by this mutation. */
  collection?: Maybe<Collection>
  /** An edge for our `Collection`. May be used by Relay 1. */
  collectionEdge?: Maybe<CollectionsEdge>
  /** Reads a single `Organisation` that is related to this `Collection`. */
  organisation?: Maybe<Organisation>
  /** Reads a single `Person` that is related to this `Collection`. */
  person?: Maybe<Person>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>
}

/** The output of our create `Collection` mutation. */
export type CreateCollectionPayloadCollectionEdgeArgs = {
  orderBy?: Maybe<Array<CollectionsOrderBy>>
}

/** All input for the create `Comment` mutation. */
export type CreateCommentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** The `Comment` to be created by this mutation. */
  comment: CommentInput
}

/** The output of our create `Comment` mutation. */
export type CreateCommentPayload = {
  __typename?: 'CreateCommentPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** The `Comment` that was created by this mutation. */
  comment?: Maybe<Comment>
  /** An edge for our `Comment`. May be used by Relay 1. */
  commentEdge?: Maybe<CommentsEdge>
  /** Reads a single `File` that is related to this `Comment`. */
  file?: Maybe<File>
  /** Reads a single `Person` that is related to this `Comment`. */
  person?: Maybe<Person>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>
}

/** The output of our create `Comment` mutation. */
export type CreateCommentPayloadCommentEdgeArgs = {
  orderBy?: Maybe<Array<CommentsOrderBy>>
}

/** All input for the create `FileCollection` mutation. */
export type CreateFileCollectionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** The `FileCollection` to be created by this mutation. */
  fileCollection: FileCollectionInput
}

/** The output of our create `FileCollection` mutation. */
export type CreateFileCollectionPayload = {
  __typename?: 'CreateFileCollectionPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** Reads a single `Collection` that is related to this `FileCollection`. */
  collection?: Maybe<Collection>
  /** Reads a single `File` that is related to this `FileCollection`. */
  file?: Maybe<File>
  /** The `FileCollection` that was created by this mutation. */
  fileCollection?: Maybe<FileCollection>
  /** An edge for our `FileCollection`. May be used by Relay 1. */
  fileCollectionEdge?: Maybe<FileCollectionsEdge>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>
}

/** The output of our create `FileCollection` mutation. */
export type CreateFileCollectionPayloadFileCollectionEdgeArgs = {
  orderBy?: Maybe<Array<FileCollectionsOrderBy>>
}

/** All input for the create `File` mutation. */
export type CreateFileInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** The `File` to be created by this mutation. */
  file: FileInput
}

/** The output of our create `File` mutation. */
export type CreateFilePayload = {
  __typename?: 'CreateFilePayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** The `File` that was created by this mutation. */
  file?: Maybe<File>
  /** An edge for our `File`. May be used by Relay 1. */
  fileEdge?: Maybe<FilesEdge>
  /** Reads a single `File` that is related to this `File`. */
  originalFile?: Maybe<File>
  /** Reads a single `Person` that is related to this `File`. */
  person?: Maybe<Person>
  /** Reads a single `Project` that is related to this `File`. */
  project?: Maybe<Project>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>
}

/** The output of our create `File` mutation. */
export type CreateFilePayloadFileEdgeArgs = {
  orderBy?: Maybe<Array<FilesOrderBy>>
}

/** All input for the create `Mention` mutation. */
export type CreateMentionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** The `Mention` to be created by this mutation. */
  mention: MentionInput
}

/** The output of our create `Mention` mutation. */
export type CreateMentionPayload = {
  __typename?: 'CreateMentionPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** Reads a single `Comment` that is related to this `Mention`. */
  comment?: Maybe<Comment>
  /** The `Mention` that was created by this mutation. */
  mention?: Maybe<Mention>
  /** An edge for our `Mention`. May be used by Relay 1. */
  mentionEdge?: Maybe<MentionsEdge>
  /** Reads a single `Person` that is related to this `Mention`. */
  mentioned?: Maybe<Person>
  /** Reads a single `Organisation` that is related to this `Mention`. */
  organisation?: Maybe<Organisation>
  /** Reads a single `Person` that is related to this `Mention`. */
  person?: Maybe<Person>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>
}

/** The output of our create `Mention` mutation. */
export type CreateMentionPayloadMentionEdgeArgs = {
  orderBy?: Maybe<Array<MentionsOrderBy>>
}

/** All input for the create `Notification` mutation. */
export type CreateNotificationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** The `Notification` to be created by this mutation. */
  notification: NotificationInput
}

/** The output of our create `Notification` mutation. */
export type CreateNotificationPayload = {
  __typename?: 'CreateNotificationPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** Reads a single `Comment` that is related to this `Notification`. */
  comment?: Maybe<Comment>
  /** Reads a single `File` that is related to this `Notification`. */
  file?: Maybe<File>
  /** The `Notification` that was created by this mutation. */
  notification?: Maybe<Notification>
  /** An edge for our `Notification`. May be used by Relay 1. */
  notificationEdge?: Maybe<NotificationsEdge>
  /** Reads a single `Organisation` that is related to this `Notification`. */
  organisation?: Maybe<Organisation>
  /** Reads a single `Person` that is related to this `Notification`. */
  person?: Maybe<Person>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>
  /** Reads a single `Person` that is related to this `Notification`. */
  receiver?: Maybe<Person>
}

/** The output of our create `Notification` mutation. */
export type CreateNotificationPayloadNotificationEdgeArgs = {
  orderBy?: Maybe<Array<NotificationsOrderBy>>
}

/** All input for the create `Organisation` mutation. */
export type CreateOrganisationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** The `Organisation` to be created by this mutation. */
  organisation: OrganisationInput
}

/** All input for the `createOrganisationInvitation` mutation. */
export type CreateOrganisationInvitationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  organisationId?: Maybe<Scalars['UUID']>
}

/** The output of our `createOrganisationInvitation` mutation. */
export type CreateOrganisationInvitationPayload = {
  __typename?: 'CreateOrganisationInvitationPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** Reads a single `Organisation` that is related to this `OrganisationInvitation`. */
  organisation?: Maybe<Organisation>
  organisationInvitation?: Maybe<OrganisationInvitation>
  /** An edge for our `OrganisationInvitation`. May be used by Relay 1. */
  organisationInvitationEdge?: Maybe<OrganisationInvitationsEdge>
  /** Reads a single `Person` that is related to this `OrganisationInvitation`. */
  personByAcceptedBy?: Maybe<Person>
  /** Reads a single `Person` that is related to this `OrganisationInvitation`. */
  personByInvitedBy?: Maybe<Person>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>
}

/** The output of our `createOrganisationInvitation` mutation. */
export type CreateOrganisationInvitationPayloadOrganisationInvitationEdgeArgs =
  {
    orderBy?: Maybe<Array<OrganisationInvitationsOrderBy>>
  }

/** The output of our create `Organisation` mutation. */
export type CreateOrganisationPayload = {
  __typename?: 'CreateOrganisationPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** The `Organisation` that was created by this mutation. */
  organisation?: Maybe<Organisation>
  /** An edge for our `Organisation`. May be used by Relay 1. */
  organisationEdge?: Maybe<OrganisationsEdge>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>
}

/** The output of our create `Organisation` mutation. */
export type CreateOrganisationPayloadOrganisationEdgeArgs = {
  orderBy?: Maybe<Array<OrganisationsOrderBy>>
}

/** All input for the create `Person` mutation. */
export type CreatePersonInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** The `Person` to be created by this mutation. */
  person: PersonInput
}

/** All input for the create `PersonOrganisation` mutation. */
export type CreatePersonOrganisationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** The `PersonOrganisation` to be created by this mutation. */
  personOrganisation: PersonOrganisationInput
}

/** The output of our create `PersonOrganisation` mutation. */
export type CreatePersonOrganisationPayload = {
  __typename?: 'CreatePersonOrganisationPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** Reads a single `Organisation` that is related to this `PersonOrganisation`. */
  organisation?: Maybe<Organisation>
  /** Reads a single `Person` that is related to this `PersonOrganisation`. */
  person?: Maybe<Person>
  /** The `PersonOrganisation` that was created by this mutation. */
  personOrganisation?: Maybe<PersonOrganisation>
  /** An edge for our `PersonOrganisation`. May be used by Relay 1. */
  personOrganisationEdge?: Maybe<PersonOrganisationsEdge>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>
}

/** The output of our create `PersonOrganisation` mutation. */
export type CreatePersonOrganisationPayloadPersonOrganisationEdgeArgs = {
  orderBy?: Maybe<Array<PersonOrganisationsOrderBy>>
}

/** The output of our create `Person` mutation. */
export type CreatePersonPayload = {
  __typename?: 'CreatePersonPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** The `Person` that was created by this mutation. */
  person?: Maybe<Person>
  /** An edge for our `Person`. May be used by Relay 1. */
  personEdge?: Maybe<PeopleEdge>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>
}

/** The output of our create `Person` mutation. */
export type CreatePersonPayloadPersonEdgeArgs = {
  orderBy?: Maybe<Array<PeopleOrderBy>>
}

/** All input for the create `PersonProject` mutation. */
export type CreatePersonProjectInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** The `PersonProject` to be created by this mutation. */
  personProject: PersonProjectInput
}

/** The output of our create `PersonProject` mutation. */
export type CreatePersonProjectPayload = {
  __typename?: 'CreatePersonProjectPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** Reads a single `Person` that is related to this `PersonProject`. */
  person?: Maybe<Person>
  /** The `PersonProject` that was created by this mutation. */
  personProject?: Maybe<PersonProject>
  /** An edge for our `PersonProject`. May be used by Relay 1. */
  personProjectEdge?: Maybe<PersonProjectsEdge>
  /** Reads a single `Project` that is related to this `PersonProject`. */
  project?: Maybe<Project>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>
}

/** The output of our create `PersonProject` mutation. */
export type CreatePersonProjectPayloadPersonProjectEdgeArgs = {
  orderBy?: Maybe<Array<PersonProjectsOrderBy>>
}

/** All input for the `createProject` mutation. */
export type CreateProjectInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  project?: Maybe<ProjectInput>
}

/** The output of our `createProject` mutation. */
export type CreateProjectPayload = {
  __typename?: 'CreateProjectPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** Reads a single `Organisation` that is related to this `Project`. */
  organisation?: Maybe<Organisation>
  /** Reads a single `Person` that is related to this `Project`. */
  person?: Maybe<Person>
  project?: Maybe<Project>
  /** An edge for our `Project`. May be used by Relay 1. */
  projectEdge?: Maybe<ProjectsEdge>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>
}

/** The output of our `createProject` mutation. */
export type CreateProjectPayloadProjectEdgeArgs = {
  orderBy?: Maybe<Array<ProjectsOrderBy>>
}

/** All input for the create `Reaction` mutation. */
export type CreateReactionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** The `Reaction` to be created by this mutation. */
  reaction: ReactionInput
}

/** The output of our create `Reaction` mutation. */
export type CreateReactionPayload = {
  __typename?: 'CreateReactionPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** Reads a single `Comment` that is related to this `Reaction`. */
  comment?: Maybe<Comment>
  /** Reads a single `File` that is related to this `Reaction`. */
  file?: Maybe<File>
  /** Reads a single `Person` that is related to this `Reaction`. */
  person?: Maybe<Person>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>
  /** The `Reaction` that was created by this mutation. */
  reaction?: Maybe<Reaction>
  /** An edge for our `Reaction`. May be used by Relay 1. */
  reactionEdge?: Maybe<ReactionsEdge>
}

/** The output of our create `Reaction` mutation. */
export type CreateReactionPayloadReactionEdgeArgs = {
  orderBy?: Maybe<Array<ReactionsOrderBy>>
}

/** All input for the `deleteCollectionByNodeId` mutation. */
export type DeleteCollectionByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** The globally unique `ID` which will identify a single `Collection` to be deleted. */
  nodeId: Scalars['ID']
}

/** All input for the `deleteCollection` mutation. */
export type DeleteCollectionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  id: Scalars['UUID']
}

/** The output of our delete `Collection` mutation. */
export type DeleteCollectionPayload = {
  __typename?: 'DeleteCollectionPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** The `Collection` that was deleted by this mutation. */
  collection?: Maybe<Collection>
  /** An edge for our `Collection`. May be used by Relay 1. */
  collectionEdge?: Maybe<CollectionsEdge>
  deletedCollectionNodeId?: Maybe<Scalars['ID']>
  /** Reads a single `Organisation` that is related to this `Collection`. */
  organisation?: Maybe<Organisation>
  /** Reads a single `Person` that is related to this `Collection`. */
  person?: Maybe<Person>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>
}

/** The output of our delete `Collection` mutation. */
export type DeleteCollectionPayloadCollectionEdgeArgs = {
  orderBy?: Maybe<Array<CollectionsOrderBy>>
}

/** All input for the `deleteCommentByNodeId` mutation. */
export type DeleteCommentByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** The globally unique `ID` which will identify a single `Comment` to be deleted. */
  nodeId: Scalars['ID']
}

/** All input for the `deleteComment` mutation. */
export type DeleteCommentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  id: Scalars['UUID']
}

/** The output of our delete `Comment` mutation. */
export type DeleteCommentPayload = {
  __typename?: 'DeleteCommentPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** The `Comment` that was deleted by this mutation. */
  comment?: Maybe<Comment>
  /** An edge for our `Comment`. May be used by Relay 1. */
  commentEdge?: Maybe<CommentsEdge>
  deletedCommentNodeId?: Maybe<Scalars['ID']>
  /** Reads a single `File` that is related to this `Comment`. */
  file?: Maybe<File>
  /** Reads a single `Person` that is related to this `Comment`. */
  person?: Maybe<Person>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>
}

/** The output of our delete `Comment` mutation. */
export type DeleteCommentPayloadCommentEdgeArgs = {
  orderBy?: Maybe<Array<CommentsOrderBy>>
}

/** All input for the `deleteFileByNodeId` mutation. */
export type DeleteFileByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** The globally unique `ID` which will identify a single `File` to be deleted. */
  nodeId: Scalars['ID']
}

/** All input for the `deleteFileCollectionByCollectionIdAndFileId` mutation. */
export type DeleteFileCollectionByCollectionIdAndFileIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  collectionId: Scalars['UUID']
  fileId: Scalars['UUID']
}

/** All input for the `deleteFileCollectionByNodeId` mutation. */
export type DeleteFileCollectionByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** The globally unique `ID` which will identify a single `FileCollection` to be deleted. */
  nodeId: Scalars['ID']
}

/** All input for the `deleteFileCollection` mutation. */
export type DeleteFileCollectionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  id: Scalars['UUID']
}

/** The output of our delete `FileCollection` mutation. */
export type DeleteFileCollectionPayload = {
  __typename?: 'DeleteFileCollectionPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** Reads a single `Collection` that is related to this `FileCollection`. */
  collection?: Maybe<Collection>
  deletedFileCollectionNodeId?: Maybe<Scalars['ID']>
  /** Reads a single `File` that is related to this `FileCollection`. */
  file?: Maybe<File>
  /** The `FileCollection` that was deleted by this mutation. */
  fileCollection?: Maybe<FileCollection>
  /** An edge for our `FileCollection`. May be used by Relay 1. */
  fileCollectionEdge?: Maybe<FileCollectionsEdge>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>
}

/** The output of our delete `FileCollection` mutation. */
export type DeleteFileCollectionPayloadFileCollectionEdgeArgs = {
  orderBy?: Maybe<Array<FileCollectionsOrderBy>>
}

/** All input for the `deleteFile` mutation. */
export type DeleteFileInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  id: Scalars['UUID']
}

/** The output of our delete `File` mutation. */
export type DeleteFilePayload = {
  __typename?: 'DeleteFilePayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>
  deletedFileNodeId?: Maybe<Scalars['ID']>
  /** The `File` that was deleted by this mutation. */
  file?: Maybe<File>
  /** An edge for our `File`. May be used by Relay 1. */
  fileEdge?: Maybe<FilesEdge>
  /** Reads a single `File` that is related to this `File`. */
  originalFile?: Maybe<File>
  /** Reads a single `Person` that is related to this `File`. */
  person?: Maybe<Person>
  /** Reads a single `Project` that is related to this `File`. */
  project?: Maybe<Project>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>
}

/** The output of our delete `File` mutation. */
export type DeleteFilePayloadFileEdgeArgs = {
  orderBy?: Maybe<Array<FilesOrderBy>>
}

/** All input for the `deleteMentionByMentionedIdAndCommentId` mutation. */
export type DeleteMentionByMentionedIdAndCommentIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  commentId: Scalars['UUID']
  mentionedId: Scalars['UUID']
}

/** All input for the `deleteMentionByNodeId` mutation. */
export type DeleteMentionByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** The globally unique `ID` which will identify a single `Mention` to be deleted. */
  nodeId: Scalars['ID']
}

/** All input for the `deleteMention` mutation. */
export type DeleteMentionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  id: Scalars['UUID']
}

/** The output of our delete `Mention` mutation. */
export type DeleteMentionPayload = {
  __typename?: 'DeleteMentionPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** Reads a single `Comment` that is related to this `Mention`. */
  comment?: Maybe<Comment>
  deletedMentionNodeId?: Maybe<Scalars['ID']>
  /** The `Mention` that was deleted by this mutation. */
  mention?: Maybe<Mention>
  /** An edge for our `Mention`. May be used by Relay 1. */
  mentionEdge?: Maybe<MentionsEdge>
  /** Reads a single `Person` that is related to this `Mention`. */
  mentioned?: Maybe<Person>
  /** Reads a single `Organisation` that is related to this `Mention`. */
  organisation?: Maybe<Organisation>
  /** Reads a single `Person` that is related to this `Mention`. */
  person?: Maybe<Person>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>
}

/** The output of our delete `Mention` mutation. */
export type DeleteMentionPayloadMentionEdgeArgs = {
  orderBy?: Maybe<Array<MentionsOrderBy>>
}

/** All input for the `deleteNotificationByNodeId` mutation. */
export type DeleteNotificationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** The globally unique `ID` which will identify a single `Notification` to be deleted. */
  nodeId: Scalars['ID']
}

/** All input for the `deleteNotificationByReceiverIdAndCommentId` mutation. */
export type DeleteNotificationByReceiverIdAndCommentIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  commentId: Scalars['UUID']
  receiverId: Scalars['UUID']
}

/** All input for the `deleteNotificationByReceiverIdAndFileId` mutation. */
export type DeleteNotificationByReceiverIdAndFileIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  fileId: Scalars['UUID']
  receiverId: Scalars['UUID']
}

/** All input for the `deleteNotification` mutation. */
export type DeleteNotificationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  id: Scalars['UUID']
}

/** The output of our delete `Notification` mutation. */
export type DeleteNotificationPayload = {
  __typename?: 'DeleteNotificationPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** Reads a single `Comment` that is related to this `Notification`. */
  comment?: Maybe<Comment>
  deletedNotificationNodeId?: Maybe<Scalars['ID']>
  /** Reads a single `File` that is related to this `Notification`. */
  file?: Maybe<File>
  /** The `Notification` that was deleted by this mutation. */
  notification?: Maybe<Notification>
  /** An edge for our `Notification`. May be used by Relay 1. */
  notificationEdge?: Maybe<NotificationsEdge>
  /** Reads a single `Organisation` that is related to this `Notification`. */
  organisation?: Maybe<Organisation>
  /** Reads a single `Person` that is related to this `Notification`. */
  person?: Maybe<Person>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>
  /** Reads a single `Person` that is related to this `Notification`. */
  receiver?: Maybe<Person>
}

/** The output of our delete `Notification` mutation. */
export type DeleteNotificationPayloadNotificationEdgeArgs = {
  orderBy?: Maybe<Array<NotificationsOrderBy>>
}

/** All input for the `deleteOrganisationByNodeId` mutation. */
export type DeleteOrganisationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** The globally unique `ID` which will identify a single `Organisation` to be deleted. */
  nodeId: Scalars['ID']
}

/** All input for the `deleteOrganisation` mutation. */
export type DeleteOrganisationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  id: Scalars['UUID']
}

/** All input for the `deleteOrganisationInvitationByNodeId` mutation. */
export type DeleteOrganisationInvitationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** The globally unique `ID` which will identify a single `OrganisationInvitation` to be deleted. */
  nodeId: Scalars['ID']
}

/** All input for the `deleteOrganisationInvitation` mutation. */
export type DeleteOrganisationInvitationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  id: Scalars['UUID']
}

/** The output of our delete `OrganisationInvitation` mutation. */
export type DeleteOrganisationInvitationPayload = {
  __typename?: 'DeleteOrganisationInvitationPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>
  deletedOrganisationInvitationNodeId?: Maybe<Scalars['ID']>
  /** Reads a single `Organisation` that is related to this `OrganisationInvitation`. */
  organisation?: Maybe<Organisation>
  /** The `OrganisationInvitation` that was deleted by this mutation. */
  organisationInvitation?: Maybe<OrganisationInvitation>
  /** An edge for our `OrganisationInvitation`. May be used by Relay 1. */
  organisationInvitationEdge?: Maybe<OrganisationInvitationsEdge>
  /** Reads a single `Person` that is related to this `OrganisationInvitation`. */
  personByAcceptedBy?: Maybe<Person>
  /** Reads a single `Person` that is related to this `OrganisationInvitation`. */
  personByInvitedBy?: Maybe<Person>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>
}

/** The output of our delete `OrganisationInvitation` mutation. */
export type DeleteOrganisationInvitationPayloadOrganisationInvitationEdgeArgs =
  {
    orderBy?: Maybe<Array<OrganisationInvitationsOrderBy>>
  }

/** The output of our delete `Organisation` mutation. */
export type DeleteOrganisationPayload = {
  __typename?: 'DeleteOrganisationPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>
  deletedOrganisationNodeId?: Maybe<Scalars['ID']>
  /** The `Organisation` that was deleted by this mutation. */
  organisation?: Maybe<Organisation>
  /** An edge for our `Organisation`. May be used by Relay 1. */
  organisationEdge?: Maybe<OrganisationsEdge>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>
}

/** The output of our delete `Organisation` mutation. */
export type DeleteOrganisationPayloadOrganisationEdgeArgs = {
  orderBy?: Maybe<Array<OrganisationsOrderBy>>
}

/** All input for the `deletePersonByFirebaseId` mutation. */
export type DeletePersonByFirebaseIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  firebaseId: Scalars['String']
}

/** All input for the `deletePersonByNodeId` mutation. */
export type DeletePersonByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** The globally unique `ID` which will identify a single `Person` to be deleted. */
  nodeId: Scalars['ID']
}

/** All input for the `deletePerson` mutation. */
export type DeletePersonInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  id: Scalars['UUID']
}

/** All input for the `deletePersonOrganisationByNodeId` mutation. */
export type DeletePersonOrganisationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** The globally unique `ID` which will identify a single `PersonOrganisation` to be deleted. */
  nodeId: Scalars['ID']
}

/** All input for the `deletePersonOrganisationByOrganisationIdAndPersonId` mutation. */
export type DeletePersonOrganisationByOrganisationIdAndPersonIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  organisationId: Scalars['UUID']
  personId: Scalars['UUID']
}

/** All input for the `deletePersonOrganisation` mutation. */
export type DeletePersonOrganisationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  id: Scalars['UUID']
}

/** The output of our delete `PersonOrganisation` mutation. */
export type DeletePersonOrganisationPayload = {
  __typename?: 'DeletePersonOrganisationPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>
  deletedPersonOrganisationNodeId?: Maybe<Scalars['ID']>
  /** Reads a single `Organisation` that is related to this `PersonOrganisation`. */
  organisation?: Maybe<Organisation>
  /** Reads a single `Person` that is related to this `PersonOrganisation`. */
  person?: Maybe<Person>
  /** The `PersonOrganisation` that was deleted by this mutation. */
  personOrganisation?: Maybe<PersonOrganisation>
  /** An edge for our `PersonOrganisation`. May be used by Relay 1. */
  personOrganisationEdge?: Maybe<PersonOrganisationsEdge>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>
}

/** The output of our delete `PersonOrganisation` mutation. */
export type DeletePersonOrganisationPayloadPersonOrganisationEdgeArgs = {
  orderBy?: Maybe<Array<PersonOrganisationsOrderBy>>
}

/** The output of our delete `Person` mutation. */
export type DeletePersonPayload = {
  __typename?: 'DeletePersonPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>
  deletedPersonNodeId?: Maybe<Scalars['ID']>
  /** The `Person` that was deleted by this mutation. */
  person?: Maybe<Person>
  /** An edge for our `Person`. May be used by Relay 1. */
  personEdge?: Maybe<PeopleEdge>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>
}

/** The output of our delete `Person` mutation. */
export type DeletePersonPayloadPersonEdgeArgs = {
  orderBy?: Maybe<Array<PeopleOrderBy>>
}

/** All input for the `deletePersonProjectByNodeId` mutation. */
export type DeletePersonProjectByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** The globally unique `ID` which will identify a single `PersonProject` to be deleted. */
  nodeId: Scalars['ID']
}

/** All input for the `deletePersonProjectByPersonIdAndProjectId` mutation. */
export type DeletePersonProjectByPersonIdAndProjectIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  personId: Scalars['UUID']
  projectId: Scalars['UUID']
}

/** All input for the `deletePersonProject` mutation. */
export type DeletePersonProjectInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  id: Scalars['UUID']
}

/** The output of our delete `PersonProject` mutation. */
export type DeletePersonProjectPayload = {
  __typename?: 'DeletePersonProjectPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>
  deletedPersonProjectNodeId?: Maybe<Scalars['ID']>
  /** Reads a single `Person` that is related to this `PersonProject`. */
  person?: Maybe<Person>
  /** The `PersonProject` that was deleted by this mutation. */
  personProject?: Maybe<PersonProject>
  /** An edge for our `PersonProject`. May be used by Relay 1. */
  personProjectEdge?: Maybe<PersonProjectsEdge>
  /** Reads a single `Project` that is related to this `PersonProject`. */
  project?: Maybe<Project>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>
}

/** The output of our delete `PersonProject` mutation. */
export type DeletePersonProjectPayloadPersonProjectEdgeArgs = {
  orderBy?: Maybe<Array<PersonProjectsOrderBy>>
}

/** All input for the `deleteProjectByNodeId` mutation. */
export type DeleteProjectByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** The globally unique `ID` which will identify a single `Project` to be deleted. */
  nodeId: Scalars['ID']
}

/** All input for the `deleteProject` mutation. */
export type DeleteProjectInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  id: Scalars['UUID']
}

/** The output of our delete `Project` mutation. */
export type DeleteProjectPayload = {
  __typename?: 'DeleteProjectPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>
  deletedProjectNodeId?: Maybe<Scalars['ID']>
  /** Reads a single `Organisation` that is related to this `Project`. */
  organisation?: Maybe<Organisation>
  /** Reads a single `Person` that is related to this `Project`. */
  person?: Maybe<Person>
  /** The `Project` that was deleted by this mutation. */
  project?: Maybe<Project>
  /** An edge for our `Project`. May be used by Relay 1. */
  projectEdge?: Maybe<ProjectsEdge>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>
}

/** The output of our delete `Project` mutation. */
export type DeleteProjectPayloadProjectEdgeArgs = {
  orderBy?: Maybe<Array<ProjectsOrderBy>>
}

/** All input for the `deleteReactionByNodeId` mutation. */
export type DeleteReactionByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** The globally unique `ID` which will identify a single `Reaction` to be deleted. */
  nodeId: Scalars['ID']
}

/** All input for the `deleteReaction` mutation. */
export type DeleteReactionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  id: Scalars['UUID']
}

/** The output of our delete `Reaction` mutation. */
export type DeleteReactionPayload = {
  __typename?: 'DeleteReactionPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** Reads a single `Comment` that is related to this `Reaction`. */
  comment?: Maybe<Comment>
  deletedReactionNodeId?: Maybe<Scalars['ID']>
  /** Reads a single `File` that is related to this `Reaction`. */
  file?: Maybe<File>
  /** Reads a single `Person` that is related to this `Reaction`. */
  person?: Maybe<Person>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>
  /** The `Reaction` that was deleted by this mutation. */
  reaction?: Maybe<Reaction>
  /** An edge for our `Reaction`. May be used by Relay 1. */
  reactionEdge?: Maybe<ReactionsEdge>
}

/** The output of our delete `Reaction` mutation. */
export type DeleteReactionPayloadReactionEdgeArgs = {
  orderBy?: Maybe<Array<ReactionsOrderBy>>
}

export type Dimension = {
  __typename?: 'Dimension'
  height?: Maybe<Scalars['Int']>
  width?: Maybe<Scalars['Int']>
}

/** An input for mutations affecting `Dimension` */
export type DimensionInput = {
  height?: Maybe<Scalars['Int']>
  width?: Maybe<Scalars['Int']>
}

export type File = Node & {
  __typename?: 'File'
  /** Reads and enables pagination through a set of `Collection`. */
  collectionsByFileCollectionFileIdAndCollectionId: FileCollectionsByFileCollectionFileIdAndCollectionIdManyToManyConnection
  /** Reads and enables pagination through a set of `Comment`. */
  comments: CommentsConnection
  /** Reads and enables pagination through a set of `Comment`. */
  commentsByNotificationFileIdAndCommentId: FileCommentsByNotificationFileIdAndCommentIdManyToManyConnection
  /** Reads and enables pagination through a set of `Comment`. */
  commentsByReactionFileIdAndCommentId: FileCommentsByReactionFileIdAndCommentIdManyToManyConnection
  createdAt: Scalars['Datetime']
  description?: Maybe<Scalars['String']>
  dimensions: Dimension
  duration: Scalars['Int']
  /** Reads and enables pagination through a set of `FileCollection`. */
  fileCollections: FileCollectionsConnection
  /** Reads and enables pagination through a set of `File`. */
  filesByOriginalFileId: FilesConnection
  format?: Maybe<Format>
  id: Scalars['UUID']
  location?: Maybe<Location>
  medium?: Maybe<Medium>
  name?: Maybe<Scalars['String']>
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']
  /** Reads and enables pagination through a set of `Notification`. */
  notifications: NotificationsConnection
  /** Reads and enables pagination through a set of `Organisation`. */
  organisationsByNotificationFileIdAndOrganisationId: FileOrganisationsByNotificationFileIdAndOrganisationIdManyToManyConnection
  originalCreationDate?: Maybe<Scalars['Datetime']>
  /** Reads a single `File` that is related to this `File`. */
  originalFile?: Maybe<File>
  originalFileId?: Maybe<Scalars['UUID']>
  /** Reads and enables pagination through a set of `Person`. */
  peopleByCommentFileIdAndPersonId: FilePeopleByCommentFileIdAndPersonIdManyToManyConnection
  /** Reads and enables pagination through a set of `Person`. */
  peopleByFileOriginalFileIdAndPersonId: FilePeopleByFileOriginalFileIdAndPersonIdManyToManyConnection
  /** Reads and enables pagination through a set of `Person`. */
  peopleByNotificationFileIdAndPersonId: FilePeopleByNotificationFileIdAndPersonIdManyToManyConnection
  /** Reads and enables pagination through a set of `Person`. */
  peopleByNotificationFileIdAndReceiverId: FilePeopleByNotificationFileIdAndReceiverIdManyToManyConnection
  /** Reads and enables pagination through a set of `Person`. */
  peopleByReactionFileIdAndPersonId: FilePeopleByReactionFileIdAndPersonIdManyToManyConnection
  /** Reads a single `Person` that is related to this `File`. */
  person?: Maybe<Person>
  personId?: Maybe<Scalars['UUID']>
  /** Reads a single `Project` that is related to this `File`. */
  project?: Maybe<Project>
  projectId: Scalars['UUID']
  /** Reads and enables pagination through a set of `Project`. */
  projectsByFileOriginalFileIdAndProjectId: FileProjectsByFileOriginalFileIdAndProjectIdManyToManyConnection
  /** Reads and enables pagination through a set of `Reaction`. */
  reactions: ReactionsConnection
  size: Scalars['Int']
  thumbnails?: Maybe<Thumbnail>
  updatedAt: Scalars['Datetime']
  uploaded: Scalars['Boolean']
  url?: Maybe<Scalars['String']>
}

export type FileCollectionsByFileCollectionFileIdAndCollectionIdArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<CollectionCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<CollectionsOrderBy>>
}

export type FileCommentsArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<CommentCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<CommentsOrderBy>>
}

export type FileCommentsByNotificationFileIdAndCommentIdArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<CommentCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<CommentsOrderBy>>
}

export type FileCommentsByReactionFileIdAndCommentIdArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<CommentCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<CommentsOrderBy>>
}

export type FileFileCollectionsArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<FileCollectionCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<FileCollectionsOrderBy>>
}

export type FileFilesByOriginalFileIdArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<FileCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<FilesOrderBy>>
}

export type FileNotificationsArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<NotificationCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<NotificationsOrderBy>>
}

export type FileOrganisationsByNotificationFileIdAndOrganisationIdArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<OrganisationCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<OrganisationsOrderBy>>
}

export type FilePeopleByCommentFileIdAndPersonIdArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<PersonCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<PeopleOrderBy>>
}

export type FilePeopleByFileOriginalFileIdAndPersonIdArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<PersonCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<PeopleOrderBy>>
}

export type FilePeopleByNotificationFileIdAndPersonIdArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<PersonCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<PeopleOrderBy>>
}

export type FilePeopleByNotificationFileIdAndReceiverIdArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<PersonCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<PeopleOrderBy>>
}

export type FilePeopleByReactionFileIdAndPersonIdArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<PersonCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<PeopleOrderBy>>
}

export type FileProjectsByFileOriginalFileIdAndProjectIdArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<ProjectCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<ProjectsOrderBy>>
}

export type FileReactionsArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<ReactionCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<ReactionsOrderBy>>
}

export type FileCollection = Node & {
  __typename?: 'FileCollection'
  /** Reads a single `Collection` that is related to this `FileCollection`. */
  collection?: Maybe<Collection>
  collectionId?: Maybe<Scalars['UUID']>
  createdAt: Scalars['Datetime']
  /** Reads a single `File` that is related to this `FileCollection`. */
  file?: Maybe<File>
  fileId?: Maybe<Scalars['UUID']>
  id: Scalars['UUID']
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']
  updatedAt: Scalars['Datetime']
}

/**
 * A condition to be used against `FileCollection` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type FileCollectionCondition = {
  /** Checks for equality with the object’s `collectionId` field. */
  collectionId?: Maybe<Scalars['UUID']>
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>
  /** Checks for equality with the object’s `fileId` field. */
  fileId?: Maybe<Scalars['UUID']>
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>
}

/** An input for mutations affecting `FileCollection` */
export type FileCollectionInput = {
  collectionId?: Maybe<Scalars['UUID']>
  createdAt?: Maybe<Scalars['Datetime']>
  fileId?: Maybe<Scalars['UUID']>
  id?: Maybe<Scalars['UUID']>
  updatedAt?: Maybe<Scalars['Datetime']>
}

/** Represents an update to a `FileCollection`. Fields that are set will be updated. */
export type FileCollectionPatch = {
  collectionId?: Maybe<Scalars['UUID']>
  createdAt?: Maybe<Scalars['Datetime']>
  fileId?: Maybe<Scalars['UUID']>
  id?: Maybe<Scalars['UUID']>
  updatedAt?: Maybe<Scalars['Datetime']>
}

/** A connection to a list of `Collection` values, with data from `FileCollection`. */
export type FileCollectionsByFileCollectionFileIdAndCollectionIdManyToManyConnection =
  {
    __typename?: 'FileCollectionsByFileCollectionFileIdAndCollectionIdManyToManyConnection'
    /** A list of edges which contains the `Collection`, info from the `FileCollection`, and the cursor to aid in pagination. */
    edges: Array<FileCollectionsByFileCollectionFileIdAndCollectionIdManyToManyEdge>
    /** A list of `Collection` objects. */
    nodes: Array<Maybe<Collection>>
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** The count of *all* `Collection` you could get from the connection. */
    totalCount: Scalars['Int']
  }

/** A `Collection` edge in the connection, with data from `FileCollection`. */
export type FileCollectionsByFileCollectionFileIdAndCollectionIdManyToManyEdge =
  {
    __typename?: 'FileCollectionsByFileCollectionFileIdAndCollectionIdManyToManyEdge'
    createdAt: Scalars['Datetime']
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>
    id: Scalars['UUID']
    /** The `Collection` at the end of the edge. */
    node?: Maybe<Collection>
    updatedAt: Scalars['Datetime']
  }

/** A connection to a list of `FileCollection` values. */
export type FileCollectionsConnection = {
  __typename?: 'FileCollectionsConnection'
  /** A list of edges which contains the `FileCollection` and cursor to aid in pagination. */
  edges: Array<FileCollectionsEdge>
  /** A list of `FileCollection` objects. */
  nodes: Array<Maybe<FileCollection>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `FileCollection` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `FileCollection` edge in the connection. */
export type FileCollectionsEdge = {
  __typename?: 'FileCollectionsEdge'
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>
  /** The `FileCollection` at the end of the edge. */
  node?: Maybe<FileCollection>
}

/** Methods to use when ordering `FileCollection`. */
export enum FileCollectionsOrderBy {
  CollectionIdAsc = 'COLLECTION_ID_ASC',
  CollectionIdDesc = 'COLLECTION_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  FileIdAsc = 'FILE_ID_ASC',
  FileIdDesc = 'FILE_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
}

/** A connection to a list of `Comment` values, with data from `Notification`. */
export type FileCommentsByNotificationFileIdAndCommentIdManyToManyConnection = {
  __typename?: 'FileCommentsByNotificationFileIdAndCommentIdManyToManyConnection'
  /** A list of edges which contains the `Comment`, info from the `Notification`, and the cursor to aid in pagination. */
  edges: Array<FileCommentsByNotificationFileIdAndCommentIdManyToManyEdge>
  /** A list of `Comment` objects. */
  nodes: Array<Maybe<Comment>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `Comment` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `Comment` edge in the connection, with data from `Notification`. */
export type FileCommentsByNotificationFileIdAndCommentIdManyToManyEdge = {
  __typename?: 'FileCommentsByNotificationFileIdAndCommentIdManyToManyEdge'
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>
  /** The `Comment` at the end of the edge. */
  node?: Maybe<Comment>
  /** Reads and enables pagination through a set of `Notification`. */
  notifications: NotificationsConnection
}

/** A `Comment` edge in the connection, with data from `Notification`. */
export type FileCommentsByNotificationFileIdAndCommentIdManyToManyEdgeNotificationsArgs =
  {
    after?: Maybe<Scalars['Cursor']>
    before?: Maybe<Scalars['Cursor']>
    condition?: Maybe<NotificationCondition>
    first?: Maybe<Scalars['Int']>
    last?: Maybe<Scalars['Int']>
    offset?: Maybe<Scalars['Int']>
    orderBy?: Maybe<Array<NotificationsOrderBy>>
  }

/** A connection to a list of `Comment` values, with data from `Reaction`. */
export type FileCommentsByReactionFileIdAndCommentIdManyToManyConnection = {
  __typename?: 'FileCommentsByReactionFileIdAndCommentIdManyToManyConnection'
  /** A list of edges which contains the `Comment`, info from the `Reaction`, and the cursor to aid in pagination. */
  edges: Array<FileCommentsByReactionFileIdAndCommentIdManyToManyEdge>
  /** A list of `Comment` objects. */
  nodes: Array<Maybe<Comment>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `Comment` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `Comment` edge in the connection, with data from `Reaction`. */
export type FileCommentsByReactionFileIdAndCommentIdManyToManyEdge = {
  __typename?: 'FileCommentsByReactionFileIdAndCommentIdManyToManyEdge'
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>
  /** The `Comment` at the end of the edge. */
  node?: Maybe<Comment>
  /** Reads and enables pagination through a set of `Reaction`. */
  reactions: ReactionsConnection
}

/** A `Comment` edge in the connection, with data from `Reaction`. */
export type FileCommentsByReactionFileIdAndCommentIdManyToManyEdgeReactionsArgs =
  {
    after?: Maybe<Scalars['Cursor']>
    before?: Maybe<Scalars['Cursor']>
    condition?: Maybe<ReactionCondition>
    first?: Maybe<Scalars['Int']>
    last?: Maybe<Scalars['Int']>
    offset?: Maybe<Scalars['Int']>
    orderBy?: Maybe<Array<ReactionsOrderBy>>
  }

/** A condition to be used against `File` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type FileCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>
  /** Checks for equality with the object’s `description` field. */
  description?: Maybe<Scalars['String']>
  /** Checks for equality with the object’s `dimensions` field. */
  dimensions?: Maybe<DimensionInput>
  /** Checks for equality with the object’s `duration` field. */
  duration?: Maybe<Scalars['Int']>
  /** Checks for equality with the object’s `format` field. */
  format?: Maybe<Format>
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>
  /** Checks for equality with the object’s `location` field. */
  location?: Maybe<LocationInput>
  /** Checks for equality with the object’s `medium` field. */
  medium?: Maybe<Medium>
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>
  /** Checks for equality with the object’s `originalCreationDate` field. */
  originalCreationDate?: Maybe<Scalars['Datetime']>
  /** Checks for equality with the object’s `originalFileId` field. */
  originalFileId?: Maybe<Scalars['UUID']>
  /** Checks for equality with the object’s `personId` field. */
  personId?: Maybe<Scalars['UUID']>
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: Maybe<Scalars['UUID']>
  /** Checks for equality with the object’s `size` field. */
  size?: Maybe<Scalars['Int']>
  /** Checks for equality with the object’s `thumbnails` field. */
  thumbnails?: Maybe<ThumbnailInput>
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>
  /** Checks for equality with the object’s `uploaded` field. */
  uploaded?: Maybe<Scalars['Boolean']>
  /** Checks for equality with the object’s `url` field. */
  url?: Maybe<Scalars['String']>
}

/** An input for mutations affecting `File` */
export type FileInput = {
  createdAt?: Maybe<Scalars['Datetime']>
  description?: Maybe<Scalars['String']>
  dimensions?: Maybe<DimensionInput>
  duration?: Maybe<Scalars['Int']>
  format?: Maybe<Format>
  id?: Maybe<Scalars['UUID']>
  location?: Maybe<LocationInput>
  medium?: Maybe<Medium>
  name?: Maybe<Scalars['String']>
  originalCreationDate?: Maybe<Scalars['Datetime']>
  originalFileId?: Maybe<Scalars['UUID']>
  personId?: Maybe<Scalars['UUID']>
  projectId: Scalars['UUID']
  size?: Maybe<Scalars['Int']>
  thumbnails?: Maybe<ThumbnailInput>
  updatedAt?: Maybe<Scalars['Datetime']>
  uploaded?: Maybe<Scalars['Boolean']>
  url?: Maybe<Scalars['String']>
}

/** A connection to a list of `Organisation` values, with data from `Notification`. */
export type FileOrganisationsByNotificationFileIdAndOrganisationIdManyToManyConnection =
  {
    __typename?: 'FileOrganisationsByNotificationFileIdAndOrganisationIdManyToManyConnection'
    /** A list of edges which contains the `Organisation`, info from the `Notification`, and the cursor to aid in pagination. */
    edges: Array<FileOrganisationsByNotificationFileIdAndOrganisationIdManyToManyEdge>
    /** A list of `Organisation` objects. */
    nodes: Array<Maybe<Organisation>>
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** The count of *all* `Organisation` you could get from the connection. */
    totalCount: Scalars['Int']
  }

/** A `Organisation` edge in the connection, with data from `Notification`. */
export type FileOrganisationsByNotificationFileIdAndOrganisationIdManyToManyEdge =
  {
    __typename?: 'FileOrganisationsByNotificationFileIdAndOrganisationIdManyToManyEdge'
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>
    /** The `Organisation` at the end of the edge. */
    node?: Maybe<Organisation>
    /** Reads and enables pagination through a set of `Notification`. */
    notifications: NotificationsConnection
  }

/** A `Organisation` edge in the connection, with data from `Notification`. */
export type FileOrganisationsByNotificationFileIdAndOrganisationIdManyToManyEdgeNotificationsArgs =
  {
    after?: Maybe<Scalars['Cursor']>
    before?: Maybe<Scalars['Cursor']>
    condition?: Maybe<NotificationCondition>
    first?: Maybe<Scalars['Int']>
    last?: Maybe<Scalars['Int']>
    offset?: Maybe<Scalars['Int']>
    orderBy?: Maybe<Array<NotificationsOrderBy>>
  }

/** Represents an update to a `File`. Fields that are set will be updated. */
export type FilePatch = {
  createdAt?: Maybe<Scalars['Datetime']>
  description?: Maybe<Scalars['String']>
  dimensions?: Maybe<DimensionInput>
  duration?: Maybe<Scalars['Int']>
  format?: Maybe<Format>
  id?: Maybe<Scalars['UUID']>
  location?: Maybe<LocationInput>
  medium?: Maybe<Medium>
  name?: Maybe<Scalars['String']>
  originalCreationDate?: Maybe<Scalars['Datetime']>
  originalFileId?: Maybe<Scalars['UUID']>
  personId?: Maybe<Scalars['UUID']>
  projectId?: Maybe<Scalars['UUID']>
  size?: Maybe<Scalars['Int']>
  thumbnails?: Maybe<ThumbnailInput>
  updatedAt?: Maybe<Scalars['Datetime']>
  uploaded?: Maybe<Scalars['Boolean']>
  url?: Maybe<Scalars['String']>
}

/** A connection to a list of `Person` values, with data from `Comment`. */
export type FilePeopleByCommentFileIdAndPersonIdManyToManyConnection = {
  __typename?: 'FilePeopleByCommentFileIdAndPersonIdManyToManyConnection'
  /** A list of edges which contains the `Person`, info from the `Comment`, and the cursor to aid in pagination. */
  edges: Array<FilePeopleByCommentFileIdAndPersonIdManyToManyEdge>
  /** A list of `Person` objects. */
  nodes: Array<Maybe<Person>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `Person` edge in the connection, with data from `Comment`. */
export type FilePeopleByCommentFileIdAndPersonIdManyToManyEdge = {
  __typename?: 'FilePeopleByCommentFileIdAndPersonIdManyToManyEdge'
  /** Reads and enables pagination through a set of `Comment`. */
  comments: CommentsConnection
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>
  /** The `Person` at the end of the edge. */
  node?: Maybe<Person>
}

/** A `Person` edge in the connection, with data from `Comment`. */
export type FilePeopleByCommentFileIdAndPersonIdManyToManyEdgeCommentsArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<CommentCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<CommentsOrderBy>>
}

/** A connection to a list of `Person` values, with data from `File`. */
export type FilePeopleByFileOriginalFileIdAndPersonIdManyToManyConnection = {
  __typename?: 'FilePeopleByFileOriginalFileIdAndPersonIdManyToManyConnection'
  /** A list of edges which contains the `Person`, info from the `File`, and the cursor to aid in pagination. */
  edges: Array<FilePeopleByFileOriginalFileIdAndPersonIdManyToManyEdge>
  /** A list of `Person` objects. */
  nodes: Array<Maybe<Person>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `Person` edge in the connection, with data from `File`. */
export type FilePeopleByFileOriginalFileIdAndPersonIdManyToManyEdge = {
  __typename?: 'FilePeopleByFileOriginalFileIdAndPersonIdManyToManyEdge'
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>
  /** Reads and enables pagination through a set of `File`. */
  files: FilesConnection
  /** The `Person` at the end of the edge. */
  node?: Maybe<Person>
}

/** A `Person` edge in the connection, with data from `File`. */
export type FilePeopleByFileOriginalFileIdAndPersonIdManyToManyEdgeFilesArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<FileCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<FilesOrderBy>>
}

/** A connection to a list of `Person` values, with data from `Notification`. */
export type FilePeopleByNotificationFileIdAndPersonIdManyToManyConnection = {
  __typename?: 'FilePeopleByNotificationFileIdAndPersonIdManyToManyConnection'
  /** A list of edges which contains the `Person`, info from the `Notification`, and the cursor to aid in pagination. */
  edges: Array<FilePeopleByNotificationFileIdAndPersonIdManyToManyEdge>
  /** A list of `Person` objects. */
  nodes: Array<Maybe<Person>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `Person` edge in the connection, with data from `Notification`. */
export type FilePeopleByNotificationFileIdAndPersonIdManyToManyEdge = {
  __typename?: 'FilePeopleByNotificationFileIdAndPersonIdManyToManyEdge'
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>
  /** The `Person` at the end of the edge. */
  node?: Maybe<Person>
  /** Reads and enables pagination through a set of `Notification`. */
  notifications: NotificationsConnection
}

/** A `Person` edge in the connection, with data from `Notification`. */
export type FilePeopleByNotificationFileIdAndPersonIdManyToManyEdgeNotificationsArgs =
  {
    after?: Maybe<Scalars['Cursor']>
    before?: Maybe<Scalars['Cursor']>
    condition?: Maybe<NotificationCondition>
    first?: Maybe<Scalars['Int']>
    last?: Maybe<Scalars['Int']>
    offset?: Maybe<Scalars['Int']>
    orderBy?: Maybe<Array<NotificationsOrderBy>>
  }

/** A connection to a list of `Person` values, with data from `Notification`. */
export type FilePeopleByNotificationFileIdAndReceiverIdManyToManyConnection = {
  __typename?: 'FilePeopleByNotificationFileIdAndReceiverIdManyToManyConnection'
  /** A list of edges which contains the `Person`, info from the `Notification`, and the cursor to aid in pagination. */
  edges: Array<FilePeopleByNotificationFileIdAndReceiverIdManyToManyEdge>
  /** A list of `Person` objects. */
  nodes: Array<Maybe<Person>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `Person` edge in the connection, with data from `Notification`. */
export type FilePeopleByNotificationFileIdAndReceiverIdManyToManyEdge = {
  __typename?: 'FilePeopleByNotificationFileIdAndReceiverIdManyToManyEdge'
  commentId?: Maybe<Scalars['UUID']>
  createdAt: Scalars['Datetime']
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>
  id: Scalars['UUID']
  /** The `Person` at the end of the edge. */
  node?: Maybe<Person>
  organisationId: Scalars['UUID']
  personId?: Maybe<Scalars['UUID']>
  seen?: Maybe<Scalars['Boolean']>
  updatedAt: Scalars['Datetime']
}

/** A connection to a list of `Person` values, with data from `Reaction`. */
export type FilePeopleByReactionFileIdAndPersonIdManyToManyConnection = {
  __typename?: 'FilePeopleByReactionFileIdAndPersonIdManyToManyConnection'
  /** A list of edges which contains the `Person`, info from the `Reaction`, and the cursor to aid in pagination. */
  edges: Array<FilePeopleByReactionFileIdAndPersonIdManyToManyEdge>
  /** A list of `Person` objects. */
  nodes: Array<Maybe<Person>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `Person` edge in the connection, with data from `Reaction`. */
export type FilePeopleByReactionFileIdAndPersonIdManyToManyEdge = {
  __typename?: 'FilePeopleByReactionFileIdAndPersonIdManyToManyEdge'
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>
  /** The `Person` at the end of the edge. */
  node?: Maybe<Person>
  /** Reads and enables pagination through a set of `Reaction`. */
  reactions: ReactionsConnection
}

/** A `Person` edge in the connection, with data from `Reaction`. */
export type FilePeopleByReactionFileIdAndPersonIdManyToManyEdgeReactionsArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<ReactionCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<ReactionsOrderBy>>
}

/** A connection to a list of `Project` values, with data from `File`. */
export type FileProjectsByFileOriginalFileIdAndProjectIdManyToManyConnection = {
  __typename?: 'FileProjectsByFileOriginalFileIdAndProjectIdManyToManyConnection'
  /** A list of edges which contains the `Project`, info from the `File`, and the cursor to aid in pagination. */
  edges: Array<FileProjectsByFileOriginalFileIdAndProjectIdManyToManyEdge>
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `Project` edge in the connection, with data from `File`. */
export type FileProjectsByFileOriginalFileIdAndProjectIdManyToManyEdge = {
  __typename?: 'FileProjectsByFileOriginalFileIdAndProjectIdManyToManyEdge'
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>
  /** Reads and enables pagination through a set of `File`. */
  files: FilesConnection
  /** The `Project` at the end of the edge. */
  node?: Maybe<Project>
}

/** A `Project` edge in the connection, with data from `File`. */
export type FileProjectsByFileOriginalFileIdAndProjectIdManyToManyEdgeFilesArgs =
  {
    after?: Maybe<Scalars['Cursor']>
    before?: Maybe<Scalars['Cursor']>
    condition?: Maybe<FileCondition>
    first?: Maybe<Scalars['Int']>
    last?: Maybe<Scalars['Int']>
    offset?: Maybe<Scalars['Int']>
    orderBy?: Maybe<Array<FilesOrderBy>>
  }

export type FileSubscriptionInput = {
  id: Scalars['UUID']
}

export type FileSubscriptionPayload = {
  __typename?: 'FileSubscriptionPayload'
  event?: Maybe<Scalars['String']>
  file?: Maybe<File>
}

/** A connection to a list of `File` values. */
export type FilesConnection = {
  __typename?: 'FilesConnection'
  /** A list of edges which contains the `File` and cursor to aid in pagination. */
  edges: Array<FilesEdge>
  /** A list of `File` objects. */
  nodes: Array<Maybe<File>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `File` edge in the connection. */
export type FilesEdge = {
  __typename?: 'FilesEdge'
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>
  /** The `File` at the end of the edge. */
  node?: Maybe<File>
}

/** Methods to use when ordering `File`. */
export enum FilesOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  DimensionsAsc = 'DIMENSIONS_ASC',
  DimensionsDesc = 'DIMENSIONS_DESC',
  DurationAsc = 'DURATION_ASC',
  DurationDesc = 'DURATION_DESC',
  FormatAsc = 'FORMAT_ASC',
  FormatDesc = 'FORMAT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LocationAsc = 'LOCATION_ASC',
  LocationDesc = 'LOCATION_DESC',
  MediumAsc = 'MEDIUM_ASC',
  MediumDesc = 'MEDIUM_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  OriginalCreationDateAsc = 'ORIGINAL_CREATION_DATE_ASC',
  OriginalCreationDateDesc = 'ORIGINAL_CREATION_DATE_DESC',
  OriginalFileIdAsc = 'ORIGINAL_FILE_ID_ASC',
  OriginalFileIdDesc = 'ORIGINAL_FILE_ID_DESC',
  PersonIdAsc = 'PERSON_ID_ASC',
  PersonIdDesc = 'PERSON_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC',
  SizeAsc = 'SIZE_ASC',
  SizeDesc = 'SIZE_DESC',
  ThumbnailsAsc = 'THUMBNAILS_ASC',
  ThumbnailsDesc = 'THUMBNAILS_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UploadedAsc = 'UPLOADED_ASC',
  UploadedDesc = 'UPLOADED_DESC',
  UrlAsc = 'URL_ASC',
  UrlDesc = 'URL_DESC',
}

export enum Format {
  Jpeg = 'JPEG',
  Jpg = 'JPG',
  Mov = 'MOV',
  Mp3 = 'MP3',
  Mp4 = 'MP4',
  Png = 'PNG',
  Wav = 'WAV',
}

/** All geometry XY types implement this interface */
export type GeometryGeometry = {
  /** Converts the object to GeoJSON */
  geojson?: Maybe<Scalars['GeoJSON']>
  /** Spatial reference identifier (SRID) */
  srid: Scalars['Int']
}

/** All geometry types implement this interface */
export type GeometryInterface = {
  /** Converts the object to GeoJSON */
  geojson?: Maybe<Scalars['GeoJSON']>
  /** Spatial reference identifier (SRID) */
  srid: Scalars['Int']
}

export type GeometryPoint = GeometryGeometry &
  GeometryInterface & {
    __typename?: 'GeometryPoint'
    geojson?: Maybe<Scalars['GeoJSON']>
    srid: Scalars['Int']
    x: Scalars['Float']
    y: Scalars['Float']
  }

/** A connection to a list of `GetCurrentPersonRecord` values. */
export type GetCurrentPersonConnection = {
  __typename?: 'GetCurrentPersonConnection'
  /** A list of edges which contains the `GetCurrentPersonRecord` and cursor to aid in pagination. */
  edges: Array<GetCurrentPersonEdge>
  /** A list of `GetCurrentPersonRecord` objects. */
  nodes: Array<Maybe<GetCurrentPersonRecord>>
  /** The count of *all* `GetCurrentPersonRecord` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `GetCurrentPersonRecord` edge in the connection. */
export type GetCurrentPersonEdge = {
  __typename?: 'GetCurrentPersonEdge'
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>
  /** The `GetCurrentPersonRecord` at the end of the edge. */
  node?: Maybe<GetCurrentPersonRecord>
}

/** The return type of our `getCurrentPerson` query. */
export type GetCurrentPersonRecord = {
  __typename?: 'GetCurrentPersonRecord'
  deviceIds?: Maybe<Array<Maybe<Scalars['String']>>>
  id?: Maybe<Scalars['UUID']>
  name?: Maybe<Scalars['String']>
  photoUrl?: Maybe<Scalars['String']>
}

/** All input for the `getPersonId` mutation. */
export type GetPersonIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
}

/** The output of our `getPersonId` mutation. */
export type GetPersonIdPayload = {
  __typename?: 'GetPersonIdPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>
  uuid?: Maybe<Scalars['UUID']>
}

export type Location = {
  __typename?: 'Location'
  address?: Maybe<Scalars['String']>
  country?: Maybe<Scalars['String']>
  district?: Maybe<Scalars['String']>
  geo?: Maybe<GeometryPoint>
  locality?: Maybe<Scalars['String']>
  neighborhood?: Maybe<Scalars['String']>
  place?: Maybe<Scalars['String']>
  pois?: Maybe<Array<Maybe<Scalars['String']>>>
  postcode?: Maybe<Scalars['String']>
  region?: Maybe<Scalars['String']>
}

/** An input for mutations affecting `Location` */
export type LocationInput = {
  address?: Maybe<Scalars['String']>
  country?: Maybe<Scalars['String']>
  district?: Maybe<Scalars['String']>
  geo?: Maybe<Scalars['GeoJSON']>
  locality?: Maybe<Scalars['String']>
  neighborhood?: Maybe<Scalars['String']>
  place?: Maybe<Scalars['String']>
  pois?: Maybe<Array<Maybe<Scalars['String']>>>
  postcode?: Maybe<Scalars['String']>
  region?: Maybe<Scalars['String']>
}

export enum Medium {
  Audio = 'AUDIO',
  Image = 'IMAGE',
  Video = 'VIDEO',
}

export type Mention = Node & {
  __typename?: 'Mention'
  /** Reads a single `Comment` that is related to this `Mention`. */
  comment?: Maybe<Comment>
  commentId?: Maybe<Scalars['UUID']>
  createdAt: Scalars['Datetime']
  id: Scalars['UUID']
  /** Reads a single `Person` that is related to this `Mention`. */
  mentioned?: Maybe<Person>
  mentionedId?: Maybe<Scalars['UUID']>
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']
  /** Reads a single `Organisation` that is related to this `Mention`. */
  organisation?: Maybe<Organisation>
  organisationId: Scalars['UUID']
  /** Reads a single `Person` that is related to this `Mention`. */
  person?: Maybe<Person>
  personId?: Maybe<Scalars['UUID']>
  updatedAt: Scalars['Datetime']
}

/** A condition to be used against `Mention` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type MentionCondition = {
  /** Checks for equality with the object’s `commentId` field. */
  commentId?: Maybe<Scalars['UUID']>
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>
  /** Checks for equality with the object’s `mentionedId` field. */
  mentionedId?: Maybe<Scalars['UUID']>
  /** Checks for equality with the object’s `organisationId` field. */
  organisationId?: Maybe<Scalars['UUID']>
  /** Checks for equality with the object’s `personId` field. */
  personId?: Maybe<Scalars['UUID']>
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>
}

/** An input for mutations affecting `Mention` */
export type MentionInput = {
  commentId?: Maybe<Scalars['UUID']>
  createdAt?: Maybe<Scalars['Datetime']>
  id?: Maybe<Scalars['UUID']>
  mentionedId?: Maybe<Scalars['UUID']>
  organisationId: Scalars['UUID']
  personId?: Maybe<Scalars['UUID']>
  updatedAt?: Maybe<Scalars['Datetime']>
}

/** Represents an update to a `Mention`. Fields that are set will be updated. */
export type MentionPatch = {
  commentId?: Maybe<Scalars['UUID']>
  createdAt?: Maybe<Scalars['Datetime']>
  id?: Maybe<Scalars['UUID']>
  mentionedId?: Maybe<Scalars['UUID']>
  organisationId?: Maybe<Scalars['UUID']>
  personId?: Maybe<Scalars['UUID']>
  updatedAt?: Maybe<Scalars['Datetime']>
}

/** A connection to a list of `Mention` values. */
export type MentionsConnection = {
  __typename?: 'MentionsConnection'
  /** A list of edges which contains the `Mention` and cursor to aid in pagination. */
  edges: Array<MentionsEdge>
  /** A list of `Mention` objects. */
  nodes: Array<Maybe<Mention>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `Mention` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `Mention` edge in the connection. */
export type MentionsEdge = {
  __typename?: 'MentionsEdge'
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>
  /** The `Mention` at the end of the edge. */
  node?: Maybe<Mention>
}

/** Methods to use when ordering `Mention`. */
export enum MentionsOrderBy {
  CommentIdAsc = 'COMMENT_ID_ASC',
  CommentIdDesc = 'COMMENT_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  MentionedIdAsc = 'MENTIONED_ID_ASC',
  MentionedIdDesc = 'MENTIONED_ID_DESC',
  Natural = 'NATURAL',
  OrganisationIdAsc = 'ORGANISATION_ID_ASC',
  OrganisationIdDesc = 'ORGANISATION_ID_DESC',
  PersonIdAsc = 'PERSON_ID_ASC',
  PersonIdDesc = 'PERSON_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
}

/** The root mutation type which contains root level fields which mutate data. */
export type Mutation = {
  __typename?: 'Mutation'
  acceptOrganisationInvitation?: Maybe<AcceptOrganisationInvitationPayload>
  addFirebaseAccount?: Maybe<AddFirebaseAccountPayload>
  addOrganisation?: Maybe<AddOrganisationPayload>
  /** Creates a single `Collection`. */
  createCollection?: Maybe<CreateCollectionPayload>
  /** Creates a single `Comment`. */
  createComment?: Maybe<CreateCommentPayload>
  /** Creates a single `File`. */
  createFile?: Maybe<CreateFilePayload>
  /** Creates a single `FileCollection`. */
  createFileCollection?: Maybe<CreateFileCollectionPayload>
  /** Creates a single `Mention`. */
  createMention?: Maybe<CreateMentionPayload>
  /** Creates a single `Notification`. */
  createNotification?: Maybe<CreateNotificationPayload>
  /** Creates a single `Organisation`. */
  createOrganisation?: Maybe<CreateOrganisationPayload>
  createOrganisationInvitation?: Maybe<CreateOrganisationInvitationPayload>
  /** Creates a single `Person`. */
  createPerson?: Maybe<CreatePersonPayload>
  /** Creates a single `PersonOrganisation`. */
  createPersonOrganisation?: Maybe<CreatePersonOrganisationPayload>
  /** Creates a single `PersonProject`. */
  createPersonProject?: Maybe<CreatePersonProjectPayload>
  createProject?: Maybe<CreateProjectPayload>
  /** Creates a single `Reaction`. */
  createReaction?: Maybe<CreateReactionPayload>
  /** Deletes a single `Collection` using a unique key. */
  deleteCollection?: Maybe<DeleteCollectionPayload>
  /** Deletes a single `Collection` using its globally unique id. */
  deleteCollectionByNodeId?: Maybe<DeleteCollectionPayload>
  /** Deletes a single `Comment` using a unique key. */
  deleteComment?: Maybe<DeleteCommentPayload>
  /** Deletes a single `Comment` using its globally unique id. */
  deleteCommentByNodeId?: Maybe<DeleteCommentPayload>
  /** Deletes a single `File` using a unique key. */
  deleteFile?: Maybe<DeleteFilePayload>
  /** Deletes a single `File` using its globally unique id. */
  deleteFileByNodeId?: Maybe<DeleteFilePayload>
  /** Deletes a single `FileCollection` using a unique key. */
  deleteFileCollection?: Maybe<DeleteFileCollectionPayload>
  /** Deletes a single `FileCollection` using a unique key. */
  deleteFileCollectionByCollectionIdAndFileId?: Maybe<DeleteFileCollectionPayload>
  /** Deletes a single `FileCollection` using its globally unique id. */
  deleteFileCollectionByNodeId?: Maybe<DeleteFileCollectionPayload>
  /** Deletes a single `Mention` using a unique key. */
  deleteMention?: Maybe<DeleteMentionPayload>
  /** Deletes a single `Mention` using a unique key. */
  deleteMentionByMentionedIdAndCommentId?: Maybe<DeleteMentionPayload>
  /** Deletes a single `Mention` using its globally unique id. */
  deleteMentionByNodeId?: Maybe<DeleteMentionPayload>
  /** Deletes a single `Notification` using a unique key. */
  deleteNotification?: Maybe<DeleteNotificationPayload>
  /** Deletes a single `Notification` using its globally unique id. */
  deleteNotificationByNodeId?: Maybe<DeleteNotificationPayload>
  /** Deletes a single `Notification` using a unique key. */
  deleteNotificationByReceiverIdAndCommentId?: Maybe<DeleteNotificationPayload>
  /** Deletes a single `Notification` using a unique key. */
  deleteNotificationByReceiverIdAndFileId?: Maybe<DeleteNotificationPayload>
  /** Deletes a single `Organisation` using a unique key. */
  deleteOrganisation?: Maybe<DeleteOrganisationPayload>
  /** Deletes a single `Organisation` using its globally unique id. */
  deleteOrganisationByNodeId?: Maybe<DeleteOrganisationPayload>
  /** Deletes a single `OrganisationInvitation` using a unique key. */
  deleteOrganisationInvitation?: Maybe<DeleteOrganisationInvitationPayload>
  /** Deletes a single `OrganisationInvitation` using its globally unique id. */
  deleteOrganisationInvitationByNodeId?: Maybe<DeleteOrganisationInvitationPayload>
  /** Deletes a single `Person` using a unique key. */
  deletePerson?: Maybe<DeletePersonPayload>
  /** Deletes a single `Person` using a unique key. */
  deletePersonByFirebaseId?: Maybe<DeletePersonPayload>
  /** Deletes a single `Person` using its globally unique id. */
  deletePersonByNodeId?: Maybe<DeletePersonPayload>
  /** Deletes a single `PersonOrganisation` using a unique key. */
  deletePersonOrganisation?: Maybe<DeletePersonOrganisationPayload>
  /** Deletes a single `PersonOrganisation` using its globally unique id. */
  deletePersonOrganisationByNodeId?: Maybe<DeletePersonOrganisationPayload>
  /** Deletes a single `PersonOrganisation` using a unique key. */
  deletePersonOrganisationByOrganisationIdAndPersonId?: Maybe<DeletePersonOrganisationPayload>
  /** Deletes a single `PersonProject` using a unique key. */
  deletePersonProject?: Maybe<DeletePersonProjectPayload>
  /** Deletes a single `PersonProject` using its globally unique id. */
  deletePersonProjectByNodeId?: Maybe<DeletePersonProjectPayload>
  /** Deletes a single `PersonProject` using a unique key. */
  deletePersonProjectByPersonIdAndProjectId?: Maybe<DeletePersonProjectPayload>
  /** Deletes a single `Project` using a unique key. */
  deleteProject?: Maybe<DeleteProjectPayload>
  /** Deletes a single `Project` using its globally unique id. */
  deleteProjectByNodeId?: Maybe<DeleteProjectPayload>
  /** Deletes a single `Reaction` using a unique key. */
  deleteReaction?: Maybe<DeleteReactionPayload>
  /** Deletes a single `Reaction` using its globally unique id. */
  deleteReactionByNodeId?: Maybe<DeleteReactionPayload>
  getPersonId?: Maybe<GetPersonIdPayload>
  /** Updates a single `Collection` using a unique key and a patch. */
  updateCollection?: Maybe<UpdateCollectionPayload>
  /** Updates a single `Collection` using its globally unique id and a patch. */
  updateCollectionByNodeId?: Maybe<UpdateCollectionPayload>
  /** Updates a single `Comment` using a unique key and a patch. */
  updateComment?: Maybe<UpdateCommentPayload>
  /** Updates a single `Comment` using its globally unique id and a patch. */
  updateCommentByNodeId?: Maybe<UpdateCommentPayload>
  /** Updates a single `File` using a unique key and a patch. */
  updateFile?: Maybe<UpdateFilePayload>
  /** Updates a single `File` using its globally unique id and a patch. */
  updateFileByNodeId?: Maybe<UpdateFilePayload>
  /** Updates a single `FileCollection` using a unique key and a patch. */
  updateFileCollection?: Maybe<UpdateFileCollectionPayload>
  /** Updates a single `FileCollection` using a unique key and a patch. */
  updateFileCollectionByCollectionIdAndFileId?: Maybe<UpdateFileCollectionPayload>
  /** Updates a single `FileCollection` using its globally unique id and a patch. */
  updateFileCollectionByNodeId?: Maybe<UpdateFileCollectionPayload>
  /** Updates a single `Mention` using a unique key and a patch. */
  updateMention?: Maybe<UpdateMentionPayload>
  /** Updates a single `Mention` using a unique key and a patch. */
  updateMentionByMentionedIdAndCommentId?: Maybe<UpdateMentionPayload>
  /** Updates a single `Mention` using its globally unique id and a patch. */
  updateMentionByNodeId?: Maybe<UpdateMentionPayload>
  /** Updates a single `Notification` using a unique key and a patch. */
  updateNotification?: Maybe<UpdateNotificationPayload>
  /** Updates a single `Notification` using its globally unique id and a patch. */
  updateNotificationByNodeId?: Maybe<UpdateNotificationPayload>
  /** Updates a single `Notification` using a unique key and a patch. */
  updateNotificationByReceiverIdAndCommentId?: Maybe<UpdateNotificationPayload>
  /** Updates a single `Notification` using a unique key and a patch. */
  updateNotificationByReceiverIdAndFileId?: Maybe<UpdateNotificationPayload>
  /** Updates a single `Organisation` using a unique key and a patch. */
  updateOrganisation?: Maybe<UpdateOrganisationPayload>
  /** Updates a single `Organisation` using its globally unique id and a patch. */
  updateOrganisationByNodeId?: Maybe<UpdateOrganisationPayload>
  /** Updates a single `OrganisationInvitation` using a unique key and a patch. */
  updateOrganisationInvitation?: Maybe<UpdateOrganisationInvitationPayload>
  /** Updates a single `OrganisationInvitation` using its globally unique id and a patch. */
  updateOrganisationInvitationByNodeId?: Maybe<UpdateOrganisationInvitationPayload>
  /** Updates a single `Person` using a unique key and a patch. */
  updatePerson?: Maybe<UpdatePersonPayload>
  /** Updates a single `Person` using a unique key and a patch. */
  updatePersonByFirebaseId?: Maybe<UpdatePersonPayload>
  /** Updates a single `Person` using its globally unique id and a patch. */
  updatePersonByNodeId?: Maybe<UpdatePersonPayload>
  /** Updates a single `PersonOrganisation` using a unique key and a patch. */
  updatePersonOrganisation?: Maybe<UpdatePersonOrganisationPayload>
  /** Updates a single `PersonOrganisation` using its globally unique id and a patch. */
  updatePersonOrganisationByNodeId?: Maybe<UpdatePersonOrganisationPayload>
  /** Updates a single `PersonOrganisation` using a unique key and a patch. */
  updatePersonOrganisationByOrganisationIdAndPersonId?: Maybe<UpdatePersonOrganisationPayload>
  /** Updates a single `PersonProject` using a unique key and a patch. */
  updatePersonProject?: Maybe<UpdatePersonProjectPayload>
  /** Updates a single `PersonProject` using its globally unique id and a patch. */
  updatePersonProjectByNodeId?: Maybe<UpdatePersonProjectPayload>
  /** Updates a single `PersonProject` using a unique key and a patch. */
  updatePersonProjectByPersonIdAndProjectId?: Maybe<UpdatePersonProjectPayload>
  /** Updates a single `Project` using a unique key and a patch. */
  updateProject?: Maybe<UpdateProjectPayload>
  /** Updates a single `Project` using its globally unique id and a patch. */
  updateProjectByNodeId?: Maybe<UpdateProjectPayload>
  /** Updates a single `Reaction` using a unique key and a patch. */
  updateReaction?: Maybe<UpdateReactionPayload>
  /** Updates a single `Reaction` using its globally unique id and a patch. */
  updateReactionByNodeId?: Maybe<UpdateReactionPayload>
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAcceptOrganisationInvitationArgs = {
  input: AcceptOrganisationInvitationInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAddFirebaseAccountArgs = {
  input: AddFirebaseAccountInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAddOrganisationArgs = {
  input: AddOrganisationInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCollectionArgs = {
  input: CreateCollectionInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCommentArgs = {
  input: CreateCommentInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateFileArgs = {
  input: CreateFileInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateFileCollectionArgs = {
  input: CreateFileCollectionInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateMentionArgs = {
  input: CreateMentionInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateNotificationArgs = {
  input: CreateNotificationInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateOrganisationArgs = {
  input: CreateOrganisationInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateOrganisationInvitationArgs = {
  input: CreateOrganisationInvitationInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreatePersonArgs = {
  input: CreatePersonInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreatePersonOrganisationArgs = {
  input: CreatePersonOrganisationInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreatePersonProjectArgs = {
  input: CreatePersonProjectInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateProjectArgs = {
  input: CreateProjectInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateReactionArgs = {
  input: CreateReactionInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCollectionArgs = {
  input: DeleteCollectionInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCollectionByNodeIdArgs = {
  input: DeleteCollectionByNodeIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCommentArgs = {
  input: DeleteCommentInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCommentByNodeIdArgs = {
  input: DeleteCommentByNodeIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFileArgs = {
  input: DeleteFileInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFileByNodeIdArgs = {
  input: DeleteFileByNodeIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFileCollectionArgs = {
  input: DeleteFileCollectionInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFileCollectionByCollectionIdAndFileIdArgs = {
  input: DeleteFileCollectionByCollectionIdAndFileIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFileCollectionByNodeIdArgs = {
  input: DeleteFileCollectionByNodeIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteMentionArgs = {
  input: DeleteMentionInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteMentionByMentionedIdAndCommentIdArgs = {
  input: DeleteMentionByMentionedIdAndCommentIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteMentionByNodeIdArgs = {
  input: DeleteMentionByNodeIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteNotificationArgs = {
  input: DeleteNotificationInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteNotificationByNodeIdArgs = {
  input: DeleteNotificationByNodeIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteNotificationByReceiverIdAndCommentIdArgs = {
  input: DeleteNotificationByReceiverIdAndCommentIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteNotificationByReceiverIdAndFileIdArgs = {
  input: DeleteNotificationByReceiverIdAndFileIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteOrganisationArgs = {
  input: DeleteOrganisationInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteOrganisationByNodeIdArgs = {
  input: DeleteOrganisationByNodeIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteOrganisationInvitationArgs = {
  input: DeleteOrganisationInvitationInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteOrganisationInvitationByNodeIdArgs = {
  input: DeleteOrganisationInvitationByNodeIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePersonArgs = {
  input: DeletePersonInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePersonByFirebaseIdArgs = {
  input: DeletePersonByFirebaseIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePersonByNodeIdArgs = {
  input: DeletePersonByNodeIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePersonOrganisationArgs = {
  input: DeletePersonOrganisationInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePersonOrganisationByNodeIdArgs = {
  input: DeletePersonOrganisationByNodeIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePersonOrganisationByOrganisationIdAndPersonIdArgs = {
  input: DeletePersonOrganisationByOrganisationIdAndPersonIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePersonProjectArgs = {
  input: DeletePersonProjectInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePersonProjectByNodeIdArgs = {
  input: DeletePersonProjectByNodeIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePersonProjectByPersonIdAndProjectIdArgs = {
  input: DeletePersonProjectByPersonIdAndProjectIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteProjectArgs = {
  input: DeleteProjectInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteProjectByNodeIdArgs = {
  input: DeleteProjectByNodeIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteReactionArgs = {
  input: DeleteReactionInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteReactionByNodeIdArgs = {
  input: DeleteReactionByNodeIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationGetPersonIdArgs = {
  input: GetPersonIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCollectionArgs = {
  input: UpdateCollectionInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCollectionByNodeIdArgs = {
  input: UpdateCollectionByNodeIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCommentArgs = {
  input: UpdateCommentInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCommentByNodeIdArgs = {
  input: UpdateCommentByNodeIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFileArgs = {
  input: UpdateFileInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFileByNodeIdArgs = {
  input: UpdateFileByNodeIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFileCollectionArgs = {
  input: UpdateFileCollectionInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFileCollectionByCollectionIdAndFileIdArgs = {
  input: UpdateFileCollectionByCollectionIdAndFileIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFileCollectionByNodeIdArgs = {
  input: UpdateFileCollectionByNodeIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMentionArgs = {
  input: UpdateMentionInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMentionByMentionedIdAndCommentIdArgs = {
  input: UpdateMentionByMentionedIdAndCommentIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMentionByNodeIdArgs = {
  input: UpdateMentionByNodeIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateNotificationArgs = {
  input: UpdateNotificationInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateNotificationByNodeIdArgs = {
  input: UpdateNotificationByNodeIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateNotificationByReceiverIdAndCommentIdArgs = {
  input: UpdateNotificationByReceiverIdAndCommentIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateNotificationByReceiverIdAndFileIdArgs = {
  input: UpdateNotificationByReceiverIdAndFileIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateOrganisationArgs = {
  input: UpdateOrganisationInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateOrganisationByNodeIdArgs = {
  input: UpdateOrganisationByNodeIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateOrganisationInvitationArgs = {
  input: UpdateOrganisationInvitationInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateOrganisationInvitationByNodeIdArgs = {
  input: UpdateOrganisationInvitationByNodeIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePersonArgs = {
  input: UpdatePersonInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePersonByFirebaseIdArgs = {
  input: UpdatePersonByFirebaseIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePersonByNodeIdArgs = {
  input: UpdatePersonByNodeIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePersonOrganisationArgs = {
  input: UpdatePersonOrganisationInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePersonOrganisationByNodeIdArgs = {
  input: UpdatePersonOrganisationByNodeIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePersonOrganisationByOrganisationIdAndPersonIdArgs = {
  input: UpdatePersonOrganisationByOrganisationIdAndPersonIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePersonProjectArgs = {
  input: UpdatePersonProjectInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePersonProjectByNodeIdArgs = {
  input: UpdatePersonProjectByNodeIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePersonProjectByPersonIdAndProjectIdArgs = {
  input: UpdatePersonProjectByPersonIdAndProjectIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProjectArgs = {
  input: UpdateProjectInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProjectByNodeIdArgs = {
  input: UpdateProjectByNodeIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateReactionArgs = {
  input: UpdateReactionInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateReactionByNodeIdArgs = {
  input: UpdateReactionByNodeIdInput
}

/** An object with a globally unique `ID`. */
export type Node = {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']
}

export type Notification = Node & {
  __typename?: 'Notification'
  /** Reads a single `Comment` that is related to this `Notification`. */
  comment?: Maybe<Comment>
  commentId?: Maybe<Scalars['UUID']>
  createdAt: Scalars['Datetime']
  /** Reads a single `File` that is related to this `Notification`. */
  file?: Maybe<File>
  fileId?: Maybe<Scalars['UUID']>
  id: Scalars['UUID']
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']
  /** Reads a single `Organisation` that is related to this `Notification`. */
  organisation?: Maybe<Organisation>
  organisationId: Scalars['UUID']
  /** Reads a single `Person` that is related to this `Notification`. */
  person?: Maybe<Person>
  personId?: Maybe<Scalars['UUID']>
  /** Reads a single `Person` that is related to this `Notification`. */
  receiver?: Maybe<Person>
  receiverId?: Maybe<Scalars['UUID']>
  seen?: Maybe<Scalars['Boolean']>
  updatedAt: Scalars['Datetime']
}

/**
 * A condition to be used against `Notification` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type NotificationCondition = {
  /** Checks for equality with the object’s `commentId` field. */
  commentId?: Maybe<Scalars['UUID']>
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>
  /** Checks for equality with the object’s `fileId` field. */
  fileId?: Maybe<Scalars['UUID']>
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>
  /** Checks for equality with the object’s `organisationId` field. */
  organisationId?: Maybe<Scalars['UUID']>
  /** Checks for equality with the object’s `personId` field. */
  personId?: Maybe<Scalars['UUID']>
  /** Checks for equality with the object’s `receiverId` field. */
  receiverId?: Maybe<Scalars['UUID']>
  /** Checks for equality with the object’s `seen` field. */
  seen?: Maybe<Scalars['Boolean']>
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>
}

/** An input for mutations affecting `Notification` */
export type NotificationInput = {
  commentId?: Maybe<Scalars['UUID']>
  createdAt?: Maybe<Scalars['Datetime']>
  fileId?: Maybe<Scalars['UUID']>
  id?: Maybe<Scalars['UUID']>
  organisationId: Scalars['UUID']
  personId?: Maybe<Scalars['UUID']>
  receiverId?: Maybe<Scalars['UUID']>
  seen?: Maybe<Scalars['Boolean']>
  updatedAt?: Maybe<Scalars['Datetime']>
}

/** Represents an update to a `Notification`. Fields that are set will be updated. */
export type NotificationPatch = {
  commentId?: Maybe<Scalars['UUID']>
  createdAt?: Maybe<Scalars['Datetime']>
  fileId?: Maybe<Scalars['UUID']>
  id?: Maybe<Scalars['UUID']>
  organisationId?: Maybe<Scalars['UUID']>
  personId?: Maybe<Scalars['UUID']>
  receiverId?: Maybe<Scalars['UUID']>
  seen?: Maybe<Scalars['Boolean']>
  updatedAt?: Maybe<Scalars['Datetime']>
}

export type NotificationSubscriptionInput = {
  receiverId: Scalars['UUID']
}

export type NotificationSubscriptionPayload = {
  __typename?: 'NotificationSubscriptionPayload'
  event?: Maybe<Scalars['String']>
  notification?: Maybe<Notification>
}

/** A connection to a list of `Notification` values. */
export type NotificationsConnection = {
  __typename?: 'NotificationsConnection'
  /** A list of edges which contains the `Notification` and cursor to aid in pagination. */
  edges: Array<NotificationsEdge>
  /** A list of `Notification` objects. */
  nodes: Array<Maybe<Notification>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `Notification` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `Notification` edge in the connection. */
export type NotificationsEdge = {
  __typename?: 'NotificationsEdge'
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>
  /** The `Notification` at the end of the edge. */
  node?: Maybe<Notification>
}

/** Methods to use when ordering `Notification`. */
export enum NotificationsOrderBy {
  CommentIdAsc = 'COMMENT_ID_ASC',
  CommentIdDesc = 'COMMENT_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  FileIdAsc = 'FILE_ID_ASC',
  FileIdDesc = 'FILE_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  OrganisationIdAsc = 'ORGANISATION_ID_ASC',
  OrganisationIdDesc = 'ORGANISATION_ID_DESC',
  PersonIdAsc = 'PERSON_ID_ASC',
  PersonIdDesc = 'PERSON_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ReceiverIdAsc = 'RECEIVER_ID_ASC',
  ReceiverIdDesc = 'RECEIVER_ID_DESC',
  SeenAsc = 'SEEN_ASC',
  SeenDesc = 'SEEN_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
}

/** A Loupe Organisation is the main organisational unit for collaboration */
export type Organisation = Node & {
  __typename?: 'Organisation'
  /** Reads and enables pagination through a set of `Collection`. */
  collections: CollectionsConnection
  /** Reads and enables pagination through a set of `Comment`. */
  commentsByMentionOrganisationIdAndCommentId: OrganisationCommentsByMentionOrganisationIdAndCommentIdManyToManyConnection
  /** Reads and enables pagination through a set of `Comment`. */
  commentsByNotificationOrganisationIdAndCommentId: OrganisationCommentsByNotificationOrganisationIdAndCommentIdManyToManyConnection
  createdAt: Scalars['Datetime']
  /** Reads and enables pagination through a set of `File`. */
  filesByNotificationOrganisationIdAndFileId: OrganisationFilesByNotificationOrganisationIdAndFileIdManyToManyConnection
  id: Scalars['UUID']
  /** Reads and enables pagination through a set of `Mention`. */
  mentions: MentionsConnection
  name: Scalars['String']
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']
  /** Reads and enables pagination through a set of `Notification`. */
  notifications: NotificationsConnection
  /** Reads and enables pagination through a set of `OrganisationInvitation`. */
  organisationInvitations: OrganisationInvitationsConnection
  /** Reads and enables pagination through a set of `Person`. */
  peopleByCollectionOrganisationIdAndPersonId: OrganisationPeopleByCollectionOrganisationIdAndPersonIdManyToManyConnection
  /** Reads and enables pagination through a set of `Person`. */
  peopleByMentionOrganisationIdAndMentionedId: OrganisationPeopleByMentionOrganisationIdAndMentionedIdManyToManyConnection
  /** Reads and enables pagination through a set of `Person`. */
  peopleByMentionOrganisationIdAndPersonId: OrganisationPeopleByMentionOrganisationIdAndPersonIdManyToManyConnection
  /** Reads and enables pagination through a set of `Person`. */
  peopleByNotificationOrganisationIdAndPersonId: OrganisationPeopleByNotificationOrganisationIdAndPersonIdManyToManyConnection
  /** Reads and enables pagination through a set of `Person`. */
  peopleByNotificationOrganisationIdAndReceiverId: OrganisationPeopleByNotificationOrganisationIdAndReceiverIdManyToManyConnection
  /** Reads and enables pagination through a set of `Person`. */
  peopleByOrganisationInvitationOrganisationIdAndAcceptedBy: OrganisationPeopleByOrganisationInvitationOrganisationIdAndAcceptedByManyToManyConnection
  /** Reads and enables pagination through a set of `Person`. */
  peopleByOrganisationInvitationOrganisationIdAndInvitedBy: OrganisationPeopleByOrganisationInvitationOrganisationIdAndInvitedByManyToManyConnection
  /** Reads and enables pagination through a set of `Person`. */
  peopleByPersonOrganisationOrganisationIdAndPersonId: OrganisationPeopleByPersonOrganisationOrganisationIdAndPersonIdManyToManyConnection
  /** Reads and enables pagination through a set of `Person`. */
  peopleByProjectOrganisationIdAndPersonId: OrganisationPeopleByProjectOrganisationIdAndPersonIdManyToManyConnection
  /** Reads and enables pagination through a set of `PersonOrganisation`. */
  personOrganisations: PersonOrganisationsConnection
  /** Each person has a personal organisation by default */
  personal?: Maybe<Scalars['Boolean']>
  /** Reads and enables pagination through a set of `Project`. */
  projects: ProjectsConnection
  updatedAt: Scalars['Datetime']
}

/** A Loupe Organisation is the main organisational unit for collaboration */
export type OrganisationCollectionsArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<CollectionCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<CollectionsOrderBy>>
}

/** A Loupe Organisation is the main organisational unit for collaboration */
export type OrganisationCommentsByMentionOrganisationIdAndCommentIdArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<CommentCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<CommentsOrderBy>>
}

/** A Loupe Organisation is the main organisational unit for collaboration */
export type OrganisationCommentsByNotificationOrganisationIdAndCommentIdArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<CommentCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<CommentsOrderBy>>
}

/** A Loupe Organisation is the main organisational unit for collaboration */
export type OrganisationFilesByNotificationOrganisationIdAndFileIdArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<FileCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<FilesOrderBy>>
}

/** A Loupe Organisation is the main organisational unit for collaboration */
export type OrganisationMentionsArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<MentionCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<MentionsOrderBy>>
}

/** A Loupe Organisation is the main organisational unit for collaboration */
export type OrganisationNotificationsArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<NotificationCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<NotificationsOrderBy>>
}

/** A Loupe Organisation is the main organisational unit for collaboration */
export type OrganisationOrganisationInvitationsArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<OrganisationInvitationCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<OrganisationInvitationsOrderBy>>
}

/** A Loupe Organisation is the main organisational unit for collaboration */
export type OrganisationPeopleByCollectionOrganisationIdAndPersonIdArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<PersonCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<PeopleOrderBy>>
}

/** A Loupe Organisation is the main organisational unit for collaboration */
export type OrganisationPeopleByMentionOrganisationIdAndMentionedIdArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<PersonCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<PeopleOrderBy>>
}

/** A Loupe Organisation is the main organisational unit for collaboration */
export type OrganisationPeopleByMentionOrganisationIdAndPersonIdArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<PersonCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<PeopleOrderBy>>
}

/** A Loupe Organisation is the main organisational unit for collaboration */
export type OrganisationPeopleByNotificationOrganisationIdAndPersonIdArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<PersonCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<PeopleOrderBy>>
}

/** A Loupe Organisation is the main organisational unit for collaboration */
export type OrganisationPeopleByNotificationOrganisationIdAndReceiverIdArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<PersonCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<PeopleOrderBy>>
}

/** A Loupe Organisation is the main organisational unit for collaboration */
export type OrganisationPeopleByOrganisationInvitationOrganisationIdAndAcceptedByArgs =
  {
    after?: Maybe<Scalars['Cursor']>
    before?: Maybe<Scalars['Cursor']>
    condition?: Maybe<PersonCondition>
    first?: Maybe<Scalars['Int']>
    last?: Maybe<Scalars['Int']>
    offset?: Maybe<Scalars['Int']>
    orderBy?: Maybe<Array<PeopleOrderBy>>
  }

/** A Loupe Organisation is the main organisational unit for collaboration */
export type OrganisationPeopleByOrganisationInvitationOrganisationIdAndInvitedByArgs =
  {
    after?: Maybe<Scalars['Cursor']>
    before?: Maybe<Scalars['Cursor']>
    condition?: Maybe<PersonCondition>
    first?: Maybe<Scalars['Int']>
    last?: Maybe<Scalars['Int']>
    offset?: Maybe<Scalars['Int']>
    orderBy?: Maybe<Array<PeopleOrderBy>>
  }

/** A Loupe Organisation is the main organisational unit for collaboration */
export type OrganisationPeopleByPersonOrganisationOrganisationIdAndPersonIdArgs =
  {
    after?: Maybe<Scalars['Cursor']>
    before?: Maybe<Scalars['Cursor']>
    condition?: Maybe<PersonCondition>
    first?: Maybe<Scalars['Int']>
    last?: Maybe<Scalars['Int']>
    offset?: Maybe<Scalars['Int']>
    orderBy?: Maybe<Array<PeopleOrderBy>>
  }

/** A Loupe Organisation is the main organisational unit for collaboration */
export type OrganisationPeopleByProjectOrganisationIdAndPersonIdArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<PersonCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<PeopleOrderBy>>
}

/** A Loupe Organisation is the main organisational unit for collaboration */
export type OrganisationPersonOrganisationsArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<PersonOrganisationCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<PersonOrganisationsOrderBy>>
}

/** A Loupe Organisation is the main organisational unit for collaboration */
export type OrganisationProjectsArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<ProjectCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<ProjectsOrderBy>>
}

/** A connection to a list of `Comment` values, with data from `Mention`. */
export type OrganisationCommentsByMentionOrganisationIdAndCommentIdManyToManyConnection =
  {
    __typename?: 'OrganisationCommentsByMentionOrganisationIdAndCommentIdManyToManyConnection'
    /** A list of edges which contains the `Comment`, info from the `Mention`, and the cursor to aid in pagination. */
    edges: Array<OrganisationCommentsByMentionOrganisationIdAndCommentIdManyToManyEdge>
    /** A list of `Comment` objects. */
    nodes: Array<Maybe<Comment>>
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** The count of *all* `Comment` you could get from the connection. */
    totalCount: Scalars['Int']
  }

/** A `Comment` edge in the connection, with data from `Mention`. */
export type OrganisationCommentsByMentionOrganisationIdAndCommentIdManyToManyEdge =
  {
    __typename?: 'OrganisationCommentsByMentionOrganisationIdAndCommentIdManyToManyEdge'
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>
    /** Reads and enables pagination through a set of `Mention`. */
    mentions: MentionsConnection
    /** The `Comment` at the end of the edge. */
    node?: Maybe<Comment>
  }

/** A `Comment` edge in the connection, with data from `Mention`. */
export type OrganisationCommentsByMentionOrganisationIdAndCommentIdManyToManyEdgeMentionsArgs =
  {
    after?: Maybe<Scalars['Cursor']>
    before?: Maybe<Scalars['Cursor']>
    condition?: Maybe<MentionCondition>
    first?: Maybe<Scalars['Int']>
    last?: Maybe<Scalars['Int']>
    offset?: Maybe<Scalars['Int']>
    orderBy?: Maybe<Array<MentionsOrderBy>>
  }

/** A connection to a list of `Comment` values, with data from `Notification`. */
export type OrganisationCommentsByNotificationOrganisationIdAndCommentIdManyToManyConnection =
  {
    __typename?: 'OrganisationCommentsByNotificationOrganisationIdAndCommentIdManyToManyConnection'
    /** A list of edges which contains the `Comment`, info from the `Notification`, and the cursor to aid in pagination. */
    edges: Array<OrganisationCommentsByNotificationOrganisationIdAndCommentIdManyToManyEdge>
    /** A list of `Comment` objects. */
    nodes: Array<Maybe<Comment>>
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** The count of *all* `Comment` you could get from the connection. */
    totalCount: Scalars['Int']
  }

/** A `Comment` edge in the connection, with data from `Notification`. */
export type OrganisationCommentsByNotificationOrganisationIdAndCommentIdManyToManyEdge =
  {
    __typename?: 'OrganisationCommentsByNotificationOrganisationIdAndCommentIdManyToManyEdge'
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>
    /** The `Comment` at the end of the edge. */
    node?: Maybe<Comment>
    /** Reads and enables pagination through a set of `Notification`. */
    notifications: NotificationsConnection
  }

/** A `Comment` edge in the connection, with data from `Notification`. */
export type OrganisationCommentsByNotificationOrganisationIdAndCommentIdManyToManyEdgeNotificationsArgs =
  {
    after?: Maybe<Scalars['Cursor']>
    before?: Maybe<Scalars['Cursor']>
    condition?: Maybe<NotificationCondition>
    first?: Maybe<Scalars['Int']>
    last?: Maybe<Scalars['Int']>
    offset?: Maybe<Scalars['Int']>
    orderBy?: Maybe<Array<NotificationsOrderBy>>
  }

/**
 * A condition to be used against `Organisation` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type OrganisationCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>
  /** Checks for equality with the object’s `personal` field. */
  personal?: Maybe<Scalars['Boolean']>
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>
}

/** A connection to a list of `File` values, with data from `Notification`. */
export type OrganisationFilesByNotificationOrganisationIdAndFileIdManyToManyConnection =
  {
    __typename?: 'OrganisationFilesByNotificationOrganisationIdAndFileIdManyToManyConnection'
    /** A list of edges which contains the `File`, info from the `Notification`, and the cursor to aid in pagination. */
    edges: Array<OrganisationFilesByNotificationOrganisationIdAndFileIdManyToManyEdge>
    /** A list of `File` objects. */
    nodes: Array<Maybe<File>>
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** The count of *all* `File` you could get from the connection. */
    totalCount: Scalars['Int']
  }

/** A `File` edge in the connection, with data from `Notification`. */
export type OrganisationFilesByNotificationOrganisationIdAndFileIdManyToManyEdge =
  {
    __typename?: 'OrganisationFilesByNotificationOrganisationIdAndFileIdManyToManyEdge'
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>
    /** The `File` at the end of the edge. */
    node?: Maybe<File>
    /** Reads and enables pagination through a set of `Notification`. */
    notifications: NotificationsConnection
  }

/** A `File` edge in the connection, with data from `Notification`. */
export type OrganisationFilesByNotificationOrganisationIdAndFileIdManyToManyEdgeNotificationsArgs =
  {
    after?: Maybe<Scalars['Cursor']>
    before?: Maybe<Scalars['Cursor']>
    condition?: Maybe<NotificationCondition>
    first?: Maybe<Scalars['Int']>
    last?: Maybe<Scalars['Int']>
    offset?: Maybe<Scalars['Int']>
    orderBy?: Maybe<Array<NotificationsOrderBy>>
  }

/** An input for mutations affecting `Organisation` */
export type OrganisationInput = {
  createdAt?: Maybe<Scalars['Datetime']>
  id?: Maybe<Scalars['UUID']>
  name: Scalars['String']
  /** Each person has a personal organisation by default */
  personal?: Maybe<Scalars['Boolean']>
  updatedAt?: Maybe<Scalars['Datetime']>
}

export type OrganisationInvitation = Node & {
  __typename?: 'OrganisationInvitation'
  acceptedBy?: Maybe<Scalars['UUID']>
  code: Scalars['String']
  createdAt: Scalars['Datetime']
  id: Scalars['UUID']
  invitedBy?: Maybe<Scalars['UUID']>
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']
  /** Reads a single `Organisation` that is related to this `OrganisationInvitation`. */
  organisation?: Maybe<Organisation>
  organisationId: Scalars['UUID']
  /** Reads a single `Person` that is related to this `OrganisationInvitation`. */
  personByAcceptedBy?: Maybe<Person>
  /** Reads a single `Person` that is related to this `OrganisationInvitation`. */
  personByInvitedBy?: Maybe<Person>
  updatedAt: Scalars['Datetime']
}

/**
 * A condition to be used against `OrganisationInvitation` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type OrganisationInvitationCondition = {
  /** Checks for equality with the object’s `acceptedBy` field. */
  acceptedBy?: Maybe<Scalars['UUID']>
  /** Checks for equality with the object’s `code` field. */
  code?: Maybe<Scalars['String']>
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>
  /** Checks for equality with the object’s `invitedBy` field. */
  invitedBy?: Maybe<Scalars['UUID']>
  /** Checks for equality with the object’s `organisationId` field. */
  organisationId?: Maybe<Scalars['UUID']>
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>
}

/** Represents an update to a `OrganisationInvitation`. Fields that are set will be updated. */
export type OrganisationInvitationPatch = {
  acceptedBy?: Maybe<Scalars['UUID']>
  code?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['Datetime']>
  id?: Maybe<Scalars['UUID']>
  invitedBy?: Maybe<Scalars['UUID']>
  organisationId?: Maybe<Scalars['UUID']>
  updatedAt?: Maybe<Scalars['Datetime']>
}

/** A connection to a list of `OrganisationInvitation` values. */
export type OrganisationInvitationsConnection = {
  __typename?: 'OrganisationInvitationsConnection'
  /** A list of edges which contains the `OrganisationInvitation` and cursor to aid in pagination. */
  edges: Array<OrganisationInvitationsEdge>
  /** A list of `OrganisationInvitation` objects. */
  nodes: Array<Maybe<OrganisationInvitation>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `OrganisationInvitation` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `OrganisationInvitation` edge in the connection. */
export type OrganisationInvitationsEdge = {
  __typename?: 'OrganisationInvitationsEdge'
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>
  /** The `OrganisationInvitation` at the end of the edge. */
  node?: Maybe<OrganisationInvitation>
}

/** Methods to use when ordering `OrganisationInvitation`. */
export enum OrganisationInvitationsOrderBy {
  AcceptedByAsc = 'ACCEPTED_BY_ASC',
  AcceptedByDesc = 'ACCEPTED_BY_DESC',
  CodeAsc = 'CODE_ASC',
  CodeDesc = 'CODE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  InvitedByAsc = 'INVITED_BY_ASC',
  InvitedByDesc = 'INVITED_BY_DESC',
  Natural = 'NATURAL',
  OrganisationIdAsc = 'ORGANISATION_ID_ASC',
  OrganisationIdDesc = 'ORGANISATION_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
}

/** Represents an update to a `Organisation`. Fields that are set will be updated. */
export type OrganisationPatch = {
  createdAt?: Maybe<Scalars['Datetime']>
  id?: Maybe<Scalars['UUID']>
  name?: Maybe<Scalars['String']>
  /** Each person has a personal organisation by default */
  personal?: Maybe<Scalars['Boolean']>
  updatedAt?: Maybe<Scalars['Datetime']>
}

/** A connection to a list of `Person` values, with data from `Collection`. */
export type OrganisationPeopleByCollectionOrganisationIdAndPersonIdManyToManyConnection =
  {
    __typename?: 'OrganisationPeopleByCollectionOrganisationIdAndPersonIdManyToManyConnection'
    /** A list of edges which contains the `Person`, info from the `Collection`, and the cursor to aid in pagination. */
    edges: Array<OrganisationPeopleByCollectionOrganisationIdAndPersonIdManyToManyEdge>
    /** A list of `Person` objects. */
    nodes: Array<Maybe<Person>>
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** The count of *all* `Person` you could get from the connection. */
    totalCount: Scalars['Int']
  }

/** A `Person` edge in the connection, with data from `Collection`. */
export type OrganisationPeopleByCollectionOrganisationIdAndPersonIdManyToManyEdge =
  {
    __typename?: 'OrganisationPeopleByCollectionOrganisationIdAndPersonIdManyToManyEdge'
    /** Reads and enables pagination through a set of `Collection`. */
    collections: CollectionsConnection
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>
    /** The `Person` at the end of the edge. */
    node?: Maybe<Person>
  }

/** A `Person` edge in the connection, with data from `Collection`. */
export type OrganisationPeopleByCollectionOrganisationIdAndPersonIdManyToManyEdgeCollectionsArgs =
  {
    after?: Maybe<Scalars['Cursor']>
    before?: Maybe<Scalars['Cursor']>
    condition?: Maybe<CollectionCondition>
    first?: Maybe<Scalars['Int']>
    last?: Maybe<Scalars['Int']>
    offset?: Maybe<Scalars['Int']>
    orderBy?: Maybe<Array<CollectionsOrderBy>>
  }

/** A connection to a list of `Person` values, with data from `Mention`. */
export type OrganisationPeopleByMentionOrganisationIdAndMentionedIdManyToManyConnection =
  {
    __typename?: 'OrganisationPeopleByMentionOrganisationIdAndMentionedIdManyToManyConnection'
    /** A list of edges which contains the `Person`, info from the `Mention`, and the cursor to aid in pagination. */
    edges: Array<OrganisationPeopleByMentionOrganisationIdAndMentionedIdManyToManyEdge>
    /** A list of `Person` objects. */
    nodes: Array<Maybe<Person>>
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** The count of *all* `Person` you could get from the connection. */
    totalCount: Scalars['Int']
  }

/** A `Person` edge in the connection, with data from `Mention`. */
export type OrganisationPeopleByMentionOrganisationIdAndMentionedIdManyToManyEdge =
  {
    __typename?: 'OrganisationPeopleByMentionOrganisationIdAndMentionedIdManyToManyEdge'
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>
    /** Reads and enables pagination through a set of `Mention`. */
    mentionsByMentionedId: MentionsConnection
    /** The `Person` at the end of the edge. */
    node?: Maybe<Person>
  }

/** A `Person` edge in the connection, with data from `Mention`. */
export type OrganisationPeopleByMentionOrganisationIdAndMentionedIdManyToManyEdgeMentionsByMentionedIdArgs =
  {
    after?: Maybe<Scalars['Cursor']>
    before?: Maybe<Scalars['Cursor']>
    condition?: Maybe<MentionCondition>
    first?: Maybe<Scalars['Int']>
    last?: Maybe<Scalars['Int']>
    offset?: Maybe<Scalars['Int']>
    orderBy?: Maybe<Array<MentionsOrderBy>>
  }

/** A connection to a list of `Person` values, with data from `Mention`. */
export type OrganisationPeopleByMentionOrganisationIdAndPersonIdManyToManyConnection =
  {
    __typename?: 'OrganisationPeopleByMentionOrganisationIdAndPersonIdManyToManyConnection'
    /** A list of edges which contains the `Person`, info from the `Mention`, and the cursor to aid in pagination. */
    edges: Array<OrganisationPeopleByMentionOrganisationIdAndPersonIdManyToManyEdge>
    /** A list of `Person` objects. */
    nodes: Array<Maybe<Person>>
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** The count of *all* `Person` you could get from the connection. */
    totalCount: Scalars['Int']
  }

/** A `Person` edge in the connection, with data from `Mention`. */
export type OrganisationPeopleByMentionOrganisationIdAndPersonIdManyToManyEdge =
  {
    __typename?: 'OrganisationPeopleByMentionOrganisationIdAndPersonIdManyToManyEdge'
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>
    /** Reads and enables pagination through a set of `Mention`. */
    mentions: MentionsConnection
    /** The `Person` at the end of the edge. */
    node?: Maybe<Person>
  }

/** A `Person` edge in the connection, with data from `Mention`. */
export type OrganisationPeopleByMentionOrganisationIdAndPersonIdManyToManyEdgeMentionsArgs =
  {
    after?: Maybe<Scalars['Cursor']>
    before?: Maybe<Scalars['Cursor']>
    condition?: Maybe<MentionCondition>
    first?: Maybe<Scalars['Int']>
    last?: Maybe<Scalars['Int']>
    offset?: Maybe<Scalars['Int']>
    orderBy?: Maybe<Array<MentionsOrderBy>>
  }

/** A connection to a list of `Person` values, with data from `Notification`. */
export type OrganisationPeopleByNotificationOrganisationIdAndPersonIdManyToManyConnection =
  {
    __typename?: 'OrganisationPeopleByNotificationOrganisationIdAndPersonIdManyToManyConnection'
    /** A list of edges which contains the `Person`, info from the `Notification`, and the cursor to aid in pagination. */
    edges: Array<OrganisationPeopleByNotificationOrganisationIdAndPersonIdManyToManyEdge>
    /** A list of `Person` objects. */
    nodes: Array<Maybe<Person>>
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** The count of *all* `Person` you could get from the connection. */
    totalCount: Scalars['Int']
  }

/** A `Person` edge in the connection, with data from `Notification`. */
export type OrganisationPeopleByNotificationOrganisationIdAndPersonIdManyToManyEdge =
  {
    __typename?: 'OrganisationPeopleByNotificationOrganisationIdAndPersonIdManyToManyEdge'
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>
    /** The `Person` at the end of the edge. */
    node?: Maybe<Person>
    /** Reads and enables pagination through a set of `Notification`. */
    notifications: NotificationsConnection
  }

/** A `Person` edge in the connection, with data from `Notification`. */
export type OrganisationPeopleByNotificationOrganisationIdAndPersonIdManyToManyEdgeNotificationsArgs =
  {
    after?: Maybe<Scalars['Cursor']>
    before?: Maybe<Scalars['Cursor']>
    condition?: Maybe<NotificationCondition>
    first?: Maybe<Scalars['Int']>
    last?: Maybe<Scalars['Int']>
    offset?: Maybe<Scalars['Int']>
    orderBy?: Maybe<Array<NotificationsOrderBy>>
  }

/** A connection to a list of `Person` values, with data from `Notification`. */
export type OrganisationPeopleByNotificationOrganisationIdAndReceiverIdManyToManyConnection =
  {
    __typename?: 'OrganisationPeopleByNotificationOrganisationIdAndReceiverIdManyToManyConnection'
    /** A list of edges which contains the `Person`, info from the `Notification`, and the cursor to aid in pagination. */
    edges: Array<OrganisationPeopleByNotificationOrganisationIdAndReceiverIdManyToManyEdge>
    /** A list of `Person` objects. */
    nodes: Array<Maybe<Person>>
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** The count of *all* `Person` you could get from the connection. */
    totalCount: Scalars['Int']
  }

/** A `Person` edge in the connection, with data from `Notification`. */
export type OrganisationPeopleByNotificationOrganisationIdAndReceiverIdManyToManyEdge =
  {
    __typename?: 'OrganisationPeopleByNotificationOrganisationIdAndReceiverIdManyToManyEdge'
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>
    /** The `Person` at the end of the edge. */
    node?: Maybe<Person>
    /** Reads and enables pagination through a set of `Notification`. */
    notificationsByReceiverId: NotificationsConnection
  }

/** A `Person` edge in the connection, with data from `Notification`. */
export type OrganisationPeopleByNotificationOrganisationIdAndReceiverIdManyToManyEdgeNotificationsByReceiverIdArgs =
  {
    after?: Maybe<Scalars['Cursor']>
    before?: Maybe<Scalars['Cursor']>
    condition?: Maybe<NotificationCondition>
    first?: Maybe<Scalars['Int']>
    last?: Maybe<Scalars['Int']>
    offset?: Maybe<Scalars['Int']>
    orderBy?: Maybe<Array<NotificationsOrderBy>>
  }

/** A connection to a list of `Person` values, with data from `OrganisationInvitation`. */
export type OrganisationPeopleByOrganisationInvitationOrganisationIdAndAcceptedByManyToManyConnection =
  {
    __typename?: 'OrganisationPeopleByOrganisationInvitationOrganisationIdAndAcceptedByManyToManyConnection'
    /** A list of edges which contains the `Person`, info from the `OrganisationInvitation`, and the cursor to aid in pagination. */
    edges: Array<OrganisationPeopleByOrganisationInvitationOrganisationIdAndAcceptedByManyToManyEdge>
    /** A list of `Person` objects. */
    nodes: Array<Maybe<Person>>
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** The count of *all* `Person` you could get from the connection. */
    totalCount: Scalars['Int']
  }

/** A `Person` edge in the connection, with data from `OrganisationInvitation`. */
export type OrganisationPeopleByOrganisationInvitationOrganisationIdAndAcceptedByManyToManyEdge =
  {
    __typename?: 'OrganisationPeopleByOrganisationInvitationOrganisationIdAndAcceptedByManyToManyEdge'
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>
    /** The `Person` at the end of the edge. */
    node?: Maybe<Person>
    /** Reads and enables pagination through a set of `OrganisationInvitation`. */
    organisationInvitationsByAcceptedBy: OrganisationInvitationsConnection
  }

/** A `Person` edge in the connection, with data from `OrganisationInvitation`. */
export type OrganisationPeopleByOrganisationInvitationOrganisationIdAndAcceptedByManyToManyEdgeOrganisationInvitationsByAcceptedByArgs =
  {
    after?: Maybe<Scalars['Cursor']>
    before?: Maybe<Scalars['Cursor']>
    condition?: Maybe<OrganisationInvitationCondition>
    first?: Maybe<Scalars['Int']>
    last?: Maybe<Scalars['Int']>
    offset?: Maybe<Scalars['Int']>
    orderBy?: Maybe<Array<OrganisationInvitationsOrderBy>>
  }

/** A connection to a list of `Person` values, with data from `OrganisationInvitation`. */
export type OrganisationPeopleByOrganisationInvitationOrganisationIdAndInvitedByManyToManyConnection =
  {
    __typename?: 'OrganisationPeopleByOrganisationInvitationOrganisationIdAndInvitedByManyToManyConnection'
    /** A list of edges which contains the `Person`, info from the `OrganisationInvitation`, and the cursor to aid in pagination. */
    edges: Array<OrganisationPeopleByOrganisationInvitationOrganisationIdAndInvitedByManyToManyEdge>
    /** A list of `Person` objects. */
    nodes: Array<Maybe<Person>>
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** The count of *all* `Person` you could get from the connection. */
    totalCount: Scalars['Int']
  }

/** A `Person` edge in the connection, with data from `OrganisationInvitation`. */
export type OrganisationPeopleByOrganisationInvitationOrganisationIdAndInvitedByManyToManyEdge =
  {
    __typename?: 'OrganisationPeopleByOrganisationInvitationOrganisationIdAndInvitedByManyToManyEdge'
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>
    /** The `Person` at the end of the edge. */
    node?: Maybe<Person>
    /** Reads and enables pagination through a set of `OrganisationInvitation`. */
    organisationInvitationsByInvitedBy: OrganisationInvitationsConnection
  }

/** A `Person` edge in the connection, with data from `OrganisationInvitation`. */
export type OrganisationPeopleByOrganisationInvitationOrganisationIdAndInvitedByManyToManyEdgeOrganisationInvitationsByInvitedByArgs =
  {
    after?: Maybe<Scalars['Cursor']>
    before?: Maybe<Scalars['Cursor']>
    condition?: Maybe<OrganisationInvitationCondition>
    first?: Maybe<Scalars['Int']>
    last?: Maybe<Scalars['Int']>
    offset?: Maybe<Scalars['Int']>
    orderBy?: Maybe<Array<OrganisationInvitationsOrderBy>>
  }

/** A connection to a list of `Person` values, with data from `PersonOrganisation`. */
export type OrganisationPeopleByPersonOrganisationOrganisationIdAndPersonIdManyToManyConnection =
  {
    __typename?: 'OrganisationPeopleByPersonOrganisationOrganisationIdAndPersonIdManyToManyConnection'
    /** A list of edges which contains the `Person`, info from the `PersonOrganisation`, and the cursor to aid in pagination. */
    edges: Array<OrganisationPeopleByPersonOrganisationOrganisationIdAndPersonIdManyToManyEdge>
    /** A list of `Person` objects. */
    nodes: Array<Maybe<Person>>
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** The count of *all* `Person` you could get from the connection. */
    totalCount: Scalars['Int']
  }

/** A `Person` edge in the connection, with data from `PersonOrganisation`. */
export type OrganisationPeopleByPersonOrganisationOrganisationIdAndPersonIdManyToManyEdge =
  {
    __typename?: 'OrganisationPeopleByPersonOrganisationOrganisationIdAndPersonIdManyToManyEdge'
    createdAt: Scalars['Datetime']
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>
    id: Scalars['UUID']
    isOwner: Scalars['Boolean']
    /** The `Person` at the end of the edge. */
    node?: Maybe<Person>
    updatedAt: Scalars['Datetime']
  }

/** A connection to a list of `Person` values, with data from `Project`. */
export type OrganisationPeopleByProjectOrganisationIdAndPersonIdManyToManyConnection =
  {
    __typename?: 'OrganisationPeopleByProjectOrganisationIdAndPersonIdManyToManyConnection'
    /** A list of edges which contains the `Person`, info from the `Project`, and the cursor to aid in pagination. */
    edges: Array<OrganisationPeopleByProjectOrganisationIdAndPersonIdManyToManyEdge>
    /** A list of `Person` objects. */
    nodes: Array<Maybe<Person>>
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** The count of *all* `Person` you could get from the connection. */
    totalCount: Scalars['Int']
  }

/** A `Person` edge in the connection, with data from `Project`. */
export type OrganisationPeopleByProjectOrganisationIdAndPersonIdManyToManyEdge =
  {
    __typename?: 'OrganisationPeopleByProjectOrganisationIdAndPersonIdManyToManyEdge'
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>
    /** The `Person` at the end of the edge. */
    node?: Maybe<Person>
    /** Reads and enables pagination through a set of `Project`. */
    projects: ProjectsConnection
  }

/** A `Person` edge in the connection, with data from `Project`. */
export type OrganisationPeopleByProjectOrganisationIdAndPersonIdManyToManyEdgeProjectsArgs =
  {
    after?: Maybe<Scalars['Cursor']>
    before?: Maybe<Scalars['Cursor']>
    condition?: Maybe<ProjectCondition>
    first?: Maybe<Scalars['Int']>
    last?: Maybe<Scalars['Int']>
    offset?: Maybe<Scalars['Int']>
    orderBy?: Maybe<Array<ProjectsOrderBy>>
  }

/** A connection to a list of `Organisation` values. */
export type OrganisationsConnection = {
  __typename?: 'OrganisationsConnection'
  /** A list of edges which contains the `Organisation` and cursor to aid in pagination. */
  edges: Array<OrganisationsEdge>
  /** A list of `Organisation` objects. */
  nodes: Array<Maybe<Organisation>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `Organisation` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `Organisation` edge in the connection. */
export type OrganisationsEdge = {
  __typename?: 'OrganisationsEdge'
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>
  /** The `Organisation` at the end of the edge. */
  node?: Maybe<Organisation>
}

/** Methods to use when ordering `Organisation`. */
export enum OrganisationsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PersonalAsc = 'PERSONAL_ASC',
  PersonalDesc = 'PERSONAL_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
}

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo'
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['Cursor']>
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['Cursor']>
}

/** A connection to a list of `Person` values. */
export type PeopleConnection = {
  __typename?: 'PeopleConnection'
  /** A list of edges which contains the `Person` and cursor to aid in pagination. */
  edges: Array<PeopleEdge>
  /** A list of `Person` objects. */
  nodes: Array<Maybe<Person>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `Person` edge in the connection. */
export type PeopleEdge = {
  __typename?: 'PeopleEdge'
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>
  /** The `Person` at the end of the edge. */
  node?: Maybe<Person>
}

/** Methods to use when ordering `Person`. */
export enum PeopleOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DeviceIdsAsc = 'DEVICE_IDS_ASC',
  DeviceIdsDesc = 'DEVICE_IDS_DESC',
  FirebaseIdAsc = 'FIREBASE_ID_ASC',
  FirebaseIdDesc = 'FIREBASE_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PhotoUrlAsc = 'PHOTO_URL_ASC',
  PhotoUrlDesc = 'PHOTO_URL_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
}

export type Person = Node & {
  __typename?: 'Person'
  /** Reads and enables pagination through a set of `Collection`. */
  collections: CollectionsConnection
  /** Reads and enables pagination through a set of `Comment`. */
  comments: CommentsConnection
  /** Reads and enables pagination through a set of `Comment`. */
  commentsByMentionMentionedIdAndCommentId: PersonCommentsByMentionMentionedIdAndCommentIdManyToManyConnection
  /** Reads and enables pagination through a set of `Comment`. */
  commentsByMentionPersonIdAndCommentId: PersonCommentsByMentionPersonIdAndCommentIdManyToManyConnection
  /** Reads and enables pagination through a set of `Comment`. */
  commentsByNotificationPersonIdAndCommentId: PersonCommentsByNotificationPersonIdAndCommentIdManyToManyConnection
  /** Reads and enables pagination through a set of `Comment`. */
  commentsByNotificationReceiverIdAndCommentId: PersonCommentsByNotificationReceiverIdAndCommentIdManyToManyConnection
  /** Reads and enables pagination through a set of `Comment`. */
  commentsByReactionPersonIdAndCommentId: PersonCommentsByReactionPersonIdAndCommentIdManyToManyConnection
  createdAt?: Maybe<Scalars['Datetime']>
  deviceIds?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Reads and enables pagination through a set of `File`. */
  files: FilesConnection
  /** Reads and enables pagination through a set of `File`. */
  filesByCommentPersonIdAndFileId: PersonFilesByCommentPersonIdAndFileIdManyToManyConnection
  /** Reads and enables pagination through a set of `File`. */
  filesByFilePersonIdAndOriginalFileId: PersonFilesByFilePersonIdAndOriginalFileIdManyToManyConnection
  /** Reads and enables pagination through a set of `File`. */
  filesByNotificationPersonIdAndFileId: PersonFilesByNotificationPersonIdAndFileIdManyToManyConnection
  /** Reads and enables pagination through a set of `File`. */
  filesByNotificationReceiverIdAndFileId: PersonFilesByNotificationReceiverIdAndFileIdManyToManyConnection
  /** Reads and enables pagination through a set of `File`. */
  filesByReactionPersonIdAndFileId: PersonFilesByReactionPersonIdAndFileIdManyToManyConnection
  firebaseId: Scalars['String']
  id: Scalars['UUID']
  /** Reads and enables pagination through a set of `Mention`. */
  mentions: MentionsConnection
  /** Reads and enables pagination through a set of `Mention`. */
  mentionsByMentionedId: MentionsConnection
  name: Scalars['String']
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']
  /** Reads and enables pagination through a set of `Notification`. */
  notifications: NotificationsConnection
  /** Reads and enables pagination through a set of `Notification`. */
  notificationsByReceiverId: NotificationsConnection
  /** Reads and enables pagination through a set of `OrganisationInvitation`. */
  organisationInvitationsByAcceptedBy: OrganisationInvitationsConnection
  /** Reads and enables pagination through a set of `OrganisationInvitation`. */
  organisationInvitationsByInvitedBy: OrganisationInvitationsConnection
  /** Reads and enables pagination through a set of `Organisation`. */
  organisationsByCollectionPersonIdAndOrganisationId: PersonOrganisationsByCollectionPersonIdAndOrganisationIdManyToManyConnection
  /** Reads and enables pagination through a set of `Organisation`. */
  organisationsByMentionMentionedIdAndOrganisationId: PersonOrganisationsByMentionMentionedIdAndOrganisationIdManyToManyConnection
  /** Reads and enables pagination through a set of `Organisation`. */
  organisationsByMentionPersonIdAndOrganisationId: PersonOrganisationsByMentionPersonIdAndOrganisationIdManyToManyConnection
  /** Reads and enables pagination through a set of `Organisation`. */
  organisationsByNotificationPersonIdAndOrganisationId: PersonOrganisationsByNotificationPersonIdAndOrganisationIdManyToManyConnection
  /** Reads and enables pagination through a set of `Organisation`. */
  organisationsByNotificationReceiverIdAndOrganisationId: PersonOrganisationsByNotificationReceiverIdAndOrganisationIdManyToManyConnection
  /** Reads and enables pagination through a set of `Organisation`. */
  organisationsByOrganisationInvitationAcceptedByAndOrganisationId: PersonOrganisationsByOrganisationInvitationAcceptedByAndOrganisationIdManyToManyConnection
  /** Reads and enables pagination through a set of `Organisation`. */
  organisationsByOrganisationInvitationInvitedByAndOrganisationId: PersonOrganisationsByOrganisationInvitationInvitedByAndOrganisationIdManyToManyConnection
  /** Reads and enables pagination through a set of `Organisation`. */
  organisationsByPersonOrganisationPersonIdAndOrganisationId: PersonOrganisationsByPersonOrganisationPersonIdAndOrganisationIdManyToManyConnection
  /** Reads and enables pagination through a set of `Organisation`. */
  organisationsByProjectPersonIdAndOrganisationId: PersonOrganisationsByProjectPersonIdAndOrganisationIdManyToManyConnection
  /** Reads and enables pagination through a set of `Person`. */
  peopleByMentionMentionedIdAndPersonId: PersonPeopleByMentionMentionedIdAndPersonIdManyToManyConnection
  /** Reads and enables pagination through a set of `Person`. */
  peopleByMentionPersonIdAndMentionedId: PersonPeopleByMentionPersonIdAndMentionedIdManyToManyConnection
  /** Reads and enables pagination through a set of `Person`. */
  peopleByNotificationPersonIdAndReceiverId: PersonPeopleByNotificationPersonIdAndReceiverIdManyToManyConnection
  /** Reads and enables pagination through a set of `Person`. */
  peopleByNotificationReceiverIdAndPersonId: PersonPeopleByNotificationReceiverIdAndPersonIdManyToManyConnection
  /** Reads and enables pagination through a set of `Person`. */
  peopleByOrganisationInvitationAcceptedByAndInvitedBy: PersonPeopleByOrganisationInvitationAcceptedByAndInvitedByManyToManyConnection
  /** Reads and enables pagination through a set of `Person`. */
  peopleByOrganisationInvitationInvitedByAndAcceptedBy: PersonPeopleByOrganisationInvitationInvitedByAndAcceptedByManyToManyConnection
  /** Reads and enables pagination through a set of `PersonOrganisation`. */
  personOrganisations: PersonOrganisationsConnection
  /** Reads and enables pagination through a set of `PersonProject`. */
  personProjects: PersonProjectsConnection
  photoUrl?: Maybe<Scalars['String']>
  /** Reads and enables pagination through a set of `Project`. */
  projects: ProjectsConnection
  /** Reads and enables pagination through a set of `Project`. */
  projectsByFilePersonIdAndProjectId: PersonProjectsByFilePersonIdAndProjectIdManyToManyConnection
  /** Reads and enables pagination through a set of `Project`. */
  projectsByPersonProjectPersonIdAndProjectId: PersonProjectsByPersonProjectPersonIdAndProjectIdManyToManyConnection
  /** Reads and enables pagination through a set of `Reaction`. */
  reactions: ReactionsConnection
  updatedAt: Scalars['Datetime']
}

export type PersonCollectionsArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<CollectionCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<CollectionsOrderBy>>
}

export type PersonCommentsArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<CommentCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<CommentsOrderBy>>
}

export type PersonCommentsByMentionMentionedIdAndCommentIdArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<CommentCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<CommentsOrderBy>>
}

export type PersonCommentsByMentionPersonIdAndCommentIdArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<CommentCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<CommentsOrderBy>>
}

export type PersonCommentsByNotificationPersonIdAndCommentIdArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<CommentCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<CommentsOrderBy>>
}

export type PersonCommentsByNotificationReceiverIdAndCommentIdArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<CommentCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<CommentsOrderBy>>
}

export type PersonCommentsByReactionPersonIdAndCommentIdArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<CommentCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<CommentsOrderBy>>
}

export type PersonFilesArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<FileCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<FilesOrderBy>>
}

export type PersonFilesByCommentPersonIdAndFileIdArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<FileCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<FilesOrderBy>>
}

export type PersonFilesByFilePersonIdAndOriginalFileIdArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<FileCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<FilesOrderBy>>
}

export type PersonFilesByNotificationPersonIdAndFileIdArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<FileCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<FilesOrderBy>>
}

export type PersonFilesByNotificationReceiverIdAndFileIdArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<FileCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<FilesOrderBy>>
}

export type PersonFilesByReactionPersonIdAndFileIdArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<FileCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<FilesOrderBy>>
}

export type PersonMentionsArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<MentionCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<MentionsOrderBy>>
}

export type PersonMentionsByMentionedIdArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<MentionCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<MentionsOrderBy>>
}

export type PersonNotificationsArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<NotificationCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<NotificationsOrderBy>>
}

export type PersonNotificationsByReceiverIdArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<NotificationCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<NotificationsOrderBy>>
}

export type PersonOrganisationInvitationsByAcceptedByArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<OrganisationInvitationCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<OrganisationInvitationsOrderBy>>
}

export type PersonOrganisationInvitationsByInvitedByArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<OrganisationInvitationCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<OrganisationInvitationsOrderBy>>
}

export type PersonOrganisationsByCollectionPersonIdAndOrganisationIdArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<OrganisationCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<OrganisationsOrderBy>>
}

export type PersonOrganisationsByMentionMentionedIdAndOrganisationIdArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<OrganisationCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<OrganisationsOrderBy>>
}

export type PersonOrganisationsByMentionPersonIdAndOrganisationIdArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<OrganisationCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<OrganisationsOrderBy>>
}

export type PersonOrganisationsByNotificationPersonIdAndOrganisationIdArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<OrganisationCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<OrganisationsOrderBy>>
}

export type PersonOrganisationsByNotificationReceiverIdAndOrganisationIdArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<OrganisationCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<OrganisationsOrderBy>>
}

export type PersonOrganisationsByOrganisationInvitationAcceptedByAndOrganisationIdArgs =
  {
    after?: Maybe<Scalars['Cursor']>
    before?: Maybe<Scalars['Cursor']>
    condition?: Maybe<OrganisationCondition>
    first?: Maybe<Scalars['Int']>
    last?: Maybe<Scalars['Int']>
    offset?: Maybe<Scalars['Int']>
    orderBy?: Maybe<Array<OrganisationsOrderBy>>
  }

export type PersonOrganisationsByOrganisationInvitationInvitedByAndOrganisationIdArgs =
  {
    after?: Maybe<Scalars['Cursor']>
    before?: Maybe<Scalars['Cursor']>
    condition?: Maybe<OrganisationCondition>
    first?: Maybe<Scalars['Int']>
    last?: Maybe<Scalars['Int']>
    offset?: Maybe<Scalars['Int']>
    orderBy?: Maybe<Array<OrganisationsOrderBy>>
  }

export type PersonOrganisationsByPersonOrganisationPersonIdAndOrganisationIdArgs =
  {
    after?: Maybe<Scalars['Cursor']>
    before?: Maybe<Scalars['Cursor']>
    condition?: Maybe<OrganisationCondition>
    first?: Maybe<Scalars['Int']>
    last?: Maybe<Scalars['Int']>
    offset?: Maybe<Scalars['Int']>
    orderBy?: Maybe<Array<OrganisationsOrderBy>>
  }

export type PersonOrganisationsByProjectPersonIdAndOrganisationIdArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<OrganisationCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<OrganisationsOrderBy>>
}

export type PersonPeopleByMentionMentionedIdAndPersonIdArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<PersonCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<PeopleOrderBy>>
}

export type PersonPeopleByMentionPersonIdAndMentionedIdArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<PersonCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<PeopleOrderBy>>
}

export type PersonPeopleByNotificationPersonIdAndReceiverIdArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<PersonCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<PeopleOrderBy>>
}

export type PersonPeopleByNotificationReceiverIdAndPersonIdArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<PersonCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<PeopleOrderBy>>
}

export type PersonPeopleByOrganisationInvitationAcceptedByAndInvitedByArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<PersonCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<PeopleOrderBy>>
}

export type PersonPeopleByOrganisationInvitationInvitedByAndAcceptedByArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<PersonCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<PeopleOrderBy>>
}

export type PersonPersonOrganisationsArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<PersonOrganisationCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<PersonOrganisationsOrderBy>>
}

export type PersonPersonProjectsArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<PersonProjectCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<PersonProjectsOrderBy>>
}

export type PersonProjectsArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<ProjectCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<ProjectsOrderBy>>
}

export type PersonProjectsByFilePersonIdAndProjectIdArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<ProjectCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<ProjectsOrderBy>>
}

export type PersonProjectsByPersonProjectPersonIdAndProjectIdArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<ProjectCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<ProjectsOrderBy>>
}

export type PersonReactionsArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<ReactionCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<ReactionsOrderBy>>
}

/** A connection to a list of `Comment` values, with data from `Mention`. */
export type PersonCommentsByMentionMentionedIdAndCommentIdManyToManyConnection =
  {
    __typename?: 'PersonCommentsByMentionMentionedIdAndCommentIdManyToManyConnection'
    /** A list of edges which contains the `Comment`, info from the `Mention`, and the cursor to aid in pagination. */
    edges: Array<PersonCommentsByMentionMentionedIdAndCommentIdManyToManyEdge>
    /** A list of `Comment` objects. */
    nodes: Array<Maybe<Comment>>
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** The count of *all* `Comment` you could get from the connection. */
    totalCount: Scalars['Int']
  }

/** A `Comment` edge in the connection, with data from `Mention`. */
export type PersonCommentsByMentionMentionedIdAndCommentIdManyToManyEdge = {
  __typename?: 'PersonCommentsByMentionMentionedIdAndCommentIdManyToManyEdge'
  createdAt: Scalars['Datetime']
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>
  id: Scalars['UUID']
  /** The `Comment` at the end of the edge. */
  node?: Maybe<Comment>
  organisationId: Scalars['UUID']
  personId?: Maybe<Scalars['UUID']>
  updatedAt: Scalars['Datetime']
}

/** A connection to a list of `Comment` values, with data from `Mention`. */
export type PersonCommentsByMentionPersonIdAndCommentIdManyToManyConnection = {
  __typename?: 'PersonCommentsByMentionPersonIdAndCommentIdManyToManyConnection'
  /** A list of edges which contains the `Comment`, info from the `Mention`, and the cursor to aid in pagination. */
  edges: Array<PersonCommentsByMentionPersonIdAndCommentIdManyToManyEdge>
  /** A list of `Comment` objects. */
  nodes: Array<Maybe<Comment>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `Comment` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `Comment` edge in the connection, with data from `Mention`. */
export type PersonCommentsByMentionPersonIdAndCommentIdManyToManyEdge = {
  __typename?: 'PersonCommentsByMentionPersonIdAndCommentIdManyToManyEdge'
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>
  /** Reads and enables pagination through a set of `Mention`. */
  mentions: MentionsConnection
  /** The `Comment` at the end of the edge. */
  node?: Maybe<Comment>
}

/** A `Comment` edge in the connection, with data from `Mention`. */
export type PersonCommentsByMentionPersonIdAndCommentIdManyToManyEdgeMentionsArgs =
  {
    after?: Maybe<Scalars['Cursor']>
    before?: Maybe<Scalars['Cursor']>
    condition?: Maybe<MentionCondition>
    first?: Maybe<Scalars['Int']>
    last?: Maybe<Scalars['Int']>
    offset?: Maybe<Scalars['Int']>
    orderBy?: Maybe<Array<MentionsOrderBy>>
  }

/** A connection to a list of `Comment` values, with data from `Notification`. */
export type PersonCommentsByNotificationPersonIdAndCommentIdManyToManyConnection =
  {
    __typename?: 'PersonCommentsByNotificationPersonIdAndCommentIdManyToManyConnection'
    /** A list of edges which contains the `Comment`, info from the `Notification`, and the cursor to aid in pagination. */
    edges: Array<PersonCommentsByNotificationPersonIdAndCommentIdManyToManyEdge>
    /** A list of `Comment` objects. */
    nodes: Array<Maybe<Comment>>
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** The count of *all* `Comment` you could get from the connection. */
    totalCount: Scalars['Int']
  }

/** A `Comment` edge in the connection, with data from `Notification`. */
export type PersonCommentsByNotificationPersonIdAndCommentIdManyToManyEdge = {
  __typename?: 'PersonCommentsByNotificationPersonIdAndCommentIdManyToManyEdge'
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>
  /** The `Comment` at the end of the edge. */
  node?: Maybe<Comment>
  /** Reads and enables pagination through a set of `Notification`. */
  notifications: NotificationsConnection
}

/** A `Comment` edge in the connection, with data from `Notification`. */
export type PersonCommentsByNotificationPersonIdAndCommentIdManyToManyEdgeNotificationsArgs =
  {
    after?: Maybe<Scalars['Cursor']>
    before?: Maybe<Scalars['Cursor']>
    condition?: Maybe<NotificationCondition>
    first?: Maybe<Scalars['Int']>
    last?: Maybe<Scalars['Int']>
    offset?: Maybe<Scalars['Int']>
    orderBy?: Maybe<Array<NotificationsOrderBy>>
  }

/** A connection to a list of `Comment` values, with data from `Notification`. */
export type PersonCommentsByNotificationReceiverIdAndCommentIdManyToManyConnection =
  {
    __typename?: 'PersonCommentsByNotificationReceiverIdAndCommentIdManyToManyConnection'
    /** A list of edges which contains the `Comment`, info from the `Notification`, and the cursor to aid in pagination. */
    edges: Array<PersonCommentsByNotificationReceiverIdAndCommentIdManyToManyEdge>
    /** A list of `Comment` objects. */
    nodes: Array<Maybe<Comment>>
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** The count of *all* `Comment` you could get from the connection. */
    totalCount: Scalars['Int']
  }

/** A `Comment` edge in the connection, with data from `Notification`. */
export type PersonCommentsByNotificationReceiverIdAndCommentIdManyToManyEdge = {
  __typename?: 'PersonCommentsByNotificationReceiverIdAndCommentIdManyToManyEdge'
  createdAt: Scalars['Datetime']
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>
  fileId?: Maybe<Scalars['UUID']>
  id: Scalars['UUID']
  /** The `Comment` at the end of the edge. */
  node?: Maybe<Comment>
  organisationId: Scalars['UUID']
  personId?: Maybe<Scalars['UUID']>
  seen?: Maybe<Scalars['Boolean']>
  updatedAt: Scalars['Datetime']
}

/** A connection to a list of `Comment` values, with data from `Reaction`. */
export type PersonCommentsByReactionPersonIdAndCommentIdManyToManyConnection = {
  __typename?: 'PersonCommentsByReactionPersonIdAndCommentIdManyToManyConnection'
  /** A list of edges which contains the `Comment`, info from the `Reaction`, and the cursor to aid in pagination. */
  edges: Array<PersonCommentsByReactionPersonIdAndCommentIdManyToManyEdge>
  /** A list of `Comment` objects. */
  nodes: Array<Maybe<Comment>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `Comment` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `Comment` edge in the connection, with data from `Reaction`. */
export type PersonCommentsByReactionPersonIdAndCommentIdManyToManyEdge = {
  __typename?: 'PersonCommentsByReactionPersonIdAndCommentIdManyToManyEdge'
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>
  /** The `Comment` at the end of the edge. */
  node?: Maybe<Comment>
  /** Reads and enables pagination through a set of `Reaction`. */
  reactions: ReactionsConnection
}

/** A `Comment` edge in the connection, with data from `Reaction`. */
export type PersonCommentsByReactionPersonIdAndCommentIdManyToManyEdgeReactionsArgs =
  {
    after?: Maybe<Scalars['Cursor']>
    before?: Maybe<Scalars['Cursor']>
    condition?: Maybe<ReactionCondition>
    first?: Maybe<Scalars['Int']>
    last?: Maybe<Scalars['Int']>
    offset?: Maybe<Scalars['Int']>
    orderBy?: Maybe<Array<ReactionsOrderBy>>
  }

/** A condition to be used against `Person` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type PersonCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>
  /** Checks for equality with the object’s `deviceIds` field. */
  deviceIds?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Checks for equality with the object’s `firebaseId` field. */
  firebaseId?: Maybe<Scalars['String']>
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>
  /** Checks for equality with the object’s `photoUrl` field. */
  photoUrl?: Maybe<Scalars['String']>
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>
}

/** A connection to a list of `File` values, with data from `Comment`. */
export type PersonFilesByCommentPersonIdAndFileIdManyToManyConnection = {
  __typename?: 'PersonFilesByCommentPersonIdAndFileIdManyToManyConnection'
  /** A list of edges which contains the `File`, info from the `Comment`, and the cursor to aid in pagination. */
  edges: Array<PersonFilesByCommentPersonIdAndFileIdManyToManyEdge>
  /** A list of `File` objects. */
  nodes: Array<Maybe<File>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `File` edge in the connection, with data from `Comment`. */
export type PersonFilesByCommentPersonIdAndFileIdManyToManyEdge = {
  __typename?: 'PersonFilesByCommentPersonIdAndFileIdManyToManyEdge'
  /** Reads and enables pagination through a set of `Comment`. */
  comments: CommentsConnection
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>
  /** The `File` at the end of the edge. */
  node?: Maybe<File>
}

/** A `File` edge in the connection, with data from `Comment`. */
export type PersonFilesByCommentPersonIdAndFileIdManyToManyEdgeCommentsArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<CommentCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<CommentsOrderBy>>
}

/** A connection to a list of `File` values, with data from `File`. */
export type PersonFilesByFilePersonIdAndOriginalFileIdManyToManyConnection = {
  __typename?: 'PersonFilesByFilePersonIdAndOriginalFileIdManyToManyConnection'
  /** A list of edges which contains the `File`, info from the `File`, and the cursor to aid in pagination. */
  edges: Array<PersonFilesByFilePersonIdAndOriginalFileIdManyToManyEdge>
  /** A list of `File` objects. */
  nodes: Array<Maybe<File>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `File` edge in the connection, with data from `File`. */
export type PersonFilesByFilePersonIdAndOriginalFileIdManyToManyEdge = {
  __typename?: 'PersonFilesByFilePersonIdAndOriginalFileIdManyToManyEdge'
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>
  /** Reads and enables pagination through a set of `File`. */
  filesByOriginalFileId: FilesConnection
  /** The `File` at the end of the edge. */
  node?: Maybe<File>
}

/** A `File` edge in the connection, with data from `File`. */
export type PersonFilesByFilePersonIdAndOriginalFileIdManyToManyEdgeFilesByOriginalFileIdArgs =
  {
    after?: Maybe<Scalars['Cursor']>
    before?: Maybe<Scalars['Cursor']>
    condition?: Maybe<FileCondition>
    first?: Maybe<Scalars['Int']>
    last?: Maybe<Scalars['Int']>
    offset?: Maybe<Scalars['Int']>
    orderBy?: Maybe<Array<FilesOrderBy>>
  }

/** A connection to a list of `File` values, with data from `Notification`. */
export type PersonFilesByNotificationPersonIdAndFileIdManyToManyConnection = {
  __typename?: 'PersonFilesByNotificationPersonIdAndFileIdManyToManyConnection'
  /** A list of edges which contains the `File`, info from the `Notification`, and the cursor to aid in pagination. */
  edges: Array<PersonFilesByNotificationPersonIdAndFileIdManyToManyEdge>
  /** A list of `File` objects. */
  nodes: Array<Maybe<File>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `File` edge in the connection, with data from `Notification`. */
export type PersonFilesByNotificationPersonIdAndFileIdManyToManyEdge = {
  __typename?: 'PersonFilesByNotificationPersonIdAndFileIdManyToManyEdge'
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>
  /** The `File` at the end of the edge. */
  node?: Maybe<File>
  /** Reads and enables pagination through a set of `Notification`. */
  notifications: NotificationsConnection
}

/** A `File` edge in the connection, with data from `Notification`. */
export type PersonFilesByNotificationPersonIdAndFileIdManyToManyEdgeNotificationsArgs =
  {
    after?: Maybe<Scalars['Cursor']>
    before?: Maybe<Scalars['Cursor']>
    condition?: Maybe<NotificationCondition>
    first?: Maybe<Scalars['Int']>
    last?: Maybe<Scalars['Int']>
    offset?: Maybe<Scalars['Int']>
    orderBy?: Maybe<Array<NotificationsOrderBy>>
  }

/** A connection to a list of `File` values, with data from `Notification`. */
export type PersonFilesByNotificationReceiverIdAndFileIdManyToManyConnection = {
  __typename?: 'PersonFilesByNotificationReceiverIdAndFileIdManyToManyConnection'
  /** A list of edges which contains the `File`, info from the `Notification`, and the cursor to aid in pagination. */
  edges: Array<PersonFilesByNotificationReceiverIdAndFileIdManyToManyEdge>
  /** A list of `File` objects. */
  nodes: Array<Maybe<File>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `File` edge in the connection, with data from `Notification`. */
export type PersonFilesByNotificationReceiverIdAndFileIdManyToManyEdge = {
  __typename?: 'PersonFilesByNotificationReceiverIdAndFileIdManyToManyEdge'
  commentId?: Maybe<Scalars['UUID']>
  createdAt: Scalars['Datetime']
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>
  id: Scalars['UUID']
  /** The `File` at the end of the edge. */
  node?: Maybe<File>
  organisationId: Scalars['UUID']
  personId?: Maybe<Scalars['UUID']>
  seen?: Maybe<Scalars['Boolean']>
  updatedAt: Scalars['Datetime']
}

/** A connection to a list of `File` values, with data from `Reaction`. */
export type PersonFilesByReactionPersonIdAndFileIdManyToManyConnection = {
  __typename?: 'PersonFilesByReactionPersonIdAndFileIdManyToManyConnection'
  /** A list of edges which contains the `File`, info from the `Reaction`, and the cursor to aid in pagination. */
  edges: Array<PersonFilesByReactionPersonIdAndFileIdManyToManyEdge>
  /** A list of `File` objects. */
  nodes: Array<Maybe<File>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `File` edge in the connection, with data from `Reaction`. */
export type PersonFilesByReactionPersonIdAndFileIdManyToManyEdge = {
  __typename?: 'PersonFilesByReactionPersonIdAndFileIdManyToManyEdge'
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>
  /** The `File` at the end of the edge. */
  node?: Maybe<File>
  /** Reads and enables pagination through a set of `Reaction`. */
  reactions: ReactionsConnection
}

/** A `File` edge in the connection, with data from `Reaction`. */
export type PersonFilesByReactionPersonIdAndFileIdManyToManyEdgeReactionsArgs =
  {
    after?: Maybe<Scalars['Cursor']>
    before?: Maybe<Scalars['Cursor']>
    condition?: Maybe<ReactionCondition>
    first?: Maybe<Scalars['Int']>
    last?: Maybe<Scalars['Int']>
    offset?: Maybe<Scalars['Int']>
    orderBy?: Maybe<Array<ReactionsOrderBy>>
  }

/** An input for mutations affecting `Person` */
export type PersonInput = {
  createdAt?: Maybe<Scalars['Datetime']>
  deviceIds?: Maybe<Array<Maybe<Scalars['String']>>>
  firebaseId: Scalars['String']
  id?: Maybe<Scalars['UUID']>
  name: Scalars['String']
  photoUrl?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['Datetime']>
}

export type PersonOrganisation = Node & {
  __typename?: 'PersonOrganisation'
  createdAt: Scalars['Datetime']
  id: Scalars['UUID']
  isOwner: Scalars['Boolean']
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']
  /** Reads a single `Organisation` that is related to this `PersonOrganisation`. */
  organisation?: Maybe<Organisation>
  organisationId: Scalars['UUID']
  /** Reads a single `Person` that is related to this `PersonOrganisation`. */
  person?: Maybe<Person>
  personId: Scalars['UUID']
  updatedAt: Scalars['Datetime']
}

/**
 * A condition to be used against `PersonOrganisation` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type PersonOrganisationCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>
  /** Checks for equality with the object’s `isOwner` field. */
  isOwner?: Maybe<Scalars['Boolean']>
  /** Checks for equality with the object’s `organisationId` field. */
  organisationId?: Maybe<Scalars['UUID']>
  /** Checks for equality with the object’s `personId` field. */
  personId?: Maybe<Scalars['UUID']>
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>
}

/** An input for mutations affecting `PersonOrganisation` */
export type PersonOrganisationInput = {
  createdAt?: Maybe<Scalars['Datetime']>
  id?: Maybe<Scalars['UUID']>
  isOwner?: Maybe<Scalars['Boolean']>
  organisationId: Scalars['UUID']
  personId: Scalars['UUID']
  updatedAt?: Maybe<Scalars['Datetime']>
}

/** Represents an update to a `PersonOrganisation`. Fields that are set will be updated. */
export type PersonOrganisationPatch = {
  createdAt?: Maybe<Scalars['Datetime']>
  id?: Maybe<Scalars['UUID']>
  isOwner?: Maybe<Scalars['Boolean']>
  organisationId?: Maybe<Scalars['UUID']>
  personId?: Maybe<Scalars['UUID']>
  updatedAt?: Maybe<Scalars['Datetime']>
}

/** A connection to a list of `Organisation` values, with data from `Collection`. */
export type PersonOrganisationsByCollectionPersonIdAndOrganisationIdManyToManyConnection =
  {
    __typename?: 'PersonOrganisationsByCollectionPersonIdAndOrganisationIdManyToManyConnection'
    /** A list of edges which contains the `Organisation`, info from the `Collection`, and the cursor to aid in pagination. */
    edges: Array<PersonOrganisationsByCollectionPersonIdAndOrganisationIdManyToManyEdge>
    /** A list of `Organisation` objects. */
    nodes: Array<Maybe<Organisation>>
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** The count of *all* `Organisation` you could get from the connection. */
    totalCount: Scalars['Int']
  }

/** A `Organisation` edge in the connection, with data from `Collection`. */
export type PersonOrganisationsByCollectionPersonIdAndOrganisationIdManyToManyEdge =
  {
    __typename?: 'PersonOrganisationsByCollectionPersonIdAndOrganisationIdManyToManyEdge'
    /** Reads and enables pagination through a set of `Collection`. */
    collections: CollectionsConnection
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>
    /** The `Organisation` at the end of the edge. */
    node?: Maybe<Organisation>
  }

/** A `Organisation` edge in the connection, with data from `Collection`. */
export type PersonOrganisationsByCollectionPersonIdAndOrganisationIdManyToManyEdgeCollectionsArgs =
  {
    after?: Maybe<Scalars['Cursor']>
    before?: Maybe<Scalars['Cursor']>
    condition?: Maybe<CollectionCondition>
    first?: Maybe<Scalars['Int']>
    last?: Maybe<Scalars['Int']>
    offset?: Maybe<Scalars['Int']>
    orderBy?: Maybe<Array<CollectionsOrderBy>>
  }

/** A connection to a list of `Organisation` values, with data from `Mention`. */
export type PersonOrganisationsByMentionMentionedIdAndOrganisationIdManyToManyConnection =
  {
    __typename?: 'PersonOrganisationsByMentionMentionedIdAndOrganisationIdManyToManyConnection'
    /** A list of edges which contains the `Organisation`, info from the `Mention`, and the cursor to aid in pagination. */
    edges: Array<PersonOrganisationsByMentionMentionedIdAndOrganisationIdManyToManyEdge>
    /** A list of `Organisation` objects. */
    nodes: Array<Maybe<Organisation>>
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** The count of *all* `Organisation` you could get from the connection. */
    totalCount: Scalars['Int']
  }

/** A `Organisation` edge in the connection, with data from `Mention`. */
export type PersonOrganisationsByMentionMentionedIdAndOrganisationIdManyToManyEdge =
  {
    __typename?: 'PersonOrganisationsByMentionMentionedIdAndOrganisationIdManyToManyEdge'
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>
    /** Reads and enables pagination through a set of `Mention`. */
    mentions: MentionsConnection
    /** The `Organisation` at the end of the edge. */
    node?: Maybe<Organisation>
  }

/** A `Organisation` edge in the connection, with data from `Mention`. */
export type PersonOrganisationsByMentionMentionedIdAndOrganisationIdManyToManyEdgeMentionsArgs =
  {
    after?: Maybe<Scalars['Cursor']>
    before?: Maybe<Scalars['Cursor']>
    condition?: Maybe<MentionCondition>
    first?: Maybe<Scalars['Int']>
    last?: Maybe<Scalars['Int']>
    offset?: Maybe<Scalars['Int']>
    orderBy?: Maybe<Array<MentionsOrderBy>>
  }

/** A connection to a list of `Organisation` values, with data from `Mention`. */
export type PersonOrganisationsByMentionPersonIdAndOrganisationIdManyToManyConnection =
  {
    __typename?: 'PersonOrganisationsByMentionPersonIdAndOrganisationIdManyToManyConnection'
    /** A list of edges which contains the `Organisation`, info from the `Mention`, and the cursor to aid in pagination. */
    edges: Array<PersonOrganisationsByMentionPersonIdAndOrganisationIdManyToManyEdge>
    /** A list of `Organisation` objects. */
    nodes: Array<Maybe<Organisation>>
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** The count of *all* `Organisation` you could get from the connection. */
    totalCount: Scalars['Int']
  }

/** A `Organisation` edge in the connection, with data from `Mention`. */
export type PersonOrganisationsByMentionPersonIdAndOrganisationIdManyToManyEdge =
  {
    __typename?: 'PersonOrganisationsByMentionPersonIdAndOrganisationIdManyToManyEdge'
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>
    /** Reads and enables pagination through a set of `Mention`. */
    mentions: MentionsConnection
    /** The `Organisation` at the end of the edge. */
    node?: Maybe<Organisation>
  }

/** A `Organisation` edge in the connection, with data from `Mention`. */
export type PersonOrganisationsByMentionPersonIdAndOrganisationIdManyToManyEdgeMentionsArgs =
  {
    after?: Maybe<Scalars['Cursor']>
    before?: Maybe<Scalars['Cursor']>
    condition?: Maybe<MentionCondition>
    first?: Maybe<Scalars['Int']>
    last?: Maybe<Scalars['Int']>
    offset?: Maybe<Scalars['Int']>
    orderBy?: Maybe<Array<MentionsOrderBy>>
  }

/** A connection to a list of `Organisation` values, with data from `Notification`. */
export type PersonOrganisationsByNotificationPersonIdAndOrganisationIdManyToManyConnection =
  {
    __typename?: 'PersonOrganisationsByNotificationPersonIdAndOrganisationIdManyToManyConnection'
    /** A list of edges which contains the `Organisation`, info from the `Notification`, and the cursor to aid in pagination. */
    edges: Array<PersonOrganisationsByNotificationPersonIdAndOrganisationIdManyToManyEdge>
    /** A list of `Organisation` objects. */
    nodes: Array<Maybe<Organisation>>
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** The count of *all* `Organisation` you could get from the connection. */
    totalCount: Scalars['Int']
  }

/** A `Organisation` edge in the connection, with data from `Notification`. */
export type PersonOrganisationsByNotificationPersonIdAndOrganisationIdManyToManyEdge =
  {
    __typename?: 'PersonOrganisationsByNotificationPersonIdAndOrganisationIdManyToManyEdge'
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>
    /** The `Organisation` at the end of the edge. */
    node?: Maybe<Organisation>
    /** Reads and enables pagination through a set of `Notification`. */
    notifications: NotificationsConnection
  }

/** A `Organisation` edge in the connection, with data from `Notification`. */
export type PersonOrganisationsByNotificationPersonIdAndOrganisationIdManyToManyEdgeNotificationsArgs =
  {
    after?: Maybe<Scalars['Cursor']>
    before?: Maybe<Scalars['Cursor']>
    condition?: Maybe<NotificationCondition>
    first?: Maybe<Scalars['Int']>
    last?: Maybe<Scalars['Int']>
    offset?: Maybe<Scalars['Int']>
    orderBy?: Maybe<Array<NotificationsOrderBy>>
  }

/** A connection to a list of `Organisation` values, with data from `Notification`. */
export type PersonOrganisationsByNotificationReceiverIdAndOrganisationIdManyToManyConnection =
  {
    __typename?: 'PersonOrganisationsByNotificationReceiverIdAndOrganisationIdManyToManyConnection'
    /** A list of edges which contains the `Organisation`, info from the `Notification`, and the cursor to aid in pagination. */
    edges: Array<PersonOrganisationsByNotificationReceiverIdAndOrganisationIdManyToManyEdge>
    /** A list of `Organisation` objects. */
    nodes: Array<Maybe<Organisation>>
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** The count of *all* `Organisation` you could get from the connection. */
    totalCount: Scalars['Int']
  }

/** A `Organisation` edge in the connection, with data from `Notification`. */
export type PersonOrganisationsByNotificationReceiverIdAndOrganisationIdManyToManyEdge =
  {
    __typename?: 'PersonOrganisationsByNotificationReceiverIdAndOrganisationIdManyToManyEdge'
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>
    /** The `Organisation` at the end of the edge. */
    node?: Maybe<Organisation>
    /** Reads and enables pagination through a set of `Notification`. */
    notifications: NotificationsConnection
  }

/** A `Organisation` edge in the connection, with data from `Notification`. */
export type PersonOrganisationsByNotificationReceiverIdAndOrganisationIdManyToManyEdgeNotificationsArgs =
  {
    after?: Maybe<Scalars['Cursor']>
    before?: Maybe<Scalars['Cursor']>
    condition?: Maybe<NotificationCondition>
    first?: Maybe<Scalars['Int']>
    last?: Maybe<Scalars['Int']>
    offset?: Maybe<Scalars['Int']>
    orderBy?: Maybe<Array<NotificationsOrderBy>>
  }

/** A connection to a list of `Organisation` values, with data from `OrganisationInvitation`. */
export type PersonOrganisationsByOrganisationInvitationAcceptedByAndOrganisationIdManyToManyConnection =
  {
    __typename?: 'PersonOrganisationsByOrganisationInvitationAcceptedByAndOrganisationIdManyToManyConnection'
    /** A list of edges which contains the `Organisation`, info from the `OrganisationInvitation`, and the cursor to aid in pagination. */
    edges: Array<PersonOrganisationsByOrganisationInvitationAcceptedByAndOrganisationIdManyToManyEdge>
    /** A list of `Organisation` objects. */
    nodes: Array<Maybe<Organisation>>
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** The count of *all* `Organisation` you could get from the connection. */
    totalCount: Scalars['Int']
  }

/** A `Organisation` edge in the connection, with data from `OrganisationInvitation`. */
export type PersonOrganisationsByOrganisationInvitationAcceptedByAndOrganisationIdManyToManyEdge =
  {
    __typename?: 'PersonOrganisationsByOrganisationInvitationAcceptedByAndOrganisationIdManyToManyEdge'
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>
    /** The `Organisation` at the end of the edge. */
    node?: Maybe<Organisation>
    /** Reads and enables pagination through a set of `OrganisationInvitation`. */
    organisationInvitations: OrganisationInvitationsConnection
  }

/** A `Organisation` edge in the connection, with data from `OrganisationInvitation`. */
export type PersonOrganisationsByOrganisationInvitationAcceptedByAndOrganisationIdManyToManyEdgeOrganisationInvitationsArgs =
  {
    after?: Maybe<Scalars['Cursor']>
    before?: Maybe<Scalars['Cursor']>
    condition?: Maybe<OrganisationInvitationCondition>
    first?: Maybe<Scalars['Int']>
    last?: Maybe<Scalars['Int']>
    offset?: Maybe<Scalars['Int']>
    orderBy?: Maybe<Array<OrganisationInvitationsOrderBy>>
  }

/** A connection to a list of `Organisation` values, with data from `OrganisationInvitation`. */
export type PersonOrganisationsByOrganisationInvitationInvitedByAndOrganisationIdManyToManyConnection =
  {
    __typename?: 'PersonOrganisationsByOrganisationInvitationInvitedByAndOrganisationIdManyToManyConnection'
    /** A list of edges which contains the `Organisation`, info from the `OrganisationInvitation`, and the cursor to aid in pagination. */
    edges: Array<PersonOrganisationsByOrganisationInvitationInvitedByAndOrganisationIdManyToManyEdge>
    /** A list of `Organisation` objects. */
    nodes: Array<Maybe<Organisation>>
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** The count of *all* `Organisation` you could get from the connection. */
    totalCount: Scalars['Int']
  }

/** A `Organisation` edge in the connection, with data from `OrganisationInvitation`. */
export type PersonOrganisationsByOrganisationInvitationInvitedByAndOrganisationIdManyToManyEdge =
  {
    __typename?: 'PersonOrganisationsByOrganisationInvitationInvitedByAndOrganisationIdManyToManyEdge'
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>
    /** The `Organisation` at the end of the edge. */
    node?: Maybe<Organisation>
    /** Reads and enables pagination through a set of `OrganisationInvitation`. */
    organisationInvitations: OrganisationInvitationsConnection
  }

/** A `Organisation` edge in the connection, with data from `OrganisationInvitation`. */
export type PersonOrganisationsByOrganisationInvitationInvitedByAndOrganisationIdManyToManyEdgeOrganisationInvitationsArgs =
  {
    after?: Maybe<Scalars['Cursor']>
    before?: Maybe<Scalars['Cursor']>
    condition?: Maybe<OrganisationInvitationCondition>
    first?: Maybe<Scalars['Int']>
    last?: Maybe<Scalars['Int']>
    offset?: Maybe<Scalars['Int']>
    orderBy?: Maybe<Array<OrganisationInvitationsOrderBy>>
  }

/** A connection to a list of `Organisation` values, with data from `PersonOrganisation`. */
export type PersonOrganisationsByPersonOrganisationPersonIdAndOrganisationIdManyToManyConnection =
  {
    __typename?: 'PersonOrganisationsByPersonOrganisationPersonIdAndOrganisationIdManyToManyConnection'
    /** A list of edges which contains the `Organisation`, info from the `PersonOrganisation`, and the cursor to aid in pagination. */
    edges: Array<PersonOrganisationsByPersonOrganisationPersonIdAndOrganisationIdManyToManyEdge>
    /** A list of `Organisation` objects. */
    nodes: Array<Maybe<Organisation>>
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** The count of *all* `Organisation` you could get from the connection. */
    totalCount: Scalars['Int']
  }

/** A `Organisation` edge in the connection, with data from `PersonOrganisation`. */
export type PersonOrganisationsByPersonOrganisationPersonIdAndOrganisationIdManyToManyEdge =
  {
    __typename?: 'PersonOrganisationsByPersonOrganisationPersonIdAndOrganisationIdManyToManyEdge'
    createdAt: Scalars['Datetime']
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>
    id: Scalars['UUID']
    isOwner: Scalars['Boolean']
    /** The `Organisation` at the end of the edge. */
    node?: Maybe<Organisation>
    updatedAt: Scalars['Datetime']
  }

/** A connection to a list of `Organisation` values, with data from `Project`. */
export type PersonOrganisationsByProjectPersonIdAndOrganisationIdManyToManyConnection =
  {
    __typename?: 'PersonOrganisationsByProjectPersonIdAndOrganisationIdManyToManyConnection'
    /** A list of edges which contains the `Organisation`, info from the `Project`, and the cursor to aid in pagination. */
    edges: Array<PersonOrganisationsByProjectPersonIdAndOrganisationIdManyToManyEdge>
    /** A list of `Organisation` objects. */
    nodes: Array<Maybe<Organisation>>
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** The count of *all* `Organisation` you could get from the connection. */
    totalCount: Scalars['Int']
  }

/** A `Organisation` edge in the connection, with data from `Project`. */
export type PersonOrganisationsByProjectPersonIdAndOrganisationIdManyToManyEdge =
  {
    __typename?: 'PersonOrganisationsByProjectPersonIdAndOrganisationIdManyToManyEdge'
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>
    /** The `Organisation` at the end of the edge. */
    node?: Maybe<Organisation>
    /** Reads and enables pagination through a set of `Project`. */
    projects: ProjectsConnection
  }

/** A `Organisation` edge in the connection, with data from `Project`. */
export type PersonOrganisationsByProjectPersonIdAndOrganisationIdManyToManyEdgeProjectsArgs =
  {
    after?: Maybe<Scalars['Cursor']>
    before?: Maybe<Scalars['Cursor']>
    condition?: Maybe<ProjectCondition>
    first?: Maybe<Scalars['Int']>
    last?: Maybe<Scalars['Int']>
    offset?: Maybe<Scalars['Int']>
    orderBy?: Maybe<Array<ProjectsOrderBy>>
  }

/** A connection to a list of `PersonOrganisation` values. */
export type PersonOrganisationsConnection = {
  __typename?: 'PersonOrganisationsConnection'
  /** A list of edges which contains the `PersonOrganisation` and cursor to aid in pagination. */
  edges: Array<PersonOrganisationsEdge>
  /** A list of `PersonOrganisation` objects. */
  nodes: Array<Maybe<PersonOrganisation>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `PersonOrganisation` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `PersonOrganisation` edge in the connection. */
export type PersonOrganisationsEdge = {
  __typename?: 'PersonOrganisationsEdge'
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>
  /** The `PersonOrganisation` at the end of the edge. */
  node?: Maybe<PersonOrganisation>
}

/** Methods to use when ordering `PersonOrganisation`. */
export enum PersonOrganisationsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsOwnerAsc = 'IS_OWNER_ASC',
  IsOwnerDesc = 'IS_OWNER_DESC',
  Natural = 'NATURAL',
  OrganisationIdAsc = 'ORGANISATION_ID_ASC',
  OrganisationIdDesc = 'ORGANISATION_ID_DESC',
  PersonIdAsc = 'PERSON_ID_ASC',
  PersonIdDesc = 'PERSON_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
}

/** Represents an update to a `Person`. Fields that are set will be updated. */
export type PersonPatch = {
  createdAt?: Maybe<Scalars['Datetime']>
  deviceIds?: Maybe<Array<Maybe<Scalars['String']>>>
  firebaseId?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['UUID']>
  name?: Maybe<Scalars['String']>
  photoUrl?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['Datetime']>
}

/** A connection to a list of `Person` values, with data from `Mention`. */
export type PersonPeopleByMentionMentionedIdAndPersonIdManyToManyConnection = {
  __typename?: 'PersonPeopleByMentionMentionedIdAndPersonIdManyToManyConnection'
  /** A list of edges which contains the `Person`, info from the `Mention`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByMentionMentionedIdAndPersonIdManyToManyEdge>
  /** A list of `Person` objects. */
  nodes: Array<Maybe<Person>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `Person` edge in the connection, with data from `Mention`. */
export type PersonPeopleByMentionMentionedIdAndPersonIdManyToManyEdge = {
  __typename?: 'PersonPeopleByMentionMentionedIdAndPersonIdManyToManyEdge'
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>
  /** Reads and enables pagination through a set of `Mention`. */
  mentions: MentionsConnection
  /** The `Person` at the end of the edge. */
  node?: Maybe<Person>
}

/** A `Person` edge in the connection, with data from `Mention`. */
export type PersonPeopleByMentionMentionedIdAndPersonIdManyToManyEdgeMentionsArgs =
  {
    after?: Maybe<Scalars['Cursor']>
    before?: Maybe<Scalars['Cursor']>
    condition?: Maybe<MentionCondition>
    first?: Maybe<Scalars['Int']>
    last?: Maybe<Scalars['Int']>
    offset?: Maybe<Scalars['Int']>
    orderBy?: Maybe<Array<MentionsOrderBy>>
  }

/** A connection to a list of `Person` values, with data from `Mention`. */
export type PersonPeopleByMentionPersonIdAndMentionedIdManyToManyConnection = {
  __typename?: 'PersonPeopleByMentionPersonIdAndMentionedIdManyToManyConnection'
  /** A list of edges which contains the `Person`, info from the `Mention`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByMentionPersonIdAndMentionedIdManyToManyEdge>
  /** A list of `Person` objects. */
  nodes: Array<Maybe<Person>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `Person` edge in the connection, with data from `Mention`. */
export type PersonPeopleByMentionPersonIdAndMentionedIdManyToManyEdge = {
  __typename?: 'PersonPeopleByMentionPersonIdAndMentionedIdManyToManyEdge'
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>
  /** Reads and enables pagination through a set of `Mention`. */
  mentionsByMentionedId: MentionsConnection
  /** The `Person` at the end of the edge. */
  node?: Maybe<Person>
}

/** A `Person` edge in the connection, with data from `Mention`. */
export type PersonPeopleByMentionPersonIdAndMentionedIdManyToManyEdgeMentionsByMentionedIdArgs =
  {
    after?: Maybe<Scalars['Cursor']>
    before?: Maybe<Scalars['Cursor']>
    condition?: Maybe<MentionCondition>
    first?: Maybe<Scalars['Int']>
    last?: Maybe<Scalars['Int']>
    offset?: Maybe<Scalars['Int']>
    orderBy?: Maybe<Array<MentionsOrderBy>>
  }

/** A connection to a list of `Person` values, with data from `Notification`. */
export type PersonPeopleByNotificationPersonIdAndReceiverIdManyToManyConnection =
  {
    __typename?: 'PersonPeopleByNotificationPersonIdAndReceiverIdManyToManyConnection'
    /** A list of edges which contains the `Person`, info from the `Notification`, and the cursor to aid in pagination. */
    edges: Array<PersonPeopleByNotificationPersonIdAndReceiverIdManyToManyEdge>
    /** A list of `Person` objects. */
    nodes: Array<Maybe<Person>>
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** The count of *all* `Person` you could get from the connection. */
    totalCount: Scalars['Int']
  }

/** A `Person` edge in the connection, with data from `Notification`. */
export type PersonPeopleByNotificationPersonIdAndReceiverIdManyToManyEdge = {
  __typename?: 'PersonPeopleByNotificationPersonIdAndReceiverIdManyToManyEdge'
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>
  /** The `Person` at the end of the edge. */
  node?: Maybe<Person>
  /** Reads and enables pagination through a set of `Notification`. */
  notificationsByReceiverId: NotificationsConnection
}

/** A `Person` edge in the connection, with data from `Notification`. */
export type PersonPeopleByNotificationPersonIdAndReceiverIdManyToManyEdgeNotificationsByReceiverIdArgs =
  {
    after?: Maybe<Scalars['Cursor']>
    before?: Maybe<Scalars['Cursor']>
    condition?: Maybe<NotificationCondition>
    first?: Maybe<Scalars['Int']>
    last?: Maybe<Scalars['Int']>
    offset?: Maybe<Scalars['Int']>
    orderBy?: Maybe<Array<NotificationsOrderBy>>
  }

/** A connection to a list of `Person` values, with data from `Notification`. */
export type PersonPeopleByNotificationReceiverIdAndPersonIdManyToManyConnection =
  {
    __typename?: 'PersonPeopleByNotificationReceiverIdAndPersonIdManyToManyConnection'
    /** A list of edges which contains the `Person`, info from the `Notification`, and the cursor to aid in pagination. */
    edges: Array<PersonPeopleByNotificationReceiverIdAndPersonIdManyToManyEdge>
    /** A list of `Person` objects. */
    nodes: Array<Maybe<Person>>
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** The count of *all* `Person` you could get from the connection. */
    totalCount: Scalars['Int']
  }

/** A `Person` edge in the connection, with data from `Notification`. */
export type PersonPeopleByNotificationReceiverIdAndPersonIdManyToManyEdge = {
  __typename?: 'PersonPeopleByNotificationReceiverIdAndPersonIdManyToManyEdge'
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>
  /** The `Person` at the end of the edge. */
  node?: Maybe<Person>
  /** Reads and enables pagination through a set of `Notification`. */
  notifications: NotificationsConnection
}

/** A `Person` edge in the connection, with data from `Notification`. */
export type PersonPeopleByNotificationReceiverIdAndPersonIdManyToManyEdgeNotificationsArgs =
  {
    after?: Maybe<Scalars['Cursor']>
    before?: Maybe<Scalars['Cursor']>
    condition?: Maybe<NotificationCondition>
    first?: Maybe<Scalars['Int']>
    last?: Maybe<Scalars['Int']>
    offset?: Maybe<Scalars['Int']>
    orderBy?: Maybe<Array<NotificationsOrderBy>>
  }

/** A connection to a list of `Person` values, with data from `OrganisationInvitation`. */
export type PersonPeopleByOrganisationInvitationAcceptedByAndInvitedByManyToManyConnection =
  {
    __typename?: 'PersonPeopleByOrganisationInvitationAcceptedByAndInvitedByManyToManyConnection'
    /** A list of edges which contains the `Person`, info from the `OrganisationInvitation`, and the cursor to aid in pagination. */
    edges: Array<PersonPeopleByOrganisationInvitationAcceptedByAndInvitedByManyToManyEdge>
    /** A list of `Person` objects. */
    nodes: Array<Maybe<Person>>
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** The count of *all* `Person` you could get from the connection. */
    totalCount: Scalars['Int']
  }

/** A `Person` edge in the connection, with data from `OrganisationInvitation`. */
export type PersonPeopleByOrganisationInvitationAcceptedByAndInvitedByManyToManyEdge =
  {
    __typename?: 'PersonPeopleByOrganisationInvitationAcceptedByAndInvitedByManyToManyEdge'
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>
    /** The `Person` at the end of the edge. */
    node?: Maybe<Person>
    /** Reads and enables pagination through a set of `OrganisationInvitation`. */
    organisationInvitationsByInvitedBy: OrganisationInvitationsConnection
  }

/** A `Person` edge in the connection, with data from `OrganisationInvitation`. */
export type PersonPeopleByOrganisationInvitationAcceptedByAndInvitedByManyToManyEdgeOrganisationInvitationsByInvitedByArgs =
  {
    after?: Maybe<Scalars['Cursor']>
    before?: Maybe<Scalars['Cursor']>
    condition?: Maybe<OrganisationInvitationCondition>
    first?: Maybe<Scalars['Int']>
    last?: Maybe<Scalars['Int']>
    offset?: Maybe<Scalars['Int']>
    orderBy?: Maybe<Array<OrganisationInvitationsOrderBy>>
  }

/** A connection to a list of `Person` values, with data from `OrganisationInvitation`. */
export type PersonPeopleByOrganisationInvitationInvitedByAndAcceptedByManyToManyConnection =
  {
    __typename?: 'PersonPeopleByOrganisationInvitationInvitedByAndAcceptedByManyToManyConnection'
    /** A list of edges which contains the `Person`, info from the `OrganisationInvitation`, and the cursor to aid in pagination. */
    edges: Array<PersonPeopleByOrganisationInvitationInvitedByAndAcceptedByManyToManyEdge>
    /** A list of `Person` objects. */
    nodes: Array<Maybe<Person>>
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** The count of *all* `Person` you could get from the connection. */
    totalCount: Scalars['Int']
  }

/** A `Person` edge in the connection, with data from `OrganisationInvitation`. */
export type PersonPeopleByOrganisationInvitationInvitedByAndAcceptedByManyToManyEdge =
  {
    __typename?: 'PersonPeopleByOrganisationInvitationInvitedByAndAcceptedByManyToManyEdge'
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>
    /** The `Person` at the end of the edge. */
    node?: Maybe<Person>
    /** Reads and enables pagination through a set of `OrganisationInvitation`. */
    organisationInvitationsByAcceptedBy: OrganisationInvitationsConnection
  }

/** A `Person` edge in the connection, with data from `OrganisationInvitation`. */
export type PersonPeopleByOrganisationInvitationInvitedByAndAcceptedByManyToManyEdgeOrganisationInvitationsByAcceptedByArgs =
  {
    after?: Maybe<Scalars['Cursor']>
    before?: Maybe<Scalars['Cursor']>
    condition?: Maybe<OrganisationInvitationCondition>
    first?: Maybe<Scalars['Int']>
    last?: Maybe<Scalars['Int']>
    offset?: Maybe<Scalars['Int']>
    orderBy?: Maybe<Array<OrganisationInvitationsOrderBy>>
  }

export type PersonProject = Node & {
  __typename?: 'PersonProject'
  createdAt: Scalars['Datetime']
  id: Scalars['UUID']
  isOwner: Scalars['Boolean']
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']
  /** Reads a single `Person` that is related to this `PersonProject`. */
  person?: Maybe<Person>
  personId?: Maybe<Scalars['UUID']>
  /** Reads a single `Project` that is related to this `PersonProject`. */
  project?: Maybe<Project>
  projectId?: Maybe<Scalars['UUID']>
  updatedAt: Scalars['Datetime']
}

/**
 * A condition to be used against `PersonProject` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type PersonProjectCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>
  /** Checks for equality with the object’s `isOwner` field. */
  isOwner?: Maybe<Scalars['Boolean']>
  /** Checks for equality with the object’s `personId` field. */
  personId?: Maybe<Scalars['UUID']>
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: Maybe<Scalars['UUID']>
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>
}

/** An input for mutations affecting `PersonProject` */
export type PersonProjectInput = {
  createdAt?: Maybe<Scalars['Datetime']>
  id?: Maybe<Scalars['UUID']>
  isOwner?: Maybe<Scalars['Boolean']>
  personId?: Maybe<Scalars['UUID']>
  projectId?: Maybe<Scalars['UUID']>
  updatedAt?: Maybe<Scalars['Datetime']>
}

/** Represents an update to a `PersonProject`. Fields that are set will be updated. */
export type PersonProjectPatch = {
  createdAt?: Maybe<Scalars['Datetime']>
  id?: Maybe<Scalars['UUID']>
  isOwner?: Maybe<Scalars['Boolean']>
  personId?: Maybe<Scalars['UUID']>
  projectId?: Maybe<Scalars['UUID']>
  updatedAt?: Maybe<Scalars['Datetime']>
}

/** A connection to a list of `Project` values, with data from `File`. */
export type PersonProjectsByFilePersonIdAndProjectIdManyToManyConnection = {
  __typename?: 'PersonProjectsByFilePersonIdAndProjectIdManyToManyConnection'
  /** A list of edges which contains the `Project`, info from the `File`, and the cursor to aid in pagination. */
  edges: Array<PersonProjectsByFilePersonIdAndProjectIdManyToManyEdge>
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `Project` edge in the connection, with data from `File`. */
export type PersonProjectsByFilePersonIdAndProjectIdManyToManyEdge = {
  __typename?: 'PersonProjectsByFilePersonIdAndProjectIdManyToManyEdge'
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>
  /** Reads and enables pagination through a set of `File`. */
  files: FilesConnection
  /** The `Project` at the end of the edge. */
  node?: Maybe<Project>
}

/** A `Project` edge in the connection, with data from `File`. */
export type PersonProjectsByFilePersonIdAndProjectIdManyToManyEdgeFilesArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<FileCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<FilesOrderBy>>
}

/** A connection to a list of `Project` values, with data from `PersonProject`. */
export type PersonProjectsByPersonProjectPersonIdAndProjectIdManyToManyConnection =
  {
    __typename?: 'PersonProjectsByPersonProjectPersonIdAndProjectIdManyToManyConnection'
    /** A list of edges which contains the `Project`, info from the `PersonProject`, and the cursor to aid in pagination. */
    edges: Array<PersonProjectsByPersonProjectPersonIdAndProjectIdManyToManyEdge>
    /** A list of `Project` objects. */
    nodes: Array<Maybe<Project>>
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** The count of *all* `Project` you could get from the connection. */
    totalCount: Scalars['Int']
  }

/** A `Project` edge in the connection, with data from `PersonProject`. */
export type PersonProjectsByPersonProjectPersonIdAndProjectIdManyToManyEdge = {
  __typename?: 'PersonProjectsByPersonProjectPersonIdAndProjectIdManyToManyEdge'
  createdAt: Scalars['Datetime']
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>
  id: Scalars['UUID']
  isOwner: Scalars['Boolean']
  /** The `Project` at the end of the edge. */
  node?: Maybe<Project>
  updatedAt: Scalars['Datetime']
}

/** A connection to a list of `PersonProject` values. */
export type PersonProjectsConnection = {
  __typename?: 'PersonProjectsConnection'
  /** A list of edges which contains the `PersonProject` and cursor to aid in pagination. */
  edges: Array<PersonProjectsEdge>
  /** A list of `PersonProject` objects. */
  nodes: Array<Maybe<PersonProject>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `PersonProject` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `PersonProject` edge in the connection. */
export type PersonProjectsEdge = {
  __typename?: 'PersonProjectsEdge'
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>
  /** The `PersonProject` at the end of the edge. */
  node?: Maybe<PersonProject>
}

/** Methods to use when ordering `PersonProject`. */
export enum PersonProjectsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsOwnerAsc = 'IS_OWNER_ASC',
  IsOwnerDesc = 'IS_OWNER_DESC',
  Natural = 'NATURAL',
  PersonIdAsc = 'PERSON_ID_ASC',
  PersonIdDesc = 'PERSON_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
}

export type Project = Node & {
  __typename?: 'Project'
  createdAt: Scalars['Datetime']
  description?: Maybe<Scalars['String']>
  /** Reads and enables pagination through a set of `File`. */
  files: FilesConnection
  /** Reads and enables pagination through a set of `File`. */
  filesByFileProjectIdAndOriginalFileId: ProjectFilesByFileProjectIdAndOriginalFileIdManyToManyConnection
  icon?: Maybe<Scalars['String']>
  id: Scalars['UUID']
  name: Scalars['String']
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']
  /** Reads a single `Organisation` that is related to this `Project`. */
  organisation?: Maybe<Organisation>
  organisationId: Scalars['UUID']
  /** Reads and enables pagination through a set of `Person`. */
  peopleByFileProjectIdAndPersonId: ProjectPeopleByFileProjectIdAndPersonIdManyToManyConnection
  /** Reads and enables pagination through a set of `Person`. */
  peopleByPersonProjectProjectIdAndPersonId: ProjectPeopleByPersonProjectProjectIdAndPersonIdManyToManyConnection
  /** Reads a single `Person` that is related to this `Project`. */
  person?: Maybe<Person>
  personId?: Maybe<Scalars['UUID']>
  /** Reads and enables pagination through a set of `PersonProject`. */
  personProjects: PersonProjectsConnection
  tintColor?: Maybe<Scalars['String']>
  type?: Maybe<ProjectType>
  updatedAt: Scalars['Datetime']
}

export type ProjectFilesArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<FileCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<FilesOrderBy>>
}

export type ProjectFilesByFileProjectIdAndOriginalFileIdArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<FileCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<FilesOrderBy>>
}

export type ProjectPeopleByFileProjectIdAndPersonIdArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<PersonCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<PeopleOrderBy>>
}

export type ProjectPeopleByPersonProjectProjectIdAndPersonIdArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<PersonCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<PeopleOrderBy>>
}

export type ProjectPersonProjectsArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<PersonProjectCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<PersonProjectsOrderBy>>
}

/** A condition to be used against `Project` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type ProjectCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>
  /** Checks for equality with the object’s `description` field. */
  description?: Maybe<Scalars['String']>
  /** Checks for equality with the object’s `icon` field. */
  icon?: Maybe<Scalars['String']>
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>
  /** Checks for equality with the object’s `organisationId` field. */
  organisationId?: Maybe<Scalars['UUID']>
  /** Checks for equality with the object’s `personId` field. */
  personId?: Maybe<Scalars['UUID']>
  /** Checks for equality with the object’s `tintColor` field. */
  tintColor?: Maybe<Scalars['String']>
  /** Checks for equality with the object’s `type` field. */
  type?: Maybe<ProjectType>
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>
}

/** A connection to a list of `File` values, with data from `File`. */
export type ProjectFilesByFileProjectIdAndOriginalFileIdManyToManyConnection = {
  __typename?: 'ProjectFilesByFileProjectIdAndOriginalFileIdManyToManyConnection'
  /** A list of edges which contains the `File`, info from the `File`, and the cursor to aid in pagination. */
  edges: Array<ProjectFilesByFileProjectIdAndOriginalFileIdManyToManyEdge>
  /** A list of `File` objects. */
  nodes: Array<Maybe<File>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `File` edge in the connection, with data from `File`. */
export type ProjectFilesByFileProjectIdAndOriginalFileIdManyToManyEdge = {
  __typename?: 'ProjectFilesByFileProjectIdAndOriginalFileIdManyToManyEdge'
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>
  /** Reads and enables pagination through a set of `File`. */
  filesByOriginalFileId: FilesConnection
  /** The `File` at the end of the edge. */
  node?: Maybe<File>
}

/** A `File` edge in the connection, with data from `File`. */
export type ProjectFilesByFileProjectIdAndOriginalFileIdManyToManyEdgeFilesByOriginalFileIdArgs =
  {
    after?: Maybe<Scalars['Cursor']>
    before?: Maybe<Scalars['Cursor']>
    condition?: Maybe<FileCondition>
    first?: Maybe<Scalars['Int']>
    last?: Maybe<Scalars['Int']>
    offset?: Maybe<Scalars['Int']>
    orderBy?: Maybe<Array<FilesOrderBy>>
  }

/** An input for mutations affecting `Project` */
export type ProjectInput = {
  createdAt?: Maybe<Scalars['Datetime']>
  description?: Maybe<Scalars['String']>
  icon?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['UUID']>
  name: Scalars['String']
  organisationId: Scalars['UUID']
  personId?: Maybe<Scalars['UUID']>
  tintColor?: Maybe<Scalars['String']>
  type?: Maybe<ProjectType>
  updatedAt?: Maybe<Scalars['Datetime']>
}

/** Represents an update to a `Project`. Fields that are set will be updated. */
export type ProjectPatch = {
  createdAt?: Maybe<Scalars['Datetime']>
  description?: Maybe<Scalars['String']>
  icon?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['UUID']>
  name?: Maybe<Scalars['String']>
  organisationId?: Maybe<Scalars['UUID']>
  personId?: Maybe<Scalars['UUID']>
  tintColor?: Maybe<Scalars['String']>
  type?: Maybe<ProjectType>
  updatedAt?: Maybe<Scalars['Datetime']>
}

/** A connection to a list of `Person` values, with data from `File`. */
export type ProjectPeopleByFileProjectIdAndPersonIdManyToManyConnection = {
  __typename?: 'ProjectPeopleByFileProjectIdAndPersonIdManyToManyConnection'
  /** A list of edges which contains the `Person`, info from the `File`, and the cursor to aid in pagination. */
  edges: Array<ProjectPeopleByFileProjectIdAndPersonIdManyToManyEdge>
  /** A list of `Person` objects. */
  nodes: Array<Maybe<Person>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `Person` edge in the connection, with data from `File`. */
export type ProjectPeopleByFileProjectIdAndPersonIdManyToManyEdge = {
  __typename?: 'ProjectPeopleByFileProjectIdAndPersonIdManyToManyEdge'
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>
  /** Reads and enables pagination through a set of `File`. */
  files: FilesConnection
  /** The `Person` at the end of the edge. */
  node?: Maybe<Person>
}

/** A `Person` edge in the connection, with data from `File`. */
export type ProjectPeopleByFileProjectIdAndPersonIdManyToManyEdgeFilesArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<FileCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<FilesOrderBy>>
}

/** A connection to a list of `Person` values, with data from `PersonProject`. */
export type ProjectPeopleByPersonProjectProjectIdAndPersonIdManyToManyConnection =
  {
    __typename?: 'ProjectPeopleByPersonProjectProjectIdAndPersonIdManyToManyConnection'
    /** A list of edges which contains the `Person`, info from the `PersonProject`, and the cursor to aid in pagination. */
    edges: Array<ProjectPeopleByPersonProjectProjectIdAndPersonIdManyToManyEdge>
    /** A list of `Person` objects. */
    nodes: Array<Maybe<Person>>
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** The count of *all* `Person` you could get from the connection. */
    totalCount: Scalars['Int']
  }

/** A `Person` edge in the connection, with data from `PersonProject`. */
export type ProjectPeopleByPersonProjectProjectIdAndPersonIdManyToManyEdge = {
  __typename?: 'ProjectPeopleByPersonProjectProjectIdAndPersonIdManyToManyEdge'
  createdAt: Scalars['Datetime']
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>
  id: Scalars['UUID']
  isOwner: Scalars['Boolean']
  /** The `Person` at the end of the edge. */
  node?: Maybe<Person>
  updatedAt: Scalars['Datetime']
}

export enum ProjectType {
  Personal = 'PERSONAL',
  Private = 'PRIVATE',
  Public = 'PUBLIC',
}

/** A connection to a list of `Project` values. */
export type ProjectsConnection = {
  __typename?: 'ProjectsConnection'
  /** A list of edges which contains the `Project` and cursor to aid in pagination. */
  edges: Array<ProjectsEdge>
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `Project` edge in the connection. */
export type ProjectsEdge = {
  __typename?: 'ProjectsEdge'
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>
  /** The `Project` at the end of the edge. */
  node?: Maybe<Project>
}

/** Methods to use when ordering `Project`. */
export enum ProjectsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  IconAsc = 'ICON_ASC',
  IconDesc = 'ICON_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  OrganisationIdAsc = 'ORGANISATION_ID_ASC',
  OrganisationIdDesc = 'ORGANISATION_ID_DESC',
  PersonIdAsc = 'PERSON_ID_ASC',
  PersonIdDesc = 'PERSON_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TintColorAsc = 'TINT_COLOR_ASC',
  TintColorDesc = 'TINT_COLOR_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
}

/** The root query type which gives access points into the data universe. */
export type Query = Node & {
  __typename?: 'Query'
  collection?: Maybe<Collection>
  /** Reads a single `Collection` using its globally unique `ID`. */
  collectionByNodeId?: Maybe<Collection>
  /** Reads and enables pagination through a set of `Collection`. */
  collections?: Maybe<CollectionsConnection>
  comment?: Maybe<Comment>
  /** Reads a single `Comment` using its globally unique `ID`. */
  commentByNodeId?: Maybe<Comment>
  /** Reads and enables pagination through a set of `Comment`. */
  comments?: Maybe<CommentsConnection>
  file?: Maybe<File>
  /** Reads a single `File` using its globally unique `ID`. */
  fileByNodeId?: Maybe<File>
  fileCollection?: Maybe<FileCollection>
  fileCollectionByCollectionIdAndFileId?: Maybe<FileCollection>
  /** Reads a single `FileCollection` using its globally unique `ID`. */
  fileCollectionByNodeId?: Maybe<FileCollection>
  /** Reads and enables pagination through a set of `FileCollection`. */
  fileCollections?: Maybe<FileCollectionsConnection>
  /** Reads and enables pagination through a set of `File`. */
  files?: Maybe<FilesConnection>
  getCurrentPerson?: Maybe<GetCurrentPersonConnection>
  /** Reads and enables pagination through a set of `File`. */
  getFilesInOrganisation?: Maybe<FilesConnection>
  mention?: Maybe<Mention>
  mentionByMentionedIdAndCommentId?: Maybe<Mention>
  /** Reads a single `Mention` using its globally unique `ID`. */
  mentionByNodeId?: Maybe<Mention>
  /** Reads and enables pagination through a set of `Mention`. */
  mentions?: Maybe<MentionsConnection>
  /** Fetches an object given its globally unique `ID`. */
  node?: Maybe<Node>
  /** The root query type must be a `Node` to work well with Relay 1 mutations. This just resolves to `query`. */
  nodeId: Scalars['ID']
  notification?: Maybe<Notification>
  /** Reads a single `Notification` using its globally unique `ID`. */
  notificationByNodeId?: Maybe<Notification>
  notificationByReceiverIdAndCommentId?: Maybe<Notification>
  notificationByReceiverIdAndFileId?: Maybe<Notification>
  /** Reads and enables pagination through a set of `Notification`. */
  notifications?: Maybe<NotificationsConnection>
  organisation?: Maybe<Organisation>
  /** Reads a single `Organisation` using its globally unique `ID`. */
  organisationByNodeId?: Maybe<Organisation>
  organisationInvitation?: Maybe<OrganisationInvitation>
  /** Reads a single `OrganisationInvitation` using its globally unique `ID`. */
  organisationInvitationByNodeId?: Maybe<OrganisationInvitation>
  /** Reads and enables pagination through a set of `OrganisationInvitation`. */
  organisationInvitations?: Maybe<OrganisationInvitationsConnection>
  /** Reads and enables pagination through a set of `Organisation`. */
  organisations?: Maybe<OrganisationsConnection>
  /** Reads and enables pagination through a set of `Person`. */
  people?: Maybe<PeopleConnection>
  person?: Maybe<Person>
  personByFirebaseId?: Maybe<Person>
  /** Reads a single `Person` using its globally unique `ID`. */
  personByNodeId?: Maybe<Person>
  personOrganisation?: Maybe<PersonOrganisation>
  /** Reads a single `PersonOrganisation` using its globally unique `ID`. */
  personOrganisationByNodeId?: Maybe<PersonOrganisation>
  personOrganisationByOrganisationIdAndPersonId?: Maybe<PersonOrganisation>
  /** Reads and enables pagination through a set of `PersonOrganisation`. */
  personOrganisations?: Maybe<PersonOrganisationsConnection>
  personProject?: Maybe<PersonProject>
  /** Reads a single `PersonProject` using its globally unique `ID`. */
  personProjectByNodeId?: Maybe<PersonProject>
  personProjectByPersonIdAndProjectId?: Maybe<PersonProject>
  /** Reads and enables pagination through a set of `PersonProject`. */
  personProjects?: Maybe<PersonProjectsConnection>
  project?: Maybe<Project>
  /** Reads a single `Project` using its globally unique `ID`. */
  projectByNodeId?: Maybe<Project>
  /** Reads and enables pagination through a set of `Project`. */
  projects?: Maybe<ProjectsConnection>
  /**
   * Exposes the root query type nested one level down. This is helpful for Relay 1
   * which can only query top level fields if they are in a particular form.
   */
  query: Query
  reaction?: Maybe<Reaction>
  /** Reads a single `Reaction` using its globally unique `ID`. */
  reactionByNodeId?: Maybe<Reaction>
  /** Reads and enables pagination through a set of `Reaction`. */
  reactions?: Maybe<ReactionsConnection>
}

/** The root query type which gives access points into the data universe. */
export type QueryCollectionArgs = {
  id: Scalars['UUID']
}

/** The root query type which gives access points into the data universe. */
export type QueryCollectionByNodeIdArgs = {
  nodeId: Scalars['ID']
}

/** The root query type which gives access points into the data universe. */
export type QueryCollectionsArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<CollectionCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<CollectionsOrderBy>>
}

/** The root query type which gives access points into the data universe. */
export type QueryCommentArgs = {
  id: Scalars['UUID']
}

/** The root query type which gives access points into the data universe. */
export type QueryCommentByNodeIdArgs = {
  nodeId: Scalars['ID']
}

/** The root query type which gives access points into the data universe. */
export type QueryCommentsArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<CommentCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<CommentsOrderBy>>
}

/** The root query type which gives access points into the data universe. */
export type QueryFileArgs = {
  id: Scalars['UUID']
}

/** The root query type which gives access points into the data universe. */
export type QueryFileByNodeIdArgs = {
  nodeId: Scalars['ID']
}

/** The root query type which gives access points into the data universe. */
export type QueryFileCollectionArgs = {
  id: Scalars['UUID']
}

/** The root query type which gives access points into the data universe. */
export type QueryFileCollectionByCollectionIdAndFileIdArgs = {
  collectionId: Scalars['UUID']
  fileId: Scalars['UUID']
}

/** The root query type which gives access points into the data universe. */
export type QueryFileCollectionByNodeIdArgs = {
  nodeId: Scalars['ID']
}

/** The root query type which gives access points into the data universe. */
export type QueryFileCollectionsArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<FileCollectionCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<FileCollectionsOrderBy>>
}

/** The root query type which gives access points into the data universe. */
export type QueryFilesArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<FileCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<FilesOrderBy>>
}

/** The root query type which gives access points into the data universe. */
export type QueryGetCurrentPersonArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
}

/** The root query type which gives access points into the data universe. */
export type QueryGetFilesInOrganisationArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<FileCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<FilesOrderBy>>
  organisationId?: Maybe<Scalars['UUID']>
  projectId?: Maybe<Scalars['UUID']>
}

/** The root query type which gives access points into the data universe. */
export type QueryMentionArgs = {
  id: Scalars['UUID']
}

/** The root query type which gives access points into the data universe. */
export type QueryMentionByMentionedIdAndCommentIdArgs = {
  commentId: Scalars['UUID']
  mentionedId: Scalars['UUID']
}

/** The root query type which gives access points into the data universe. */
export type QueryMentionByNodeIdArgs = {
  nodeId: Scalars['ID']
}

/** The root query type which gives access points into the data universe. */
export type QueryMentionsArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<MentionCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<MentionsOrderBy>>
}

/** The root query type which gives access points into the data universe. */
export type QueryNodeArgs = {
  nodeId: Scalars['ID']
}

/** The root query type which gives access points into the data universe. */
export type QueryNotificationArgs = {
  id: Scalars['UUID']
}

/** The root query type which gives access points into the data universe. */
export type QueryNotificationByNodeIdArgs = {
  nodeId: Scalars['ID']
}

/** The root query type which gives access points into the data universe. */
export type QueryNotificationByReceiverIdAndCommentIdArgs = {
  commentId: Scalars['UUID']
  receiverId: Scalars['UUID']
}

/** The root query type which gives access points into the data universe. */
export type QueryNotificationByReceiverIdAndFileIdArgs = {
  fileId: Scalars['UUID']
  receiverId: Scalars['UUID']
}

/** The root query type which gives access points into the data universe. */
export type QueryNotificationsArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<NotificationCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<NotificationsOrderBy>>
}

/** The root query type which gives access points into the data universe. */
export type QueryOrganisationArgs = {
  id: Scalars['UUID']
}

/** The root query type which gives access points into the data universe. */
export type QueryOrganisationByNodeIdArgs = {
  nodeId: Scalars['ID']
}

/** The root query type which gives access points into the data universe. */
export type QueryOrganisationInvitationArgs = {
  id: Scalars['UUID']
}

/** The root query type which gives access points into the data universe. */
export type QueryOrganisationInvitationByNodeIdArgs = {
  nodeId: Scalars['ID']
}

/** The root query type which gives access points into the data universe. */
export type QueryOrganisationInvitationsArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<OrganisationInvitationCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<OrganisationInvitationsOrderBy>>
}

/** The root query type which gives access points into the data universe. */
export type QueryOrganisationsArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<OrganisationCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<OrganisationsOrderBy>>
}

/** The root query type which gives access points into the data universe. */
export type QueryPeopleArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<PersonCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<PeopleOrderBy>>
}

/** The root query type which gives access points into the data universe. */
export type QueryPersonArgs = {
  id: Scalars['UUID']
}

/** The root query type which gives access points into the data universe. */
export type QueryPersonByFirebaseIdArgs = {
  firebaseId: Scalars['String']
}

/** The root query type which gives access points into the data universe. */
export type QueryPersonByNodeIdArgs = {
  nodeId: Scalars['ID']
}

/** The root query type which gives access points into the data universe. */
export type QueryPersonOrganisationArgs = {
  id: Scalars['UUID']
}

/** The root query type which gives access points into the data universe. */
export type QueryPersonOrganisationByNodeIdArgs = {
  nodeId: Scalars['ID']
}

/** The root query type which gives access points into the data universe. */
export type QueryPersonOrganisationByOrganisationIdAndPersonIdArgs = {
  organisationId: Scalars['UUID']
  personId: Scalars['UUID']
}

/** The root query type which gives access points into the data universe. */
export type QueryPersonOrganisationsArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<PersonOrganisationCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<PersonOrganisationsOrderBy>>
}

/** The root query type which gives access points into the data universe. */
export type QueryPersonProjectArgs = {
  id: Scalars['UUID']
}

/** The root query type which gives access points into the data universe. */
export type QueryPersonProjectByNodeIdArgs = {
  nodeId: Scalars['ID']
}

/** The root query type which gives access points into the data universe. */
export type QueryPersonProjectByPersonIdAndProjectIdArgs = {
  personId: Scalars['UUID']
  projectId: Scalars['UUID']
}

/** The root query type which gives access points into the data universe. */
export type QueryPersonProjectsArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<PersonProjectCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<PersonProjectsOrderBy>>
}

/** The root query type which gives access points into the data universe. */
export type QueryProjectArgs = {
  id: Scalars['UUID']
}

/** The root query type which gives access points into the data universe. */
export type QueryProjectByNodeIdArgs = {
  nodeId: Scalars['ID']
}

/** The root query type which gives access points into the data universe. */
export type QueryProjectsArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<ProjectCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<ProjectsOrderBy>>
}

/** The root query type which gives access points into the data universe. */
export type QueryReactionArgs = {
  id: Scalars['UUID']
}

/** The root query type which gives access points into the data universe. */
export type QueryReactionByNodeIdArgs = {
  nodeId: Scalars['ID']
}

/** The root query type which gives access points into the data universe. */
export type QueryReactionsArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  condition?: Maybe<ReactionCondition>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<ReactionsOrderBy>>
}

export type Reaction = Node & {
  __typename?: 'Reaction'
  /** Reads a single `Comment` that is related to this `Reaction`. */
  comment?: Maybe<Comment>
  commentId?: Maybe<Scalars['UUID']>
  createdAt: Scalars['Datetime']
  emoji?: Maybe<Scalars['String']>
  /** Reads a single `File` that is related to this `Reaction`. */
  file?: Maybe<File>
  fileId?: Maybe<Scalars['UUID']>
  id: Scalars['UUID']
  native?: Maybe<Scalars['String']>
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']
  /** Reads a single `Person` that is related to this `Reaction`. */
  person?: Maybe<Person>
  personId?: Maybe<Scalars['UUID']>
  updatedAt: Scalars['Datetime']
}

/**
 * A condition to be used against `Reaction` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type ReactionCondition = {
  /** Checks for equality with the object’s `commentId` field. */
  commentId?: Maybe<Scalars['UUID']>
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>
  /** Checks for equality with the object’s `emoji` field. */
  emoji?: Maybe<Scalars['String']>
  /** Checks for equality with the object’s `fileId` field. */
  fileId?: Maybe<Scalars['UUID']>
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>
  /** Checks for equality with the object’s `native` field. */
  native?: Maybe<Scalars['String']>
  /** Checks for equality with the object’s `personId` field. */
  personId?: Maybe<Scalars['UUID']>
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>
}

/** An input for mutations affecting `Reaction` */
export type ReactionInput = {
  commentId?: Maybe<Scalars['UUID']>
  createdAt?: Maybe<Scalars['Datetime']>
  emoji?: Maybe<Scalars['String']>
  fileId?: Maybe<Scalars['UUID']>
  id?: Maybe<Scalars['UUID']>
  native?: Maybe<Scalars['String']>
  personId?: Maybe<Scalars['UUID']>
  updatedAt?: Maybe<Scalars['Datetime']>
}

/** Represents an update to a `Reaction`. Fields that are set will be updated. */
export type ReactionPatch = {
  commentId?: Maybe<Scalars['UUID']>
  createdAt?: Maybe<Scalars['Datetime']>
  emoji?: Maybe<Scalars['String']>
  fileId?: Maybe<Scalars['UUID']>
  id?: Maybe<Scalars['UUID']>
  native?: Maybe<Scalars['String']>
  personId?: Maybe<Scalars['UUID']>
  updatedAt?: Maybe<Scalars['Datetime']>
}

/** A connection to a list of `Reaction` values. */
export type ReactionsConnection = {
  __typename?: 'ReactionsConnection'
  /** A list of edges which contains the `Reaction` and cursor to aid in pagination. */
  edges: Array<ReactionsEdge>
  /** A list of `Reaction` objects. */
  nodes: Array<Maybe<Reaction>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `Reaction` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `Reaction` edge in the connection. */
export type ReactionsEdge = {
  __typename?: 'ReactionsEdge'
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>
  /** The `Reaction` at the end of the edge. */
  node?: Maybe<Reaction>
}

/** Methods to use when ordering `Reaction`. */
export enum ReactionsOrderBy {
  CommentIdAsc = 'COMMENT_ID_ASC',
  CommentIdDesc = 'COMMENT_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  EmojiAsc = 'EMOJI_ASC',
  EmojiDesc = 'EMOJI_DESC',
  FileIdAsc = 'FILE_ID_ASC',
  FileIdDesc = 'FILE_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NativeAsc = 'NATIVE_ASC',
  NativeDesc = 'NATIVE_DESC',
  Natural = 'NATURAL',
  PersonIdAsc = 'PERSON_ID_ASC',
  PersonIdDesc = 'PERSON_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
}

/** The root subscription type: contains realtime events you can subscribe to with the `subscription` operation. */
export type Subscription = {
  __typename?: 'Subscription'
  fileUpdated?: Maybe<FileSubscriptionPayload>
  newNotification?: Maybe<NotificationSubscriptionPayload>
}

/** The root subscription type: contains realtime events you can subscribe to with the `subscription` operation. */
export type SubscriptionFileUpdatedArgs = {
  input?: Maybe<FileSubscriptionInput>
}

/** The root subscription type: contains realtime events you can subscribe to with the `subscription` operation. */
export type SubscriptionNewNotificationArgs = {
  input?: Maybe<NotificationSubscriptionInput>
}

export type Thumbnail = {
  __typename?: 'Thumbnail'
  large?: Maybe<Scalars['String']>
  medium?: Maybe<Scalars['String']>
  small?: Maybe<Scalars['String']>
}

/** An input for mutations affecting `Thumbnail` */
export type ThumbnailInput = {
  large?: Maybe<Scalars['String']>
  medium?: Maybe<Scalars['String']>
  small?: Maybe<Scalars['String']>
}

/** All input for the `updateCollectionByNodeId` mutation. */
export type UpdateCollectionByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** The globally unique `ID` which will identify a single `Collection` to be updated. */
  nodeId: Scalars['ID']
  /** An object where the defined keys will be set on the `Collection` being updated. */
  patch: CollectionPatch
}

/** All input for the `updateCollection` mutation. */
export type UpdateCollectionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  id: Scalars['UUID']
  /** An object where the defined keys will be set on the `Collection` being updated. */
  patch: CollectionPatch
}

/** The output of our update `Collection` mutation. */
export type UpdateCollectionPayload = {
  __typename?: 'UpdateCollectionPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** The `Collection` that was updated by this mutation. */
  collection?: Maybe<Collection>
  /** An edge for our `Collection`. May be used by Relay 1. */
  collectionEdge?: Maybe<CollectionsEdge>
  /** Reads a single `Organisation` that is related to this `Collection`. */
  organisation?: Maybe<Organisation>
  /** Reads a single `Person` that is related to this `Collection`. */
  person?: Maybe<Person>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>
}

/** The output of our update `Collection` mutation. */
export type UpdateCollectionPayloadCollectionEdgeArgs = {
  orderBy?: Maybe<Array<CollectionsOrderBy>>
}

/** All input for the `updateCommentByNodeId` mutation. */
export type UpdateCommentByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** The globally unique `ID` which will identify a single `Comment` to be updated. */
  nodeId: Scalars['ID']
  /** An object where the defined keys will be set on the `Comment` being updated. */
  patch: CommentPatch
}

/** All input for the `updateComment` mutation. */
export type UpdateCommentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  id: Scalars['UUID']
  /** An object where the defined keys will be set on the `Comment` being updated. */
  patch: CommentPatch
}

/** The output of our update `Comment` mutation. */
export type UpdateCommentPayload = {
  __typename?: 'UpdateCommentPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** The `Comment` that was updated by this mutation. */
  comment?: Maybe<Comment>
  /** An edge for our `Comment`. May be used by Relay 1. */
  commentEdge?: Maybe<CommentsEdge>
  /** Reads a single `File` that is related to this `Comment`. */
  file?: Maybe<File>
  /** Reads a single `Person` that is related to this `Comment`. */
  person?: Maybe<Person>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>
}

/** The output of our update `Comment` mutation. */
export type UpdateCommentPayloadCommentEdgeArgs = {
  orderBy?: Maybe<Array<CommentsOrderBy>>
}

/** All input for the `updateFileByNodeId` mutation. */
export type UpdateFileByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** The globally unique `ID` which will identify a single `File` to be updated. */
  nodeId: Scalars['ID']
  /** An object where the defined keys will be set on the `File` being updated. */
  patch: FilePatch
}

/** All input for the `updateFileCollectionByCollectionIdAndFileId` mutation. */
export type UpdateFileCollectionByCollectionIdAndFileIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  collectionId: Scalars['UUID']
  fileId: Scalars['UUID']
  /** An object where the defined keys will be set on the `FileCollection` being updated. */
  patch: FileCollectionPatch
}

/** All input for the `updateFileCollectionByNodeId` mutation. */
export type UpdateFileCollectionByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** The globally unique `ID` which will identify a single `FileCollection` to be updated. */
  nodeId: Scalars['ID']
  /** An object where the defined keys will be set on the `FileCollection` being updated. */
  patch: FileCollectionPatch
}

/** All input for the `updateFileCollection` mutation. */
export type UpdateFileCollectionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  id: Scalars['UUID']
  /** An object where the defined keys will be set on the `FileCollection` being updated. */
  patch: FileCollectionPatch
}

/** The output of our update `FileCollection` mutation. */
export type UpdateFileCollectionPayload = {
  __typename?: 'UpdateFileCollectionPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** Reads a single `Collection` that is related to this `FileCollection`. */
  collection?: Maybe<Collection>
  /** Reads a single `File` that is related to this `FileCollection`. */
  file?: Maybe<File>
  /** The `FileCollection` that was updated by this mutation. */
  fileCollection?: Maybe<FileCollection>
  /** An edge for our `FileCollection`. May be used by Relay 1. */
  fileCollectionEdge?: Maybe<FileCollectionsEdge>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>
}

/** The output of our update `FileCollection` mutation. */
export type UpdateFileCollectionPayloadFileCollectionEdgeArgs = {
  orderBy?: Maybe<Array<FileCollectionsOrderBy>>
}

/** All input for the `updateFile` mutation. */
export type UpdateFileInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  id: Scalars['UUID']
  /** An object where the defined keys will be set on the `File` being updated. */
  patch: FilePatch
}

/** The output of our update `File` mutation. */
export type UpdateFilePayload = {
  __typename?: 'UpdateFilePayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** The `File` that was updated by this mutation. */
  file?: Maybe<File>
  /** An edge for our `File`. May be used by Relay 1. */
  fileEdge?: Maybe<FilesEdge>
  /** Reads a single `File` that is related to this `File`. */
  originalFile?: Maybe<File>
  /** Reads a single `Person` that is related to this `File`. */
  person?: Maybe<Person>
  /** Reads a single `Project` that is related to this `File`. */
  project?: Maybe<Project>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>
}

/** The output of our update `File` mutation. */
export type UpdateFilePayloadFileEdgeArgs = {
  orderBy?: Maybe<Array<FilesOrderBy>>
}

/** All input for the `updateMentionByMentionedIdAndCommentId` mutation. */
export type UpdateMentionByMentionedIdAndCommentIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  commentId: Scalars['UUID']
  mentionedId: Scalars['UUID']
  /** An object where the defined keys will be set on the `Mention` being updated. */
  patch: MentionPatch
}

/** All input for the `updateMentionByNodeId` mutation. */
export type UpdateMentionByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** The globally unique `ID` which will identify a single `Mention` to be updated. */
  nodeId: Scalars['ID']
  /** An object where the defined keys will be set on the `Mention` being updated. */
  patch: MentionPatch
}

/** All input for the `updateMention` mutation. */
export type UpdateMentionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  id: Scalars['UUID']
  /** An object where the defined keys will be set on the `Mention` being updated. */
  patch: MentionPatch
}

/** The output of our update `Mention` mutation. */
export type UpdateMentionPayload = {
  __typename?: 'UpdateMentionPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** Reads a single `Comment` that is related to this `Mention`. */
  comment?: Maybe<Comment>
  /** The `Mention` that was updated by this mutation. */
  mention?: Maybe<Mention>
  /** An edge for our `Mention`. May be used by Relay 1. */
  mentionEdge?: Maybe<MentionsEdge>
  /** Reads a single `Person` that is related to this `Mention`. */
  mentioned?: Maybe<Person>
  /** Reads a single `Organisation` that is related to this `Mention`. */
  organisation?: Maybe<Organisation>
  /** Reads a single `Person` that is related to this `Mention`. */
  person?: Maybe<Person>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>
}

/** The output of our update `Mention` mutation. */
export type UpdateMentionPayloadMentionEdgeArgs = {
  orderBy?: Maybe<Array<MentionsOrderBy>>
}

/** All input for the `updateNotificationByNodeId` mutation. */
export type UpdateNotificationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** The globally unique `ID` which will identify a single `Notification` to be updated. */
  nodeId: Scalars['ID']
  /** An object where the defined keys will be set on the `Notification` being updated. */
  patch: NotificationPatch
}

/** All input for the `updateNotificationByReceiverIdAndCommentId` mutation. */
export type UpdateNotificationByReceiverIdAndCommentIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  commentId: Scalars['UUID']
  /** An object where the defined keys will be set on the `Notification` being updated. */
  patch: NotificationPatch
  receiverId: Scalars['UUID']
}

/** All input for the `updateNotificationByReceiverIdAndFileId` mutation. */
export type UpdateNotificationByReceiverIdAndFileIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  fileId: Scalars['UUID']
  /** An object where the defined keys will be set on the `Notification` being updated. */
  patch: NotificationPatch
  receiverId: Scalars['UUID']
}

/** All input for the `updateNotification` mutation. */
export type UpdateNotificationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  id: Scalars['UUID']
  /** An object where the defined keys will be set on the `Notification` being updated. */
  patch: NotificationPatch
}

/** The output of our update `Notification` mutation. */
export type UpdateNotificationPayload = {
  __typename?: 'UpdateNotificationPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** Reads a single `Comment` that is related to this `Notification`. */
  comment?: Maybe<Comment>
  /** Reads a single `File` that is related to this `Notification`. */
  file?: Maybe<File>
  /** The `Notification` that was updated by this mutation. */
  notification?: Maybe<Notification>
  /** An edge for our `Notification`. May be used by Relay 1. */
  notificationEdge?: Maybe<NotificationsEdge>
  /** Reads a single `Organisation` that is related to this `Notification`. */
  organisation?: Maybe<Organisation>
  /** Reads a single `Person` that is related to this `Notification`. */
  person?: Maybe<Person>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>
  /** Reads a single `Person` that is related to this `Notification`. */
  receiver?: Maybe<Person>
}

/** The output of our update `Notification` mutation. */
export type UpdateNotificationPayloadNotificationEdgeArgs = {
  orderBy?: Maybe<Array<NotificationsOrderBy>>
}

/** All input for the `updateOrganisationByNodeId` mutation. */
export type UpdateOrganisationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** The globally unique `ID` which will identify a single `Organisation` to be updated. */
  nodeId: Scalars['ID']
  /** An object where the defined keys will be set on the `Organisation` being updated. */
  patch: OrganisationPatch
}

/** All input for the `updateOrganisation` mutation. */
export type UpdateOrganisationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  id: Scalars['UUID']
  /** An object where the defined keys will be set on the `Organisation` being updated. */
  patch: OrganisationPatch
}

/** All input for the `updateOrganisationInvitationByNodeId` mutation. */
export type UpdateOrganisationInvitationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** The globally unique `ID` which will identify a single `OrganisationInvitation` to be updated. */
  nodeId: Scalars['ID']
  /** An object where the defined keys will be set on the `OrganisationInvitation` being updated. */
  patch: OrganisationInvitationPatch
}

/** All input for the `updateOrganisationInvitation` mutation. */
export type UpdateOrganisationInvitationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  id: Scalars['UUID']
  /** An object where the defined keys will be set on the `OrganisationInvitation` being updated. */
  patch: OrganisationInvitationPatch
}

/** The output of our update `OrganisationInvitation` mutation. */
export type UpdateOrganisationInvitationPayload = {
  __typename?: 'UpdateOrganisationInvitationPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** Reads a single `Organisation` that is related to this `OrganisationInvitation`. */
  organisation?: Maybe<Organisation>
  /** The `OrganisationInvitation` that was updated by this mutation. */
  organisationInvitation?: Maybe<OrganisationInvitation>
  /** An edge for our `OrganisationInvitation`. May be used by Relay 1. */
  organisationInvitationEdge?: Maybe<OrganisationInvitationsEdge>
  /** Reads a single `Person` that is related to this `OrganisationInvitation`. */
  personByAcceptedBy?: Maybe<Person>
  /** Reads a single `Person` that is related to this `OrganisationInvitation`. */
  personByInvitedBy?: Maybe<Person>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>
}

/** The output of our update `OrganisationInvitation` mutation. */
export type UpdateOrganisationInvitationPayloadOrganisationInvitationEdgeArgs =
  {
    orderBy?: Maybe<Array<OrganisationInvitationsOrderBy>>
  }

/** The output of our update `Organisation` mutation. */
export type UpdateOrganisationPayload = {
  __typename?: 'UpdateOrganisationPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** The `Organisation` that was updated by this mutation. */
  organisation?: Maybe<Organisation>
  /** An edge for our `Organisation`. May be used by Relay 1. */
  organisationEdge?: Maybe<OrganisationsEdge>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>
}

/** The output of our update `Organisation` mutation. */
export type UpdateOrganisationPayloadOrganisationEdgeArgs = {
  orderBy?: Maybe<Array<OrganisationsOrderBy>>
}

/** All input for the `updatePersonByFirebaseId` mutation. */
export type UpdatePersonByFirebaseIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  firebaseId: Scalars['String']
  /** An object where the defined keys will be set on the `Person` being updated. */
  patch: PersonPatch
}

/** All input for the `updatePersonByNodeId` mutation. */
export type UpdatePersonByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** The globally unique `ID` which will identify a single `Person` to be updated. */
  nodeId: Scalars['ID']
  /** An object where the defined keys will be set on the `Person` being updated. */
  patch: PersonPatch
}

/** All input for the `updatePerson` mutation. */
export type UpdatePersonInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  id: Scalars['UUID']
  /** An object where the defined keys will be set on the `Person` being updated. */
  patch: PersonPatch
}

/** All input for the `updatePersonOrganisationByNodeId` mutation. */
export type UpdatePersonOrganisationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** The globally unique `ID` which will identify a single `PersonOrganisation` to be updated. */
  nodeId: Scalars['ID']
  /** An object where the defined keys will be set on the `PersonOrganisation` being updated. */
  patch: PersonOrganisationPatch
}

/** All input for the `updatePersonOrganisationByOrganisationIdAndPersonId` mutation. */
export type UpdatePersonOrganisationByOrganisationIdAndPersonIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  organisationId: Scalars['UUID']
  /** An object where the defined keys will be set on the `PersonOrganisation` being updated. */
  patch: PersonOrganisationPatch
  personId: Scalars['UUID']
}

/** All input for the `updatePersonOrganisation` mutation. */
export type UpdatePersonOrganisationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  id: Scalars['UUID']
  /** An object where the defined keys will be set on the `PersonOrganisation` being updated. */
  patch: PersonOrganisationPatch
}

/** The output of our update `PersonOrganisation` mutation. */
export type UpdatePersonOrganisationPayload = {
  __typename?: 'UpdatePersonOrganisationPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** Reads a single `Organisation` that is related to this `PersonOrganisation`. */
  organisation?: Maybe<Organisation>
  /** Reads a single `Person` that is related to this `PersonOrganisation`. */
  person?: Maybe<Person>
  /** The `PersonOrganisation` that was updated by this mutation. */
  personOrganisation?: Maybe<PersonOrganisation>
  /** An edge for our `PersonOrganisation`. May be used by Relay 1. */
  personOrganisationEdge?: Maybe<PersonOrganisationsEdge>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>
}

/** The output of our update `PersonOrganisation` mutation. */
export type UpdatePersonOrganisationPayloadPersonOrganisationEdgeArgs = {
  orderBy?: Maybe<Array<PersonOrganisationsOrderBy>>
}

/** The output of our update `Person` mutation. */
export type UpdatePersonPayload = {
  __typename?: 'UpdatePersonPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** The `Person` that was updated by this mutation. */
  person?: Maybe<Person>
  /** An edge for our `Person`. May be used by Relay 1. */
  personEdge?: Maybe<PeopleEdge>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>
}

/** The output of our update `Person` mutation. */
export type UpdatePersonPayloadPersonEdgeArgs = {
  orderBy?: Maybe<Array<PeopleOrderBy>>
}

/** All input for the `updatePersonProjectByNodeId` mutation. */
export type UpdatePersonProjectByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** The globally unique `ID` which will identify a single `PersonProject` to be updated. */
  nodeId: Scalars['ID']
  /** An object where the defined keys will be set on the `PersonProject` being updated. */
  patch: PersonProjectPatch
}

/** All input for the `updatePersonProjectByPersonIdAndProjectId` mutation. */
export type UpdatePersonProjectByPersonIdAndProjectIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** An object where the defined keys will be set on the `PersonProject` being updated. */
  patch: PersonProjectPatch
  personId: Scalars['UUID']
  projectId: Scalars['UUID']
}

/** All input for the `updatePersonProject` mutation. */
export type UpdatePersonProjectInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  id: Scalars['UUID']
  /** An object where the defined keys will be set on the `PersonProject` being updated. */
  patch: PersonProjectPatch
}

/** The output of our update `PersonProject` mutation. */
export type UpdatePersonProjectPayload = {
  __typename?: 'UpdatePersonProjectPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** Reads a single `Person` that is related to this `PersonProject`. */
  person?: Maybe<Person>
  /** The `PersonProject` that was updated by this mutation. */
  personProject?: Maybe<PersonProject>
  /** An edge for our `PersonProject`. May be used by Relay 1. */
  personProjectEdge?: Maybe<PersonProjectsEdge>
  /** Reads a single `Project` that is related to this `PersonProject`. */
  project?: Maybe<Project>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>
}

/** The output of our update `PersonProject` mutation. */
export type UpdatePersonProjectPayloadPersonProjectEdgeArgs = {
  orderBy?: Maybe<Array<PersonProjectsOrderBy>>
}

/** All input for the `updateProjectByNodeId` mutation. */
export type UpdateProjectByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** The globally unique `ID` which will identify a single `Project` to be updated. */
  nodeId: Scalars['ID']
  /** An object where the defined keys will be set on the `Project` being updated. */
  patch: ProjectPatch
}

/** All input for the `updateProject` mutation. */
export type UpdateProjectInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  id: Scalars['UUID']
  /** An object where the defined keys will be set on the `Project` being updated. */
  patch: ProjectPatch
}

/** The output of our update `Project` mutation. */
export type UpdateProjectPayload = {
  __typename?: 'UpdateProjectPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** Reads a single `Organisation` that is related to this `Project`. */
  organisation?: Maybe<Organisation>
  /** Reads a single `Person` that is related to this `Project`. */
  person?: Maybe<Person>
  /** The `Project` that was updated by this mutation. */
  project?: Maybe<Project>
  /** An edge for our `Project`. May be used by Relay 1. */
  projectEdge?: Maybe<ProjectsEdge>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>
}

/** The output of our update `Project` mutation. */
export type UpdateProjectPayloadProjectEdgeArgs = {
  orderBy?: Maybe<Array<ProjectsOrderBy>>
}

/** All input for the `updateReactionByNodeId` mutation. */
export type UpdateReactionByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** The globally unique `ID` which will identify a single `Reaction` to be updated. */
  nodeId: Scalars['ID']
  /** An object where the defined keys will be set on the `Reaction` being updated. */
  patch: ReactionPatch
}

/** All input for the `updateReaction` mutation. */
export type UpdateReactionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>
  id: Scalars['UUID']
  /** An object where the defined keys will be set on the `Reaction` being updated. */
  patch: ReactionPatch
}

/** The output of our update `Reaction` mutation. */
export type UpdateReactionPayload = {
  __typename?: 'UpdateReactionPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** Reads a single `Comment` that is related to this `Reaction`. */
  comment?: Maybe<Comment>
  /** Reads a single `File` that is related to this `Reaction`. */
  file?: Maybe<File>
  /** Reads a single `Person` that is related to this `Reaction`. */
  person?: Maybe<Person>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>
  /** The `Reaction` that was updated by this mutation. */
  reaction?: Maybe<Reaction>
  /** An edge for our `Reaction`. May be used by Relay 1. */
  reactionEdge?: Maybe<ReactionsEdge>
}

/** The output of our update `Reaction` mutation. */
export type UpdateReactionPayloadReactionEdgeArgs = {
  orderBy?: Maybe<Array<ReactionsOrderBy>>
}

export type FullFileFragment = {
  __typename?: 'File'
  name?: string | null | undefined
  id: any
  medium?: Medium | null | undefined
  format?: Format | null | undefined
  description?: string | null | undefined
  createdAt: any
  comments: {
    __typename?: 'CommentsConnection'
    nodes: Array<
      | {
          __typename?: 'Comment'
          id: any
          fileId?: any | null | undefined
          content?: string | null | undefined
          createdAt: any
          updatedAt: any
          reactions: {
            __typename?: 'ReactionsConnection'
            nodes: Array<
              | {
                  __typename?: 'Reaction'
                  id: any
                  emoji?: string | null | undefined
                  fileId?: any | null | undefined
                  commentId?: any | null | undefined
                  native?: string | null | undefined
                  person?:
                    | { __typename?: 'Person'; id: any; name: string }
                    | null
                    | undefined
                }
              | null
              | undefined
            >
          }
          person?:
            | {
                __typename?: 'Person'
                id: any
                name: string
                photoUrl?: string | null | undefined
              }
            | null
            | undefined
        }
      | null
      | undefined
    >
  }
  person?:
    | {
        __typename?: 'Person'
        id: any
        name: string
        photoUrl?: string | null | undefined
      }
    | null
    | undefined
  thumbnails?:
    | {
        __typename?: 'Thumbnail'
        small?: string | null | undefined
        medium?: string | null | undefined
      }
    | null
    | undefined
  location?:
    | {
        __typename?: 'Location'
        address?: string | null | undefined
        geo?:
          | { __typename?: 'GeometryPoint'; x: number; y: number }
          | null
          | undefined
      }
    | null
    | undefined
  project?:
    | { __typename?: 'Project'; id: any; name: string; organisationId: any }
    | null
    | undefined
  collections: {
    __typename?: 'FileCollectionsConnection'
    nodes: Array<
      | {
          __typename?: 'FileCollection'
          id: any
          collection?:
            | {
                __typename?: 'Collection'
                id: any
                name?: string | null | undefined
              }
            | null
            | undefined
        }
      | null
      | undefined
    >
  }
}

export type FullMentionFragment = {
  __typename?: 'Mention'
  id: any
  mentioned?:
    | {
        __typename?: 'Person'
        deviceIds?: Array<string | null | undefined> | null | undefined
        id: any
        name: string
        photoUrl?: string | null | undefined
      }
    | null
    | undefined
  comment?:
    | {
        __typename?: 'Comment'
        id: any
        fileId?: any | null | undefined
        content?: string | null | undefined
        createdAt: any
        updatedAt: any
        file?:
          | {
              __typename?: 'File'
              name?: string | null | undefined
              id: any
              medium?: Medium | null | undefined
              format?: Format | null | undefined
              description?: string | null | undefined
              createdAt: any
              comments: {
                __typename?: 'CommentsConnection'
                nodes: Array<
                  | {
                      __typename?: 'Comment'
                      id: any
                      fileId?: any | null | undefined
                      content?: string | null | undefined
                      createdAt: any
                      updatedAt: any
                      reactions: {
                        __typename?: 'ReactionsConnection'
                        nodes: Array<
                          | {
                              __typename?: 'Reaction'
                              id: any
                              emoji?: string | null | undefined
                              fileId?: any | null | undefined
                              commentId?: any | null | undefined
                              native?: string | null | undefined
                              person?:
                                | {
                                    __typename?: 'Person'
                                    id: any
                                    name: string
                                  }
                                | null
                                | undefined
                            }
                          | null
                          | undefined
                        >
                      }
                      person?:
                        | {
                            __typename?: 'Person'
                            id: any
                            name: string
                            photoUrl?: string | null | undefined
                          }
                        | null
                        | undefined
                    }
                  | null
                  | undefined
                >
              }
              person?:
                | {
                    __typename?: 'Person'
                    id: any
                    name: string
                    photoUrl?: string | null | undefined
                  }
                | null
                | undefined
              thumbnails?:
                | {
                    __typename?: 'Thumbnail'
                    small?: string | null | undefined
                    medium?: string | null | undefined
                  }
                | null
                | undefined
              location?:
                | {
                    __typename?: 'Location'
                    address?: string | null | undefined
                    geo?:
                      | { __typename?: 'GeometryPoint'; x: number; y: number }
                      | null
                      | undefined
                  }
                | null
                | undefined
              project?:
                | {
                    __typename?: 'Project'
                    id: any
                    name: string
                    organisationId: any
                  }
                | null
                | undefined
              collections: {
                __typename?: 'FileCollectionsConnection'
                nodes: Array<
                  | {
                      __typename?: 'FileCollection'
                      id: any
                      collection?:
                        | {
                            __typename?: 'Collection'
                            id: any
                            name?: string | null | undefined
                          }
                        | null
                        | undefined
                    }
                  | null
                  | undefined
                >
              }
            }
          | null
          | undefined
        reactions: {
          __typename?: 'ReactionsConnection'
          nodes: Array<
            | {
                __typename?: 'Reaction'
                id: any
                emoji?: string | null | undefined
                fileId?: any | null | undefined
                commentId?: any | null | undefined
                native?: string | null | undefined
                person?:
                  | { __typename?: 'Person'; id: any; name: string }
                  | null
                  | undefined
              }
            | null
            | undefined
          >
        }
        person?:
          | {
              __typename?: 'Person'
              id: any
              name: string
              photoUrl?: string | null | undefined
            }
          | null
          | undefined
      }
    | null
    | undefined
}

export type FullProjectFragment = {
  __typename?: 'Project'
  id: any
  name: string
  description?: string | null | undefined
  type?: ProjectType | null | undefined
  createdAt: any
  updatedAt: any
  createdBy?:
    | {
        __typename?: 'Person'
        id: any
        name: string
        photoUrl?: string | null | undefined
      }
    | null
    | undefined
  people: {
    __typename?: 'PersonProjectsConnection'
    nodes: Array<
      | {
          __typename?: 'PersonProject'
          id: any
          person?:
            | {
                __typename?: 'Person'
                id: any
                name: string
                photoUrl?: string | null | undefined
              }
            | null
            | undefined
        }
      | null
      | undefined
    >
  }
}

export type RegularCommentFragment = {
  __typename?: 'Comment'
  id: any
  fileId?: any | null | undefined
  content?: string | null | undefined
  createdAt: any
  updatedAt: any
  reactions: {
    __typename?: 'ReactionsConnection'
    nodes: Array<
      | {
          __typename?: 'Reaction'
          id: any
          emoji?: string | null | undefined
          fileId?: any | null | undefined
          commentId?: any | null | undefined
          native?: string | null | undefined
          person?:
            | { __typename?: 'Person'; id: any; name: string }
            | null
            | undefined
        }
      | null
      | undefined
    >
  }
  person?:
    | {
        __typename?: 'Person'
        id: any
        name: string
        photoUrl?: string | null | undefined
      }
    | null
    | undefined
}

export type RegularFileFragment = {
  __typename?: 'File'
  name?: string | null | undefined
  id: any
  medium?: Medium | null | undefined
  format?: Format | null | undefined
  description?: string | null | undefined
  createdAt: any
  person?:
    | {
        __typename?: 'Person'
        id: any
        name: string
        photoUrl?: string | null | undefined
      }
    | null
    | undefined
  thumbnails?:
    | {
        __typename?: 'Thumbnail'
        small?: string | null | undefined
        medium?: string | null | undefined
      }
    | null
    | undefined
  location?:
    | {
        __typename?: 'Location'
        address?: string | null | undefined
        geo?:
          | { __typename?: 'GeometryPoint'; x: number; y: number }
          | null
          | undefined
      }
    | null
    | undefined
  project?:
    | { __typename?: 'Project'; id: any; name: string; organisationId: any }
    | null
    | undefined
  collections: {
    __typename?: 'FileCollectionsConnection'
    nodes: Array<
      | {
          __typename?: 'FileCollection'
          id: any
          collection?:
            | {
                __typename?: 'Collection'
                id: any
                name?: string | null | undefined
              }
            | null
            | undefined
        }
      | null
      | undefined
    >
  }
}

export type RegularPersonFragment = {
  __typename?: 'Person'
  id: any
  name: string
  photoUrl?: string | null | undefined
}

export type RegularProjectFragment = {
  __typename?: 'Project'
  id: any
  name: string
  organisationId: any
}

export type RegularReactionFragment = {
  __typename?: 'Reaction'
  id: any
  emoji?: string | null | undefined
  fileId?: any | null | undefined
  commentId?: any | null | undefined
  native?: string | null | undefined
  person?: { __typename?: 'Person'; id: any; name: string } | null | undefined
}

export type AddFileToCollectionMutationVariables = Exact<{
  collectionId: Scalars['UUID']
  fileId: Scalars['UUID']
}>

export type AddFileToCollectionMutation = {
  __typename?: 'Mutation'
  createFileCollection?:
    | {
        __typename?: 'CreateFileCollectionPayload'
        clientMutationId?: string | null | undefined
      }
    | null
    | undefined
}

export type CreateCollectionMutationVariables = Exact<{
  organisationId: Scalars['UUID']
  name: Scalars['String']
}>

export type CreateCollectionMutation = {
  __typename?: 'Mutation'
  createCollection?:
    | {
        __typename?: 'CreateCollectionPayload'
        collection?:
          | {
              __typename?: 'Collection'
              name?: string | null | undefined
              id: any
            }
          | null
          | undefined
      }
    | null
    | undefined
}

export type GetCollectionsByOrgQueryVariables = Exact<{
  organisationId: Scalars['UUID']
}>

export type GetCollectionsByOrgQuery = {
  __typename?: 'Query'
  collections?:
    | {
        __typename?: 'CollectionsConnection'
        nodes: Array<
          | {
              __typename?: 'Collection'
              id: any
              name?: string | null | undefined
              organisation?:
                | { __typename?: 'Organisation'; id: any; name: string }
                | null
                | undefined
              files: {
                __typename?: 'FileCollectionsConnection'
                nodes: Array<
                  | {
                      __typename?: 'FileCollection'
                      id: any
                      fileId?: any | null | undefined
                    }
                  | null
                  | undefined
                >
              }
            }
          | null
          | undefined
        >
      }
    | null
    | undefined
}

export type RemoveCollectionFromFileMutationVariables = Exact<{
  fileId: Scalars['UUID']
  collectionId: Scalars['UUID']
}>

export type RemoveCollectionFromFileMutation = {
  __typename?: 'Mutation'
  deleteFileCollectionByCollectionIdAndFileId?:
    | {
        __typename?: 'DeleteFileCollectionPayload'
        fileCollection?:
          | { __typename?: 'FileCollection'; id: any }
          | null
          | undefined
      }
    | null
    | undefined
}

export type CreateCommentMutationVariables = Exact<{
  fileId: Scalars['UUID']
  content: Scalars['String']
}>

export type CreateCommentMutation = {
  __typename?: 'Mutation'
  createComment?:
    | {
        __typename: 'CreateCommentPayload'
        comment?:
          | {
              __typename?: 'Comment'
              id: any
              fileId?: any | null | undefined
              content?: string | null | undefined
              createdAt: any
              updatedAt: any
              reactions: {
                __typename?: 'ReactionsConnection'
                nodes: Array<
                  | {
                      __typename?: 'Reaction'
                      id: any
                      emoji?: string | null | undefined
                      fileId?: any | null | undefined
                      commentId?: any | null | undefined
                      native?: string | null | undefined
                      person?:
                        | { __typename?: 'Person'; id: any; name: string }
                        | null
                        | undefined
                    }
                  | null
                  | undefined
                >
              }
              person?:
                | {
                    __typename?: 'Person'
                    id: any
                    name: string
                    photoUrl?: string | null | undefined
                  }
                | null
                | undefined
            }
          | null
          | undefined
      }
    | null
    | undefined
}

export type DeleteCommentMutationVariables = Exact<{
  commentId: Scalars['UUID']
}>

export type DeleteCommentMutation = {
  __typename?: 'Mutation'
  deleteComment?:
    | {
        __typename?: 'DeleteCommentPayload'
        comment?: { __typename?: 'Comment'; id: any } | null | undefined
      }
    | null
    | undefined
}

export type UpdateCommentMutationVariables = Exact<{
  commentId: Scalars['UUID']
  patch: CommentPatch
}>

export type UpdateCommentMutation = {
  __typename?: 'Mutation'
  updateComment?:
    | {
        __typename?: 'UpdateCommentPayload'
        comment?:
          | {
              __typename?: 'Comment'
              id: any
              content?: string | null | undefined
            }
          | null
          | undefined
      }
    | null
    | undefined
}

export type CreateFileMutationVariables = Exact<{
  name: Scalars['String']
  projectId: Scalars['UUID']
  medium: Medium
  duration: Scalars['Int']
  dimensions: DimensionInput
  format: Format
  size: Scalars['Int']
  id: Scalars['UUID']
}>

export type CreateFileMutation = {
  __typename?: 'Mutation'
  createFile?:
    | {
        __typename?: 'CreateFilePayload'
        file?:
          | { __typename?: 'File'; name?: string | null | undefined; id: any }
          | null
          | undefined
      }
    | null
    | undefined
}

export type DeleteFileMutationVariables = Exact<{
  fileId: Scalars['UUID']
}>

export type DeleteFileMutation = {
  __typename?: 'Mutation'
  deleteFile?:
    | {
        __typename?: 'DeleteFilePayload'
        file?: { __typename?: 'File'; id: any } | null | undefined
      }
    | null
    | undefined
}

export type UpdateFileMutationVariables = Exact<{
  fileId: Scalars['UUID']
  patch: FilePatch
}>

export type UpdateFileMutation = {
  __typename?: 'Mutation'
  updateFile?:
    | {
        __typename?: 'UpdateFilePayload'
        file?:
          | {
              __typename?: 'File'
              name?: string | null | undefined
              id: any
              medium?: Medium | null | undefined
              format?: Format | null | undefined
              description?: string | null | undefined
              createdAt: any
              person?:
                | {
                    __typename?: 'Person'
                    id: any
                    name: string
                    photoUrl?: string | null | undefined
                  }
                | null
                | undefined
              thumbnails?:
                | {
                    __typename?: 'Thumbnail'
                    small?: string | null | undefined
                    medium?: string | null | undefined
                  }
                | null
                | undefined
              location?:
                | {
                    __typename?: 'Location'
                    address?: string | null | undefined
                    geo?:
                      | { __typename?: 'GeometryPoint'; x: number; y: number }
                      | null
                      | undefined
                  }
                | null
                | undefined
              project?:
                | {
                    __typename?: 'Project'
                    id: any
                    name: string
                    organisationId: any
                  }
                | null
                | undefined
              collections: {
                __typename?: 'FileCollectionsConnection'
                nodes: Array<
                  | {
                      __typename?: 'FileCollection'
                      id: any
                      collection?:
                        | {
                            __typename?: 'Collection'
                            id: any
                            name?: string | null | undefined
                          }
                        | null
                        | undefined
                    }
                  | null
                  | undefined
                >
              }
            }
          | null
          | undefined
      }
    | null
    | undefined
}

export type CreateMentionMutationVariables = Exact<{
  commentId: Scalars['UUID']
  mentionedId: Scalars['UUID']
  organisationId: Scalars['UUID']
}>

export type CreateMentionMutation = {
  __typename?: 'Mutation'
  createMention?:
    | {
        __typename?: 'CreateMentionPayload'
        mention?: { __typename?: 'Mention'; id: any } | null | undefined
      }
    | null
    | undefined
}

export type CreateNotificationMutationVariables = Exact<{
  receiverId: Scalars['UUID']
  organisationId: Scalars['UUID']
  commentId?: Maybe<Scalars['UUID']>
  fileId?: Maybe<Scalars['UUID']>
}>

export type CreateNotificationMutation = {
  __typename?: 'Mutation'
  createNotification?:
    | {
        __typename?: 'CreateNotificationPayload'
        notification?:
          | { __typename?: 'Notification'; id: any }
          | null
          | undefined
      }
    | null
    | undefined
}

export type DeleteNotificationMutationVariables = Exact<{
  notificationId: Scalars['UUID']
}>

export type DeleteNotificationMutation = {
  __typename?: 'Mutation'
  deleteNotification?:
    | {
        __typename?: 'DeleteNotificationPayload'
        notification?:
          | { __typename?: 'Notification'; id: any }
          | null
          | undefined
      }
    | null
    | undefined
}

export type AcceptOrganisationInvitationMutationVariables = Exact<{
  invitationCode: Scalars['String']
}>

export type AcceptOrganisationInvitationMutation = {
  __typename?: 'Mutation'
  acceptOrganisationInvitation?:
    | {
        __typename?: 'AcceptOrganisationInvitationPayload'
        boolean?: boolean | null | undefined
      }
    | null
    | undefined
}

export type CreateOrganisationMutationVariables = Exact<{
  name: Scalars['String']
}>

export type CreateOrganisationMutation = {
  __typename?: 'Mutation'
  addOrganisation?:
    | {
        __typename?: 'AddOrganisationPayload'
        organisation?:
          | { __typename?: 'Organisation'; name: string; id: any }
          | null
          | undefined
      }
    | null
    | undefined
}

export type CreateOrganisationInvitationMutationVariables = Exact<{
  code: Scalars['String']
  organisationId: Scalars['UUID']
}>

export type CreateOrganisationInvitationMutation = {
  __typename?: 'Mutation'
  createOrganisationInvitation?:
    | {
        __typename?: 'CreateOrganisationInvitationPayload'
        organisationInvitation?:
          | { __typename?: 'OrganisationInvitation'; id: any }
          | null
          | undefined
      }
    | null
    | undefined
}

export type DeleteOrganisationMutationVariables = Exact<{
  organisationId: Scalars['UUID']
}>

export type DeleteOrganisationMutation = {
  __typename?: 'Mutation'
  deleteOrganisation?:
    | {
        __typename?: 'DeleteOrganisationPayload'
        organisation?:
          | { __typename?: 'Organisation'; id: any }
          | null
          | undefined
      }
    | null
    | undefined
}

export type DeleteOrganisationInvitationMutationVariables = Exact<{
  invitationId: Scalars['UUID']
}>

export type DeleteOrganisationInvitationMutation = {
  __typename?: 'Mutation'
  deleteOrganisationInvitation?:
    | {
        __typename?: 'DeleteOrganisationInvitationPayload'
        organisationInvitation?:
          | { __typename?: 'OrganisationInvitation'; id: any }
          | null
          | undefined
      }
    | null
    | undefined
}

export type UpdateOrganisationMutationVariables = Exact<{
  organisationId: Scalars['UUID']
  patch: OrganisationPatch
}>

export type UpdateOrganisationMutation = {
  __typename?: 'Mutation'
  updateOrganisation?:
    | {
        __typename?: 'UpdateOrganisationPayload'
        organisation?:
          | { __typename?: 'Organisation'; id: any; name: string }
          | null
          | undefined
      }
    | null
    | undefined
}

export type AddFirebaseAccountMutationVariables = Exact<{
  name: Scalars['String']
  photoUrl: Scalars['String']
}>

export type AddFirebaseAccountMutation = {
  __typename?: 'Mutation'
  addFirebaseAccount?:
    | {
        __typename?: 'AddFirebaseAccountPayload'
        person?:
          | { __typename?: 'Person'; id: any; firebaseId: string }
          | null
          | undefined
      }
    | null
    | undefined
}

export type AddPersonToProjectMutationVariables = Exact<{
  personId: Scalars['UUID']
  projectId: Scalars['UUID']
}>

export type AddPersonToProjectMutation = {
  __typename?: 'Mutation'
  createPersonProject?:
    | {
        __typename?: 'CreatePersonProjectPayload'
        personProject?:
          | {
              __typename?: 'PersonProject'
              personId?: any | null | undefined
              projectId?: any | null | undefined
            }
          | null
          | undefined
      }
    | null
    | undefined
}

export type CreateProjectMutationVariables = Exact<{
  name: Scalars['String']
  description: Scalars['String']
  organisationId: Scalars['UUID']
  type: ProjectType
}>

export type CreateProjectMutation = {
  __typename?: 'Mutation'
  createProject?:
    | {
        __typename?: 'CreateProjectPayload'
        project?:
          | {
              __typename?: 'Project'
              organisationId: any
              id: any
              name: string
              description?: string | null | undefined
              type?: ProjectType | null | undefined
              createdAt: any
              updatedAt: any
              createdBy?:
                | {
                    __typename?: 'Person'
                    id: any
                    name: string
                    photoUrl?: string | null | undefined
                  }
                | null
                | undefined
              people: {
                __typename?: 'PersonProjectsConnection'
                nodes: Array<
                  | {
                      __typename?: 'PersonProject'
                      id: any
                      person?:
                        | {
                            __typename?: 'Person'
                            id: any
                            name: string
                            photoUrl?: string | null | undefined
                          }
                        | null
                        | undefined
                    }
                  | null
                  | undefined
                >
              }
            }
          | null
          | undefined
      }
    | null
    | undefined
}

export type DeleteProjectMutationVariables = Exact<{
  projectId: Scalars['UUID']
}>

export type DeleteProjectMutation = {
  __typename?: 'Mutation'
  deleteProject?:
    | {
        __typename?: 'DeleteProjectPayload'
        project?: { __typename?: 'Project'; id: any } | null | undefined
      }
    | null
    | undefined
}

export type RemovePersonFromProjectMutationVariables = Exact<{
  personId: Scalars['UUID']
  projectId: Scalars['UUID']
}>

export type RemovePersonFromProjectMutation = {
  __typename?: 'Mutation'
  deletePersonProjectByPersonIdAndProjectId?:
    | {
        __typename?: 'DeletePersonProjectPayload'
        personProject?:
          | {
              __typename?: 'PersonProject'
              personId?: any | null | undefined
              projectId?: any | null | undefined
            }
          | null
          | undefined
      }
    | null
    | undefined
}

export type UpdateProjectMutationVariables = Exact<{
  projectId: Scalars['UUID']
  patch: ProjectPatch
}>

export type UpdateProjectMutation = {
  __typename?: 'Mutation'
  updateProject?:
    | {
        __typename?: 'UpdateProjectPayload'
        project?:
          | { __typename?: 'Project'; id: any; name: string }
          | null
          | undefined
      }
    | null
    | undefined
}

export type CreateReactionMutationVariables = Exact<{
  emoji: Scalars['String']
  native: Scalars['String']
  fileId: Scalars['UUID']
  commentId?: Maybe<Scalars['UUID']>
}>

export type CreateReactionMutation = {
  __typename?: 'Mutation'
  createReaction?:
    | {
        __typename?: 'CreateReactionPayload'
        reaction?:
          | {
              __typename?: 'Reaction'
              id: any
              emoji?: string | null | undefined
              fileId?: any | null | undefined
              commentId?: any | null | undefined
              native?: string | null | undefined
              person?:
                | { __typename?: 'Person'; id: any; name: string }
                | null
                | undefined
            }
          | null
          | undefined
      }
    | null
    | undefined
}

export type DeleteReactionMutationVariables = Exact<{
  reactionId: Scalars['UUID']
}>

export type DeleteReactionMutation = {
  __typename?: 'Mutation'
  deleteReaction?:
    | {
        __typename?: 'DeleteReactionPayload'
        reaction?:
          | {
              __typename?: 'Reaction'
              id: any
              emoji?: string | null | undefined
              fileId?: any | null | undefined
              commentId?: any | null | undefined
              native?: string | null | undefined
              person?:
                | { __typename?: 'Person'; id: any; name: string }
                | null
                | undefined
            }
          | null
          | undefined
      }
    | null
    | undefined
}

export type GetCommentsByFileQueryVariables = Exact<{
  fileId: Scalars['UUID']
}>

export type GetCommentsByFileQuery = {
  __typename?: 'Query'
  comments?:
    | {
        __typename?: 'CommentsConnection'
        nodes: Array<
          | {
              __typename?: 'Comment'
              id: any
              fileId?: any | null | undefined
              content?: string | null | undefined
              createdAt: any
              updatedAt: any
              reactions: {
                __typename?: 'ReactionsConnection'
                nodes: Array<
                  | {
                      __typename?: 'Reaction'
                      id: any
                      emoji?: string | null | undefined
                      fileId?: any | null | undefined
                      commentId?: any | null | undefined
                      native?: string | null | undefined
                      person?:
                        | { __typename?: 'Person'; id: any; name: string }
                        | null
                        | undefined
                    }
                  | null
                  | undefined
                >
              }
              person?:
                | {
                    __typename?: 'Person'
                    id: any
                    name: string
                    photoUrl?: string | null | undefined
                  }
                | null
                | undefined
            }
          | null
          | undefined
        >
      }
    | null
    | undefined
}

export type GetCurrentPersonQueryVariables = Exact<{ [key: string]: never }>

export type GetCurrentPersonQuery = {
  __typename?: 'Query'
  getCurrentPerson?:
    | {
        __typename?: 'GetCurrentPersonConnection'
        nodes: Array<
          | {
              __typename?: 'GetCurrentPersonRecord'
              id?: any | null | undefined
              name?: string | null | undefined
              photoUrl?: string | null | undefined
            }
          | null
          | undefined
        >
      }
    | null
    | undefined
}

export type GetFileQueryVariables = Exact<{
  fileId: Scalars['UUID']
}>

export type GetFileQuery = {
  __typename?: 'Query'
  file?:
    | {
        __typename?: 'File'
        name?: string | null | undefined
        id: any
        medium?: Medium | null | undefined
        format?: Format | null | undefined
        description?: string | null | undefined
        createdAt: any
        comments: {
          __typename?: 'CommentsConnection'
          nodes: Array<
            | {
                __typename?: 'Comment'
                id: any
                fileId?: any | null | undefined
                content?: string | null | undefined
                createdAt: any
                updatedAt: any
                reactions: {
                  __typename?: 'ReactionsConnection'
                  nodes: Array<
                    | {
                        __typename?: 'Reaction'
                        id: any
                        emoji?: string | null | undefined
                        fileId?: any | null | undefined
                        commentId?: any | null | undefined
                        native?: string | null | undefined
                        person?:
                          | { __typename?: 'Person'; id: any; name: string }
                          | null
                          | undefined
                      }
                    | null
                    | undefined
                  >
                }
                person?:
                  | {
                      __typename?: 'Person'
                      id: any
                      name: string
                      photoUrl?: string | null | undefined
                    }
                  | null
                  | undefined
              }
            | null
            | undefined
          >
        }
        person?:
          | {
              __typename?: 'Person'
              id: any
              name: string
              photoUrl?: string | null | undefined
            }
          | null
          | undefined
        thumbnails?:
          | {
              __typename?: 'Thumbnail'
              small?: string | null | undefined
              medium?: string | null | undefined
            }
          | null
          | undefined
        location?:
          | {
              __typename?: 'Location'
              address?: string | null | undefined
              geo?:
                | { __typename?: 'GeometryPoint'; x: number; y: number }
                | null
                | undefined
            }
          | null
          | undefined
        project?:
          | {
              __typename?: 'Project'
              id: any
              name: string
              organisationId: any
            }
          | null
          | undefined
        collections: {
          __typename?: 'FileCollectionsConnection'
          nodes: Array<
            | {
                __typename?: 'FileCollection'
                id: any
                collection?:
                  | {
                      __typename?: 'Collection'
                      id: any
                      name?: string | null | undefined
                    }
                  | null
                  | undefined
              }
            | null
            | undefined
          >
        }
      }
    | null
    | undefined
}

export type GetFilesByProjectQueryVariables = Exact<{
  projectId?: Maybe<Scalars['UUID']>
}>

export type GetFilesByProjectQuery = {
  __typename?: 'Query'
  files?:
    | {
        __typename?: 'FilesConnection'
        nodes: Array<
          | {
              __typename?: 'File'
              id: any
              name?: string | null | undefined
              createdAt: any
              updatedAt: any
              person?:
                | { __typename?: 'Person'; name: string; id: any }
                | null
                | undefined
              project?:
                | {
                    __typename?: 'Project'
                    id: any
                    name: string
                    organisationId: any
                  }
                | null
                | undefined
            }
          | null
          | undefined
        >
      }
    | null
    | undefined
}

export type GetFilesInOrganisationQueryVariables = Exact<{
  organisationId: Scalars['UUID']
  projectId?: Maybe<Scalars['UUID']>
  count?: Maybe<Scalars['Int']>
}>

export type GetFilesInOrganisationQuery = {
  __typename?: 'Query'
  getFilesInOrganisation?:
    | {
        __typename?: 'FilesConnection'
        pageInfo: {
          __typename?: 'PageInfo'
          startCursor?: any | null | undefined
          endCursor?: any | null | undefined
          hasPreviousPage: boolean
          hasNextPage: boolean
        }
        nodes: Array<
          | {
              __typename?: 'File'
              name?: string | null | undefined
              id: any
              medium?: Medium | null | undefined
              format?: Format | null | undefined
              description?: string | null | undefined
              createdAt: any
              person?:
                | {
                    __typename?: 'Person'
                    id: any
                    name: string
                    photoUrl?: string | null | undefined
                  }
                | null
                | undefined
              thumbnails?:
                | {
                    __typename?: 'Thumbnail'
                    small?: string | null | undefined
                    medium?: string | null | undefined
                  }
                | null
                | undefined
              location?:
                | {
                    __typename?: 'Location'
                    address?: string | null | undefined
                    geo?:
                      | { __typename?: 'GeometryPoint'; x: number; y: number }
                      | null
                      | undefined
                  }
                | null
                | undefined
              project?:
                | {
                    __typename?: 'Project'
                    id: any
                    name: string
                    organisationId: any
                  }
                | null
                | undefined
              collections: {
                __typename?: 'FileCollectionsConnection'
                nodes: Array<
                  | {
                      __typename?: 'FileCollection'
                      id: any
                      collection?:
                        | {
                            __typename?: 'Collection'
                            id: any
                            name?: string | null | undefined
                          }
                        | null
                        | undefined
                    }
                  | null
                  | undefined
                >
              }
            }
          | null
          | undefined
        >
      }
    | null
    | undefined
}

export type GetFullFilesQueryVariables = Exact<{
  organisationId: Scalars['UUID']
  projectId?: Maybe<Scalars['UUID']>
  count?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['Cursor']>
}>

export type GetFullFilesQuery = {
  __typename?: 'Query'
  getFilesInOrganisation?:
    | {
        __typename?: 'FilesConnection'
        totalCount: number
        pageInfo: {
          __typename?: 'PageInfo'
          startCursor?: any | null | undefined
          endCursor?: any | null | undefined
          hasPreviousPage: boolean
          hasNextPage: boolean
        }
        nodes: Array<
          | {
              __typename?: 'File'
              name?: string | null | undefined
              id: any
              medium?: Medium | null | undefined
              format?: Format | null | undefined
              description?: string | null | undefined
              createdAt: any
              comments: {
                __typename?: 'CommentsConnection'
                nodes: Array<
                  | {
                      __typename?: 'Comment'
                      id: any
                      fileId?: any | null | undefined
                      content?: string | null | undefined
                      createdAt: any
                      updatedAt: any
                      reactions: {
                        __typename?: 'ReactionsConnection'
                        nodes: Array<
                          | {
                              __typename?: 'Reaction'
                              id: any
                              emoji?: string | null | undefined
                              fileId?: any | null | undefined
                              commentId?: any | null | undefined
                              native?: string | null | undefined
                              person?:
                                | {
                                    __typename?: 'Person'
                                    id: any
                                    name: string
                                  }
                                | null
                                | undefined
                            }
                          | null
                          | undefined
                        >
                      }
                      person?:
                        | {
                            __typename?: 'Person'
                            id: any
                            name: string
                            photoUrl?: string | null | undefined
                          }
                        | null
                        | undefined
                    }
                  | null
                  | undefined
                >
              }
              person?:
                | {
                    __typename?: 'Person'
                    id: any
                    name: string
                    photoUrl?: string | null | undefined
                  }
                | null
                | undefined
              thumbnails?:
                | {
                    __typename?: 'Thumbnail'
                    small?: string | null | undefined
                    medium?: string | null | undefined
                  }
                | null
                | undefined
              location?:
                | {
                    __typename?: 'Location'
                    address?: string | null | undefined
                    geo?:
                      | { __typename?: 'GeometryPoint'; x: number; y: number }
                      | null
                      | undefined
                  }
                | null
                | undefined
              project?:
                | {
                    __typename?: 'Project'
                    id: any
                    name: string
                    organisationId: any
                  }
                | null
                | undefined
              collections: {
                __typename?: 'FileCollectionsConnection'
                nodes: Array<
                  | {
                      __typename?: 'FileCollection'
                      id: any
                      collection?:
                        | {
                            __typename?: 'Collection'
                            id: any
                            name?: string | null | undefined
                          }
                        | null
                        | undefined
                    }
                  | null
                  | undefined
                >
              }
            }
          | null
          | undefined
        >
      }
    | null
    | undefined
}

export type GetInvitationsQueryVariables = Exact<{
  organisationId: Scalars['UUID']
}>

export type GetInvitationsQuery = {
  __typename?: 'Query'
  organisationInvitations?:
    | {
        __typename?: 'OrganisationInvitationsConnection'
        nodes: Array<
          | {
              __typename?: 'OrganisationInvitation'
              id: any
              code: string
              personByInvitedBy?:
                | { __typename?: 'Person'; id: any; name: string }
                | null
                | undefined
              personByAcceptedBy?:
                | { __typename?: 'Person'; id: any; name: string }
                | null
                | undefined
            }
          | null
          | undefined
        >
      }
    | null
    | undefined
}

export type GetMentionsQueryVariables = Exact<{
  mentionedId: Scalars['UUID']
  organisationId?: Maybe<Scalars['UUID']>
}>

export type GetMentionsQuery = {
  __typename?: 'Query'
  mentions?:
    | {
        __typename?: 'MentionsConnection'
        nodes: Array<
          | {
              __typename?: 'Mention'
              id: any
              mentioned?:
                | {
                    __typename?: 'Person'
                    deviceIds?:
                      | Array<string | null | undefined>
                      | null
                      | undefined
                    id: any
                    name: string
                    photoUrl?: string | null | undefined
                  }
                | null
                | undefined
              comment?:
                | {
                    __typename?: 'Comment'
                    id: any
                    fileId?: any | null | undefined
                    content?: string | null | undefined
                    createdAt: any
                    updatedAt: any
                    file?:
                      | {
                          __typename?: 'File'
                          name?: string | null | undefined
                          id: any
                          medium?: Medium | null | undefined
                          format?: Format | null | undefined
                          description?: string | null | undefined
                          createdAt: any
                          comments: {
                            __typename?: 'CommentsConnection'
                            nodes: Array<
                              | {
                                  __typename?: 'Comment'
                                  id: any
                                  fileId?: any | null | undefined
                                  content?: string | null | undefined
                                  createdAt: any
                                  updatedAt: any
                                  reactions: {
                                    __typename?: 'ReactionsConnection'
                                    nodes: Array<
                                      | {
                                          __typename?: 'Reaction'
                                          id: any
                                          emoji?: string | null | undefined
                                          fileId?: any | null | undefined
                                          commentId?: any | null | undefined
                                          native?: string | null | undefined
                                          person?:
                                            | {
                                                __typename?: 'Person'
                                                id: any
                                                name: string
                                              }
                                            | null
                                            | undefined
                                        }
                                      | null
                                      | undefined
                                    >
                                  }
                                  person?:
                                    | {
                                        __typename?: 'Person'
                                        id: any
                                        name: string
                                        photoUrl?: string | null | undefined
                                      }
                                    | null
                                    | undefined
                                }
                              | null
                              | undefined
                            >
                          }
                          person?:
                            | {
                                __typename?: 'Person'
                                id: any
                                name: string
                                photoUrl?: string | null | undefined
                              }
                            | null
                            | undefined
                          thumbnails?:
                            | {
                                __typename?: 'Thumbnail'
                                small?: string | null | undefined
                                medium?: string | null | undefined
                              }
                            | null
                            | undefined
                          location?:
                            | {
                                __typename?: 'Location'
                                address?: string | null | undefined
                                geo?:
                                  | {
                                      __typename?: 'GeometryPoint'
                                      x: number
                                      y: number
                                    }
                                  | null
                                  | undefined
                              }
                            | null
                            | undefined
                          project?:
                            | {
                                __typename?: 'Project'
                                id: any
                                name: string
                                organisationId: any
                              }
                            | null
                            | undefined
                          collections: {
                            __typename?: 'FileCollectionsConnection'
                            nodes: Array<
                              | {
                                  __typename?: 'FileCollection'
                                  id: any
                                  collection?:
                                    | {
                                        __typename?: 'Collection'
                                        id: any
                                        name?: string | null | undefined
                                      }
                                    | null
                                    | undefined
                                }
                              | null
                              | undefined
                            >
                          }
                        }
                      | null
                      | undefined
                    reactions: {
                      __typename?: 'ReactionsConnection'
                      nodes: Array<
                        | {
                            __typename?: 'Reaction'
                            id: any
                            emoji?: string | null | undefined
                            fileId?: any | null | undefined
                            commentId?: any | null | undefined
                            native?: string | null | undefined
                            person?:
                              | { __typename?: 'Person'; id: any; name: string }
                              | null
                              | undefined
                          }
                        | null
                        | undefined
                      >
                    }
                    person?:
                      | {
                          __typename?: 'Person'
                          id: any
                          name: string
                          photoUrl?: string | null | undefined
                        }
                      | null
                      | undefined
                  }
                | null
                | undefined
            }
          | null
          | undefined
        >
      }
    | null
    | undefined
}

export type GetNotificationsQueryVariables = Exact<{
  organisationId: Scalars['UUID']
  receiverId: Scalars['UUID']
}>

export type GetNotificationsQuery = {
  __typename?: 'Query'
  notifications?:
    | {
        __typename?: 'NotificationsConnection'
        nodes: Array<
          | {
              __typename?: 'Notification'
              id: any
              createdAt: any
              fileId?: any | null | undefined
              seen?: boolean | null | undefined
              person?:
                | {
                    __typename?: 'Person'
                    id: any
                    name: string
                    photoUrl?: string | null | undefined
                  }
                | null
                | undefined
              comment?:
                | {
                    __typename?: 'Comment'
                    id: any
                    content?: string | null | undefined
                    person?:
                      | {
                          __typename?: 'Person'
                          id: any
                          name: string
                          photoUrl?: string | null | undefined
                        }
                      | null
                      | undefined
                    file?:
                      | {
                          __typename?: 'File'
                          id: any
                          name?: string | null | undefined
                          project?:
                            | {
                                __typename?: 'Project'
                                id: any
                                organisationId: any
                              }
                            | null
                            | undefined
                        }
                      | null
                      | undefined
                  }
                | null
                | undefined
            }
          | null
          | undefined
        >
      }
    | null
    | undefined
}

export type GetOrgsQueryVariables = Exact<{ [key: string]: never }>

export type GetOrgsQuery = {
  __typename?: 'Query'
  organisations?:
    | {
        __typename?: 'OrganisationsConnection'
        nodes: Array<
          | {
              __typename?: 'Organisation'
              id: any
              name: string
              personal?: boolean | null | undefined
              people: {
                __typename?: 'PersonOrganisationsConnection'
                nodes: Array<
                  | {
                      __typename?: 'PersonOrganisation'
                      id: any
                      person?:
                        | {
                            __typename?: 'Person'
                            id: any
                            name: string
                            photoUrl?: string | null | undefined
                          }
                        | null
                        | undefined
                    }
                  | null
                  | undefined
                >
              }
            }
          | null
          | undefined
        >
      }
    | null
    | undefined
}

export type PeopleInProjectQueryVariables = Exact<{
  projectId: Scalars['UUID']
}>

export type PeopleInProjectQuery = {
  __typename?: 'Query'
  project?:
    | {
        __typename?: 'Project'
        id: any
        people: {
          __typename?: 'PersonProjectsConnection'
          nodes: Array<
            | {
                __typename?: 'PersonProject'
                person?:
                  | {
                      __typename?: 'Person'
                      id: any
                      name: string
                      photoUrl?: string | null | undefined
                    }
                  | null
                  | undefined
              }
            | null
            | undefined
          >
        }
      }
    | null
    | undefined
}

export type GetProjectsQueryVariables = Exact<{
  organisationId: Scalars['UUID']
}>

export type GetProjectsQuery = {
  __typename?: 'Query'
  projects?:
    | {
        __typename?: 'ProjectsConnection'
        nodes: Array<
          | {
              __typename?: 'Project'
              id: any
              name: string
              description?: string | null | undefined
              type?: ProjectType | null | undefined
              createdAt: any
              updatedAt: any
              createdBy?:
                | {
                    __typename?: 'Person'
                    id: any
                    name: string
                    photoUrl?: string | null | undefined
                  }
                | null
                | undefined
              people: {
                __typename?: 'PersonProjectsConnection'
                nodes: Array<
                  | {
                      __typename?: 'PersonProject'
                      id: any
                      person?:
                        | {
                            __typename?: 'Person'
                            id: any
                            name: string
                            photoUrl?: string | null | undefined
                          }
                        | null
                        | undefined
                    }
                  | null
                  | undefined
                >
              }
            }
          | null
          | undefined
        >
      }
    | null
    | undefined
}

export type FileSubscriptionVariables = Exact<{
  fileId: Scalars['UUID']
}>

export type FileSubscription = {
  __typename?: 'Subscription'
  fileUpdated?:
    | {
        __typename?: 'FileSubscriptionPayload'
        event?: string | null | undefined
        file?:
          | {
              __typename?: 'File'
              name?: string | null | undefined
              id: any
              medium?: Medium | null | undefined
              format?: Format | null | undefined
              description?: string | null | undefined
              createdAt: any
              comments: {
                __typename?: 'CommentsConnection'
                nodes: Array<
                  | {
                      __typename?: 'Comment'
                      id: any
                      fileId?: any | null | undefined
                      content?: string | null | undefined
                      createdAt: any
                      updatedAt: any
                      reactions: {
                        __typename?: 'ReactionsConnection'
                        nodes: Array<
                          | {
                              __typename?: 'Reaction'
                              id: any
                              emoji?: string | null | undefined
                              fileId?: any | null | undefined
                              commentId?: any | null | undefined
                              native?: string | null | undefined
                              person?:
                                | {
                                    __typename?: 'Person'
                                    id: any
                                    name: string
                                  }
                                | null
                                | undefined
                            }
                          | null
                          | undefined
                        >
                      }
                      person?:
                        | {
                            __typename?: 'Person'
                            id: any
                            name: string
                            photoUrl?: string | null | undefined
                          }
                        | null
                        | undefined
                    }
                  | null
                  | undefined
                >
              }
              person?:
                | {
                    __typename?: 'Person'
                    id: any
                    name: string
                    photoUrl?: string | null | undefined
                  }
                | null
                | undefined
              thumbnails?:
                | {
                    __typename?: 'Thumbnail'
                    small?: string | null | undefined
                    medium?: string | null | undefined
                  }
                | null
                | undefined
              location?:
                | {
                    __typename?: 'Location'
                    address?: string | null | undefined
                    geo?:
                      | { __typename?: 'GeometryPoint'; x: number; y: number }
                      | null
                      | undefined
                  }
                | null
                | undefined
              project?:
                | {
                    __typename?: 'Project'
                    id: any
                    name: string
                    organisationId: any
                  }
                | null
                | undefined
              collections: {
                __typename?: 'FileCollectionsConnection'
                nodes: Array<
                  | {
                      __typename?: 'FileCollection'
                      id: any
                      collection?:
                        | {
                            __typename?: 'Collection'
                            id: any
                            name?: string | null | undefined
                          }
                        | null
                        | undefined
                    }
                  | null
                  | undefined
                >
              }
            }
          | null
          | undefined
      }
    | null
    | undefined
}

export type NotificationSubscriptionVariables = Exact<{
  receiverId: Scalars['UUID']
}>

export type NotificationSubscription = {
  __typename?: 'Subscription'
  newNotification?:
    | {
        __typename?: 'NotificationSubscriptionPayload'
        event?: string | null | undefined
        notification?:
          | {
              __typename?: 'Notification'
              id: any
              file?:
                | {
                    __typename?: 'File'
                    id: any
                    name?: string | null | undefined
                  }
                | null
                | undefined
            }
          | null
          | undefined
      }
    | null
    | undefined
}

export const RegularPersonFragmentDoc = gql`
  fragment RegularPerson on Person {
    id
    name
    photoUrl
  }
`
export const RegularReactionFragmentDoc = gql`
  fragment RegularReaction on Reaction {
    id
    emoji
    fileId
    commentId
    person {
      id
      name
    }
    native
  }
`
export const RegularCommentFragmentDoc = gql`
  fragment RegularComment on Comment {
    id
    fileId
    content
    createdAt
    updatedAt
    reactions {
      nodes {
        ...RegularReaction
      }
    }
    person {
      ...RegularPerson
    }
  }
  ${RegularReactionFragmentDoc}
  ${RegularPersonFragmentDoc}
`
export const RegularProjectFragmentDoc = gql`
  fragment RegularProject on Project {
    id
    name
    organisationId
  }
`
export const RegularFileFragmentDoc = gql`
  fragment RegularFile on File {
    name
    id
    medium
    format
    description
    createdAt
    person {
      ...RegularPerson
    }
    thumbnails {
      small
      medium
    }
    location {
      address
      geo {
        x
        y
      }
    }
    project {
      ...RegularProject
    }
    collections: fileCollections {
      nodes {
        id
        collection {
          id
          name
        }
      }
    }
  }
  ${RegularPersonFragmentDoc}
  ${RegularProjectFragmentDoc}
`
export const FullFileFragmentDoc = gql`
  fragment FullFile on File {
    ...RegularFile
    comments {
      nodes {
        ...RegularComment
      }
    }
  }
  ${RegularFileFragmentDoc}
  ${RegularCommentFragmentDoc}
`
export const FullMentionFragmentDoc = gql`
  fragment FullMention on Mention {
    id
    mentioned {
      ...RegularPerson
      deviceIds
    }
    comment {
      ...RegularComment
      file {
        ...FullFile
      }
    }
  }
  ${RegularPersonFragmentDoc}
  ${RegularCommentFragmentDoc}
  ${FullFileFragmentDoc}
`
export const FullProjectFragmentDoc = gql`
  fragment FullProject on Project {
    id
    name
    description
    type
    createdAt
    updatedAt
    createdBy: person {
      ...RegularPerson
    }
    people: personProjects {
      nodes {
        id
        person {
          ...RegularPerson
        }
      }
    }
  }
  ${RegularPersonFragmentDoc}
`
export const AddFileToCollectionDocument = gql`
  mutation AddFileToCollection($collectionId: UUID!, $fileId: UUID!) {
    createFileCollection(
      input: {
        fileCollection: { collectionId: $collectionId, fileId: $fileId }
      }
    ) {
      clientMutationId
    }
  }
`

export function useAddFileToCollectionMutation() {
  return Urql.useMutation<
    AddFileToCollectionMutation,
    AddFileToCollectionMutationVariables
  >(AddFileToCollectionDocument)
}
export const CreateCollectionDocument = gql`
  mutation createCollection($organisationId: UUID!, $name: String!) {
    createCollection(
      input: { collection: { name: $name, organisationId: $organisationId } }
    ) {
      collection {
        name
        id
      }
    }
  }
`

export function useCreateCollectionMutation() {
  return Urql.useMutation<
    CreateCollectionMutation,
    CreateCollectionMutationVariables
  >(CreateCollectionDocument)
}
export const GetCollectionsByOrgDocument = gql`
  query getCollectionsByOrg($organisationId: UUID!) {
    collections(condition: { organisationId: $organisationId }) {
      nodes {
        id
        name
        organisation {
          id
          name
        }
        files: fileCollections {
          nodes {
            id
            fileId
          }
        }
      }
    }
  }
`

export function useGetCollectionsByOrgQuery(
  options: Omit<
    Urql.UseQueryArgs<GetCollectionsByOrgQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<GetCollectionsByOrgQuery>({
    query: GetCollectionsByOrgDocument,
    ...options,
  })
}
export const RemoveCollectionFromFileDocument = gql`
  mutation removeCollectionFromFile($fileId: UUID!, $collectionId: UUID!) {
    deleteFileCollectionByCollectionIdAndFileId(
      input: { collectionId: $collectionId, fileId: $fileId }
    ) {
      fileCollection {
        id
      }
    }
  }
`

export function useRemoveCollectionFromFileMutation() {
  return Urql.useMutation<
    RemoveCollectionFromFileMutation,
    RemoveCollectionFromFileMutationVariables
  >(RemoveCollectionFromFileDocument)
}
export const CreateCommentDocument = gql`
  mutation createComment($fileId: UUID!, $content: String!) {
    createComment(input: { comment: { content: $content, fileId: $fileId } }) {
      __typename
      comment {
        ...RegularComment
      }
    }
  }
  ${RegularCommentFragmentDoc}
`

export function useCreateCommentMutation() {
  return Urql.useMutation<
    CreateCommentMutation,
    CreateCommentMutationVariables
  >(CreateCommentDocument)
}
export const DeleteCommentDocument = gql`
  mutation deleteComment($commentId: UUID!) {
    deleteComment(input: { id: $commentId }) {
      comment {
        id
      }
    }
  }
`

export function useDeleteCommentMutation() {
  return Urql.useMutation<
    DeleteCommentMutation,
    DeleteCommentMutationVariables
  >(DeleteCommentDocument)
}
export const UpdateCommentDocument = gql`
  mutation updateComment($commentId: UUID!, $patch: CommentPatch!) {
    updateComment(input: { id: $commentId, patch: $patch }) {
      comment {
        id
        content
      }
    }
  }
`

export function useUpdateCommentMutation() {
  return Urql.useMutation<
    UpdateCommentMutation,
    UpdateCommentMutationVariables
  >(UpdateCommentDocument)
}
export const CreateFileDocument = gql`
  mutation createFile(
    $name: String!
    $projectId: UUID!
    $medium: Medium!
    $duration: Int!
    $dimensions: DimensionInput!
    $format: Format!
    $size: Int!
    $id: UUID!
  ) {
    createFile(
      input: {
        file: {
          id: $id
          name: $name
          projectId: $projectId
          medium: $medium
          dimensions: $dimensions
          format: $format
          size: $size
          duration: $duration
        }
      }
    ) {
      file {
        name
        id
      }
    }
  }
`

export function useCreateFileMutation() {
  return Urql.useMutation<CreateFileMutation, CreateFileMutationVariables>(
    CreateFileDocument
  )
}
export const DeleteFileDocument = gql`
  mutation deleteFile($fileId: UUID!) {
    deleteFile(input: { id: $fileId }) {
      file {
        id
      }
    }
  }
`

export function useDeleteFileMutation() {
  return Urql.useMutation<DeleteFileMutation, DeleteFileMutationVariables>(
    DeleteFileDocument
  )
}
export const UpdateFileDocument = gql`
  mutation updateFile($fileId: UUID!, $patch: FilePatch!) {
    updateFile(input: { id: $fileId, patch: $patch }) {
      file {
        ...RegularFile
      }
    }
  }
  ${RegularFileFragmentDoc}
`

export function useUpdateFileMutation() {
  return Urql.useMutation<UpdateFileMutation, UpdateFileMutationVariables>(
    UpdateFileDocument
  )
}
export const CreateMentionDocument = gql`
  mutation createMention(
    $commentId: UUID!
    $mentionedId: UUID!
    $organisationId: UUID!
  ) {
    createMention(
      input: {
        mention: {
          commentId: $commentId
          mentionedId: $mentionedId
          organisationId: $organisationId
        }
      }
    ) {
      mention {
        id
      }
    }
  }
`

export function useCreateMentionMutation() {
  return Urql.useMutation<
    CreateMentionMutation,
    CreateMentionMutationVariables
  >(CreateMentionDocument)
}
export const CreateNotificationDocument = gql`
  mutation createNotification(
    $receiverId: UUID!
    $organisationId: UUID!
    $commentId: UUID
    $fileId: UUID
  ) {
    createNotification(
      input: {
        notification: {
          receiverId: $receiverId
          commentId: $commentId
          fileId: $fileId
          organisationId: $organisationId
        }
      }
    ) {
      notification {
        id
      }
    }
  }
`

export function useCreateNotificationMutation() {
  return Urql.useMutation<
    CreateNotificationMutation,
    CreateNotificationMutationVariables
  >(CreateNotificationDocument)
}
export const DeleteNotificationDocument = gql`
  mutation deleteNotification($notificationId: UUID!) {
    deleteNotification(input: { id: $notificationId }) {
      notification {
        id
      }
    }
  }
`

export function useDeleteNotificationMutation() {
  return Urql.useMutation<
    DeleteNotificationMutation,
    DeleteNotificationMutationVariables
  >(DeleteNotificationDocument)
}
export const AcceptOrganisationInvitationDocument = gql`
  mutation acceptOrganisationInvitation($invitationCode: String!) {
    acceptOrganisationInvitation(input: { invitationCode: $invitationCode }) {
      boolean
    }
  }
`

export function useAcceptOrganisationInvitationMutation() {
  return Urql.useMutation<
    AcceptOrganisationInvitationMutation,
    AcceptOrganisationInvitationMutationVariables
  >(AcceptOrganisationInvitationDocument)
}
export const CreateOrganisationDocument = gql`
  mutation createOrganisation($name: String!) {
    addOrganisation(input: { name: $name }) {
      organisation {
        name
        id
      }
    }
  }
`

export function useCreateOrganisationMutation() {
  return Urql.useMutation<
    CreateOrganisationMutation,
    CreateOrganisationMutationVariables
  >(CreateOrganisationDocument)
}
export const CreateOrganisationInvitationDocument = gql`
  mutation createOrganisationInvitation(
    $code: String!
    $organisationId: UUID!
  ) {
    createOrganisationInvitation(
      input: { code: $code, organisationId: $organisationId }
    ) {
      organisationInvitation {
        id
      }
    }
  }
`

export function useCreateOrganisationInvitationMutation() {
  return Urql.useMutation<
    CreateOrganisationInvitationMutation,
    CreateOrganisationInvitationMutationVariables
  >(CreateOrganisationInvitationDocument)
}
export const DeleteOrganisationDocument = gql`
  mutation deleteOrganisation($organisationId: UUID!) {
    deleteOrganisation(input: { id: $organisationId }) {
      organisation {
        id
      }
    }
  }
`

export function useDeleteOrganisationMutation() {
  return Urql.useMutation<
    DeleteOrganisationMutation,
    DeleteOrganisationMutationVariables
  >(DeleteOrganisationDocument)
}
export const DeleteOrganisationInvitationDocument = gql`
  mutation deleteOrganisationInvitation($invitationId: UUID!) {
    deleteOrganisationInvitation(input: { id: $invitationId }) {
      organisationInvitation {
        id
      }
    }
  }
`

export function useDeleteOrganisationInvitationMutation() {
  return Urql.useMutation<
    DeleteOrganisationInvitationMutation,
    DeleteOrganisationInvitationMutationVariables
  >(DeleteOrganisationInvitationDocument)
}
export const UpdateOrganisationDocument = gql`
  mutation updateOrganisation(
    $organisationId: UUID!
    $patch: OrganisationPatch!
  ) {
    updateOrganisation(input: { id: $organisationId, patch: $patch }) {
      organisation {
        id
        name
      }
    }
  }
`

export function useUpdateOrganisationMutation() {
  return Urql.useMutation<
    UpdateOrganisationMutation,
    UpdateOrganisationMutationVariables
  >(UpdateOrganisationDocument)
}
export const AddFirebaseAccountDocument = gql`
  mutation addFirebaseAccount($name: String!, $photoUrl: String!) {
    addFirebaseAccount(input: { name: $name, photoUrl: $photoUrl }) {
      person {
        id
        firebaseId
      }
    }
  }
`

export function useAddFirebaseAccountMutation() {
  return Urql.useMutation<
    AddFirebaseAccountMutation,
    AddFirebaseAccountMutationVariables
  >(AddFirebaseAccountDocument)
}
export const AddPersonToProjectDocument = gql`
  mutation addPersonToProject($personId: UUID!, $projectId: UUID!) {
    createPersonProject(
      input: { personProject: { personId: $personId, projectId: $projectId } }
    ) {
      personProject {
        personId
        projectId
      }
    }
  }
`

export function useAddPersonToProjectMutation() {
  return Urql.useMutation<
    AddPersonToProjectMutation,
    AddPersonToProjectMutationVariables
  >(AddPersonToProjectDocument)
}
export const CreateProjectDocument = gql`
  mutation createProject(
    $name: String!
    $description: String!
    $organisationId: UUID!
    $type: ProjectType!
  ) {
    createProject(
      input: {
        project: {
          name: $name
          organisationId: $organisationId
          description: $description
          type: $type
        }
      }
    ) {
      project {
        organisationId
        ...FullProject
      }
    }
  }
  ${FullProjectFragmentDoc}
`

export function useCreateProjectMutation() {
  return Urql.useMutation<
    CreateProjectMutation,
    CreateProjectMutationVariables
  >(CreateProjectDocument)
}
export const DeleteProjectDocument = gql`
  mutation deleteProject($projectId: UUID!) {
    deleteProject(input: { id: $projectId }) {
      project {
        id
      }
    }
  }
`

export function useDeleteProjectMutation() {
  return Urql.useMutation<
    DeleteProjectMutation,
    DeleteProjectMutationVariables
  >(DeleteProjectDocument)
}
export const RemovePersonFromProjectDocument = gql`
  mutation removePersonFromProject($personId: UUID!, $projectId: UUID!) {
    deletePersonProjectByPersonIdAndProjectId(
      input: { personId: $personId, projectId: $projectId }
    ) {
      personProject {
        personId
        projectId
      }
    }
  }
`

export function useRemovePersonFromProjectMutation() {
  return Urql.useMutation<
    RemovePersonFromProjectMutation,
    RemovePersonFromProjectMutationVariables
  >(RemovePersonFromProjectDocument)
}
export const UpdateProjectDocument = gql`
  mutation updateProject($projectId: UUID!, $patch: ProjectPatch!) {
    updateProject(input: { id: $projectId, patch: $patch }) {
      project {
        id
        name
      }
    }
  }
`

export function useUpdateProjectMutation() {
  return Urql.useMutation<
    UpdateProjectMutation,
    UpdateProjectMutationVariables
  >(UpdateProjectDocument)
}
export const CreateReactionDocument = gql`
  mutation createReaction(
    $emoji: String!
    $native: String!
    $fileId: UUID!
    $commentId: UUID
  ) {
    createReaction(
      input: {
        reaction: {
          emoji: $emoji
          native: $native
          fileId: $fileId
          commentId: $commentId
        }
      }
    ) {
      reaction {
        ...RegularReaction
      }
    }
  }
  ${RegularReactionFragmentDoc}
`

export function useCreateReactionMutation() {
  return Urql.useMutation<
    CreateReactionMutation,
    CreateReactionMutationVariables
  >(CreateReactionDocument)
}
export const DeleteReactionDocument = gql`
  mutation deleteReaction($reactionId: UUID!) {
    deleteReaction(input: { id: $reactionId }) {
      reaction {
        ...RegularReaction
      }
    }
  }
  ${RegularReactionFragmentDoc}
`

export function useDeleteReactionMutation() {
  return Urql.useMutation<
    DeleteReactionMutation,
    DeleteReactionMutationVariables
  >(DeleteReactionDocument)
}
export const GetCommentsByFileDocument = gql`
  query getCommentsByFile($fileId: UUID!) {
    comments(condition: { fileId: $fileId }) {
      nodes {
        ...RegularComment
      }
    }
  }
  ${RegularCommentFragmentDoc}
`

export function useGetCommentsByFileQuery(
  options: Omit<
    Urql.UseQueryArgs<GetCommentsByFileQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<GetCommentsByFileQuery>({
    query: GetCommentsByFileDocument,
    ...options,
  })
}
export const GetCurrentPersonDocument = gql`
  query getCurrentPerson {
    getCurrentPerson {
      nodes {
        id
        name
        photoUrl
      }
    }
  }
`

export function useGetCurrentPersonQuery(
  options: Omit<Urql.UseQueryArgs<GetCurrentPersonQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<GetCurrentPersonQuery>({
    query: GetCurrentPersonDocument,
    ...options,
  })
}
export const GetFileDocument = gql`
  query getFile($fileId: UUID!) {
    file(id: $fileId) {
      ...FullFile
    }
  }
  ${FullFileFragmentDoc}
`

export function useGetFileQuery(
  options: Omit<Urql.UseQueryArgs<GetFileQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<GetFileQuery>({ query: GetFileDocument, ...options })
}
export const GetFilesByProjectDocument = gql`
  query GetFilesByProject($projectId: UUID) {
    files(condition: { projectId: $projectId }) {
      nodes {
        person {
          name
          id
        }
        id
        name
        project {
          id
          name
          organisationId
        }
        createdAt
        updatedAt
      }
    }
  }
`

export function useGetFilesByProjectQuery(
  options: Omit<
    Urql.UseQueryArgs<GetFilesByProjectQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<GetFilesByProjectQuery>({
    query: GetFilesByProjectDocument,
    ...options,
  })
}
export const GetFilesInOrganisationDocument = gql`
  query GetFilesInOrganisation(
    $organisationId: UUID!
    $projectId: UUID
    $count: Int
  ) {
    getFilesInOrganisation(
      orderBy: [CREATED_AT_DESC, ID_DESC]
      first: $count
      organisationId: $organisationId
      projectId: $projectId
    ) {
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
      nodes {
        ...RegularFile
      }
    }
  }
  ${RegularFileFragmentDoc}
`

export function useGetFilesInOrganisationQuery(
  options: Omit<
    Urql.UseQueryArgs<GetFilesInOrganisationQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<GetFilesInOrganisationQuery>({
    query: GetFilesInOrganisationDocument,
    ...options,
  })
}
export const GetFullFilesDocument = gql`
  query getFullFiles(
    $organisationId: UUID!
    $projectId: UUID
    $count: Int
    $after: Cursor
  ) {
    getFilesInOrganisation(
      orderBy: [CREATED_AT_DESC, ID_DESC]
      first: $count
      organisationId: $organisationId
      projectId: $projectId
      after: $after
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
      nodes {
        ...FullFile
      }
    }
  }
  ${FullFileFragmentDoc}
`

export function useGetFullFilesQuery(
  options: Omit<Urql.UseQueryArgs<GetFullFilesQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<GetFullFilesQuery>({
    query: GetFullFilesDocument,
    ...options,
  })
}
export const GetInvitationsDocument = gql`
  query getInvitations($organisationId: UUID!) {
    organisationInvitations(condition: { organisationId: $organisationId }) {
      nodes {
        personByInvitedBy {
          id
          name
        }
        personByAcceptedBy {
          id
          name
        }
        id
        code
      }
    }
  }
`

export function useGetInvitationsQuery(
  options: Omit<Urql.UseQueryArgs<GetInvitationsQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<GetInvitationsQuery>({
    query: GetInvitationsDocument,
    ...options,
  })
}
export const GetMentionsDocument = gql`
  query getMentions($mentionedId: UUID!, $organisationId: UUID) {
    mentions(
      orderBy: [CREATED_AT_DESC, ID_DESC]
      condition: { mentionedId: $mentionedId, organisationId: $organisationId }
    ) {
      nodes {
        ...FullMention
      }
    }
  }
  ${FullMentionFragmentDoc}
`

export function useGetMentionsQuery(
  options: Omit<Urql.UseQueryArgs<GetMentionsQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<GetMentionsQuery>({
    query: GetMentionsDocument,
    ...options,
  })
}
export const GetNotificationsDocument = gql`
  query getNotifications($organisationId: UUID!, $receiverId: UUID!) {
    notifications(
      condition: { organisationId: $organisationId, receiverId: $receiverId }
    ) {
      nodes {
        id
        createdAt
        fileId
        person {
          ...RegularPerson
        }
        comment {
          id
          content
          person {
            ...RegularPerson
          }
          file {
            id
            name
            project {
              id
              organisationId
            }
          }
        }
        seen
      }
    }
  }
  ${RegularPersonFragmentDoc}
`

export function useGetNotificationsQuery(
  options: Omit<Urql.UseQueryArgs<GetNotificationsQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<GetNotificationsQuery>({
    query: GetNotificationsDocument,
    ...options,
  })
}
export const GetOrgsDocument = gql`
  query getOrgs {
    organisations {
      nodes {
        id
        name
        personal
        people: personOrganisations {
          nodes {
            id
            person {
              ...RegularPerson
            }
          }
        }
      }
    }
  }
  ${RegularPersonFragmentDoc}
`

export function useGetOrgsQuery(
  options: Omit<Urql.UseQueryArgs<GetOrgsQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<GetOrgsQuery>({ query: GetOrgsDocument, ...options })
}
export const PeopleInProjectDocument = gql`
  query peopleInProject($projectId: UUID!) {
    project(id: $projectId) {
      id
      people: personProjects {
        nodes {
          person {
            ...RegularPerson
          }
        }
      }
    }
  }
  ${RegularPersonFragmentDoc}
`

export function usePeopleInProjectQuery(
  options: Omit<Urql.UseQueryArgs<PeopleInProjectQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<PeopleInProjectQuery>({
    query: PeopleInProjectDocument,
    ...options,
  })
}
export const GetProjectsDocument = gql`
  query getProjects($organisationId: UUID!) {
    projects(condition: { organisationId: $organisationId }) {
      nodes {
        ...FullProject
      }
    }
  }
  ${FullProjectFragmentDoc}
`

export function useGetProjectsQuery(
  options: Omit<Urql.UseQueryArgs<GetProjectsQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<GetProjectsQuery>({
    query: GetProjectsDocument,
    ...options,
  })
}
export const FileDocument = gql`
  subscription File($fileId: UUID!) {
    fileUpdated(input: { id: $fileId }) {
      file {
        ...FullFile
      }
      event
    }
  }
  ${FullFileFragmentDoc}
`

export function useFileSubscription<TData = FileSubscription>(
  options: Omit<
    Urql.UseSubscriptionArgs<FileSubscriptionVariables>,
    'query'
  > = {},
  handler?: Urql.SubscriptionHandler<FileSubscription, TData>
) {
  return Urql.useSubscription<
    FileSubscription,
    TData,
    FileSubscriptionVariables
  >({ query: FileDocument, ...options }, handler)
}
export const NotificationDocument = gql`
  subscription Notification($receiverId: UUID!) {
    newNotification(input: { receiverId: $receiverId }) {
      event
      notification {
        id
        file {
          id
          name
        }
      }
    }
  }
`

export function useNotificationSubscription<TData = NotificationSubscription>(
  options: Omit<
    Urql.UseSubscriptionArgs<NotificationSubscriptionVariables>,
    'query'
  > = {},
  handler?: Urql.SubscriptionHandler<NotificationSubscription, TData>
) {
  return Urql.useSubscription<
    NotificationSubscription,
    TData,
    NotificationSubscriptionVariables
  >({ query: NotificationDocument, ...options }, handler)
}
