import '../styles/index.css'
import { ProvideAuth } from '../lib/auth'
import { Provider as TooltipProvider } from '@radix-ui/react-tooltip'

export default function App({ Component, pageProps }) {
  return (
    <ProvideAuth>
      <TooltipProvider>
        <Component {...pageProps} />
      </TooltipProvider>
    </ProvideAuth>
  )
}
