import React, { useState, useEffect, useContext, createContext } from 'react'
import firebase from 'firebase'
import 'firebase/auth'

import { Provider as UrqlProvider } from 'urql'
import createUrqlClient from '@/lib/urqlClient'

// import prod from '../.firebase/prod.json'

if (!firebase.apps.length) {
  // firebase.initializeApp(prod)
  var firebaseConfig = {
    apiKey: 'AIzaSyBi7ZgArtMLO1XmdyzubnOTNRBlHvKmtvU',
    authDomain: 'sampler-rolls.firebaseapp.com',
    projectId: 'sampler-rolls',
    storageBucket: 'sampler-rolls.appspot.com',
    messagingSenderId: '657351996336',
    appId: '1:657351996336:web:a8d7466a9eb8a9caa05c75',
  }

  firebase.initializeApp(firebaseConfig)
}

const provider = new firebase.auth.GoogleAuthProvider()

const authContext = createContext()
let urqlClient

export function ProvideAuth({ children }) {
  const auth = useProvideAuth()

  if (!auth.user) {
    return <authContext.Provider value={auth}>{children}</authContext.Provider>
  }

  if (!urqlClient) {
    urqlClient = createUrqlClient()
    console.log('urql client established', urqlClient)
  }

  return (
    <authContext.Provider value={auth}>
      <UrqlProvider value={urqlClient}>{children}</UrqlProvider>
    </authContext.Provider>
  )
}

export const useAuth = () => {
  return useContext(authContext)
}

function useProvideAuth() {
  const [user, setUser] = useState(null)

  const handleUser = async (rawUser) => {
    if (rawUser) {
      const user = await formatUser(rawUser)
      // const { token, ...userWithoutToken } = user
      setUser(user)
      return user
    } else {
      setUser(false)
      return false
    }
  }

  const signin = async () => {
    const result = await firebase.auth().signInWithPopup(provider)
    const user = await formatUser(result.user)
    setUser(user)
    return result
  }

  const signout = () => {
    return firebase
      .auth()
      .signOut()
      .then(() => {
        setUser(false)
      })
  }

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const u = await formatUser(user)
        setUser(u)
      } else {
        setUser(false)
      }
    })

    return () => unsubscribe()
  }, [])

  useEffect(() => {
    const unsubscribe = firebase.auth().onIdTokenChanged(handleUser)
    return () => unsubscribe()
  }, [])

  return {
    userId: user && user.uid,
    signin,
    signout,
    user,
  }
}

const formatUser = async (user) => {
  const token = await user.getIdToken(true)
  return {
    uid: user.uid,
    email: user.email,
    name: user.displayName,
    provider: user.providerData[0].providerId,
    photoURL: user.photoURL,
    token,
  }
}
